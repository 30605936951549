import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "../app/App";
// import PageNotFound from "../views/PageNotFound";
import allRoutes from "./routes";

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            ...allRoutes
        ]
    },
    {
        path: "*",
        name: "PageNotFound",
        element: (
            <Navigate to={'/swap'}/>
        )
    }
]);

export default router;