import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { toCurrency, formatNumber } from "../../helpers/utils";
import SharedService from "../../services/SharedService";

const { getTokenStats } = SharedService()

const GradientText = styled.span`
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`

const HomeStatistics = () => {
    const [statistics, setStatistics] = useState({})
    let mounted = useRef(true)
    let dataFetched = useRef(false)

    const stats = useMemo(() => {
        return [
            { name: 'Trade Volume', value: `$${formatNumber(statistics?.total_sold)}` },
            { name: 'All Time Trades', value: '124M' },
            { name: 'Integrations', value: '150' },
            { name: 'Community Delegates', value: '51,000' },
        ]
    }, [statistics])

    useEffect(() => {
        mounted.current = true;
        if (dataFetched.current) return;

        const getStats = () => {
            getTokenStats()
                .then(response => {
                    if (mounted.current && response) {
                        setStatistics(response ?? {})
                    }
                }).finally(() => {
                    if (mounted.current) {
                        dataFetched.current = false
                    }
                })
        }

        getStats()
        dataFetched.current = true;

        return () => {
            mounted.current = false;
            setStatistics({})
        }
    }, [])

    return (
        <div className="bg-purple-primary text-white py-24 sm:py-32" data-aos="fade-up" data-aos-duration="1500">
            <h2 className="text-center text-xl font-bold mb-6">See Our Works</h2>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center md:grid-cols-2 xl:grid-cols-4">
                    {
                        stats?.map((stat, index) => (
                            <div key={index} className="mx-auto flex max-w-xs flex-col gap-y-4">
                                <dt className="text-base leading-7 text-gray-200">{stat.name}</dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                                    {stat.value}<span className="text-blue-primary">+</span>
                                </dd>
                            </div>
                        ))
                    }
                </dl>
            </div>

            <div className="hidden h-48 lg:flex lg:items-center my-12 lg:justify-center mx-auto max-w-7xl px-6 lg:px-8">
                <div className="h-full flex text-5xl font-bold items-center justify-center border-grey-primary rounded-lg mx-4 border p-12">
                    <GradientText className="bg-gradient-to-r from-white via-blue-primary to-blue-primary">
                        { toCurrency(statistics.total_sold, 'USD') }
                    </GradientText>
                </div>
                <div className="h-full border-grey-primary rounded-lg border p-12 mx-4 flex flex-col justify-between">
                    <p className="font-bold text-xl">Market Cap</p>

                    <span className="text-sm">Total dollar value of all the shares of AAST's stock.</span>
                </div>
            </div>

            <div className="hidden lg:flex lg:items-center mt-6 lg:flex-col lg:justify-center border-grey-primary rounded-lg border w-4/6 mx-auto max-w-7xl px-6 lg:px-10 py-6">
                <div className="w-full flex justify-between items-center">
                    <h3 className="uppercase font-bold text-xl">AAS TOKEN</h3>

                    <a href="https://www.cyberscope.io/audits/aas?s=09" target="_blank" rel="noreferrer" className="w-40 hover:bg-purple-primary rounded-lg bg-blue-primary px-3.5 py-2.5 text-sm font-bold text-white shadow-sm border border-blue-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white flex items-center justify-center">
                        See Statistics
                    </a>
                </div>

                <div className="flex w-full flex-col my-6">
                    <small className="text-xs block font-bold">AAS Token Tailored Solutions for Sustainable Progress.</small>
                    <small className="text-xs block font-bold">Discover the Future of Finance with AAS Token Your Passport to a Decentralized World!</small>
                </div>
            </div>
        </div>
    );
}
 
export default HomeStatistics;