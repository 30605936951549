import styled from "styled-components";
import PlayIcon from "../../assets/icons/playIcon";
import { Link } from "react-router-dom";

const GradientText = styled.span`
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`

const Wrapper = styled.div`
    height: 76vh;
`

const HeroSection = () => {
    return (
        <Wrapper className="relative isolate px-6 pt-14 lg:px-8" >
            <div className="mx-auto max-w-3xl py-32 sm:py-48 lg:py-56">
            
                <div className="text-center">
                    <h1 className="animate__flipInX animate__animated animate__delay-1s text-3xl font-bold tracking-normal text-white sm:text-5xl">
                        AAS Token <GradientText className="bg-gradient-to-r from-white via-blue-primary to-blue-primary">tailored Solutions</GradientText> <br/>for Sustainable Progress.
                    </h1>
                    <p className="animate__flipInX animate__animated animate__delay-1s mt-6 text-sm sm:text-lg leading-8 text-white">
                        Discover the future of Finance with AAS Token. Your Passport to a Decentralized World!
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link to="/sign-in" className="flex justify-center items-center animate__animated animate__delay-1s animate__backInLeft w-40 hover:bg-purple-primary rounded-lg bg-blue-primary px-3.5 py-2.5 text-sm font-bold text-white shadow-sm border border-blue-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                            Get Started
                        </Link>
                        <a href="https://www.youtube.com/watch?v=iz9zhHggdh0" target="_blank" rel="noreferrer" className="animate__animated animate__delay-1s animate__backInRight w-40 hover:bg-grey-primary flex justify-center items-center text-sm font-bold leading-6 px-3.5 py-2.5 text-white border shadow-sm border-blue-primary rounded-lg">
                            <PlayIcon className="mr-2"/>
                            Watch Video
                        </a>
                    </div>
                </div>
            </div>
            
        </Wrapper>
    );
}
 
export default HeroSection;