export const TelegramLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/telegram_xdlaab.png'} alt="telegram"/>
    )
}

export const XLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/X_pnxpjb.png'} alt="X"/>
    )
}

export const InstagramLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/instagram_zve5w2.png'} alt="instagram"/>
    )
}

export const FacebookLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/facebook_da369e.png'} alt="facebook"/>
    )
}

export const TiktokLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/tiktok_swbcms.png'} alt="tiktok"/>
    )
}

export const DiscordLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/discord_phbtoa.png'} alt="discord"/>
    )
}

export const YoutubeLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/youtube_lgptor.png'} alt="youtube"/>
    )
}

export const ThreadsLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/threads_zy6can.png'} alt="threads"/>
    )
}

export const EmailLogo = ({ ...rest }) => {
    return (
        <svg { ...rest } width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="65" height="65" rx="7.5" fill="#47B2E3" fillOpacity="0.1"/>
            <rect x="0.5" y="0.5" width="65" height="65" rx="7.5" stroke="#47B2E3"/>
            <path d="M38 24.5H28C25 24.5 23 26 23 29.5V36.5C23 40 25 41.5 28 41.5H38C41 41.5 43 40 43 36.5V29.5C43 26 41 24.5 38 24.5ZM38.47 30.59L35.34 33.09C34.68 33.62 33.84 33.88 33 33.88C32.16 33.88 31.31 33.62 30.66 33.09L27.53 30.59C27.21 30.33 27.16 29.85 27.41 29.53C27.67 29.21 28.14 29.15 28.46 29.41L31.59 31.91C32.35 32.52 33.64 32.52 34.4 31.91L37.53 29.41C37.85 29.15 38.33 29.2 38.58 29.53C38.84 29.85 38.79 30.33 38.47 30.59Z" fill="#47B2E3"/>
        </svg>
    )
}

export const ChatLogo = ({ ...rest }) => {
    return (
        <svg { ...rest } width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.833496" y="0.5" width="55" height="55" rx="7.5" fill="#47B2E3" fillOpacity="0.1"/>
            <rect x="0.833496" y="0.5" width="55" height="55" rx="7.5" stroke="#47B2E3"/>
            <path d="M33.3335 18H23.3335C20.5735 18 18.3335 20.23 18.3335 22.98V28.96V29.96C18.3335 32.71 20.5735 34.94 23.3335 34.94H24.8335C25.1035 34.94 25.4635 35.12 25.6335 35.34L27.1335 37.33C27.7935 38.21 28.8735 38.21 29.5335 37.33L31.0335 35.34C31.2235 35.09 31.5235 34.94 31.8335 34.94H33.3335C36.0935 34.94 38.3335 32.71 38.3335 29.96V22.98C38.3335 20.23 36.0935 18 33.3335 18ZM24.3335 28C23.7735 28 23.3335 27.55 23.3335 27C23.3335 26.45 23.7835 26 24.3335 26C24.8835 26 25.3335 26.45 25.3335 27C25.3335 27.55 24.8935 28 24.3335 28ZM28.3335 28C27.7735 28 27.3335 27.55 27.3335 27C27.3335 26.45 27.7835 26 28.3335 26C28.8835 26 29.3335 26.45 29.3335 27C29.3335 27.55 28.8935 28 28.3335 28ZM32.3335 28C31.7735 28 31.3335 27.55 31.3335 27C31.3335 26.45 31.7835 26 32.3335 26C32.8835 26 33.3335 26.45 33.3335 27C33.3335 27.55 32.8935 28 32.3335 28Z" fill="#47B2E3"/>
        </svg>
    )
}

export const PhoneLogo = ({ ...rest }) => {
    return (
        <svg { ...rest } width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.1665" y="0.5" width="55" height="55" rx="7.5" fill="#47B2E3" fillOpacity="0.1"/>
            <rect x="1.1665" y="0.5" width="55" height="55" rx="7.5" stroke="#47B2E3"/>
            <path d="M27.7165 30.95L25.8665 32.8C25.4765 33.19 24.8565 33.19 24.4565 32.81C24.3465 32.7 24.2365 32.6 24.1265 32.49C23.0965 31.45 22.1665 30.36 21.3365 29.22C20.5165 28.08 19.8565 26.94 19.3765 25.81C18.9065 24.67 18.6665 23.58 18.6665 22.54C18.6665 21.86 18.7865 21.21 19.0265 20.61C19.2665 20 19.6465 19.44 20.1765 18.94C20.8165 18.31 21.5165 18 22.2565 18C22.5365 18 22.8165 18.06 23.0665 18.18C23.3265 18.3 23.5565 18.48 23.7365 18.74L26.0565 22.01C26.2365 22.26 26.3665 22.49 26.4565 22.71C26.5465 22.92 26.5965 23.13 26.5965 23.32C26.5965 23.56 26.5265 23.8 26.3865 24.03C26.2565 24.26 26.0665 24.5 25.8265 24.74L25.0665 25.53C24.9565 25.64 24.9065 25.77 24.9065 25.93C24.9065 26.01 24.9165 26.08 24.9365 26.16C24.9665 26.24 24.9965 26.3 25.0165 26.36C25.1965 26.69 25.5065 27.12 25.9465 27.64C26.3965 28.16 26.8765 28.69 27.3965 29.22C27.4965 29.32 27.6065 29.42 27.7065 29.52C28.1065 29.91 28.1165 30.55 27.7165 30.95Z" fill="#47B2E3"/>
            <path d="M38.6366 34.33C38.6366 34.61 38.5866 34.9 38.4866 35.18C38.4566 35.26 38.4266 35.34 38.3866 35.42C38.2166 35.78 37.9966 36.12 37.7066 36.44C37.2166 36.98 36.6766 37.37 36.0666 37.62C36.0566 37.62 36.0466 37.63 36.0366 37.63C35.4466 37.87 34.8066 38 34.1166 38C33.0966 38 32.0066 37.76 30.8566 37.27C29.7066 36.78 28.5566 36.12 27.4166 35.29C27.0266 35 26.6366 34.71 26.2666 34.4L29.5366 31.13C29.8166 31.34 30.0666 31.5 30.2766 31.61C30.3266 31.63 30.3866 31.66 30.4566 31.69C30.5366 31.72 30.6166 31.73 30.7066 31.73C30.8766 31.73 31.0066 31.67 31.1166 31.56L31.8766 30.81C32.1266 30.56 32.3666 30.37 32.5966 30.25C32.8266 30.11 33.0566 30.04 33.3066 30.04C33.4966 30.04 33.6966 30.08 33.9166 30.17C34.1366 30.26 34.3666 30.39 34.6166 30.56L37.9266 32.91C38.1866 33.09 38.3666 33.3 38.4766 33.55C38.5766 33.8 38.6366 34.05 38.6366 34.33Z" fill="#47B2E3"/>
        </svg>
    )
}