import { useContext, useReducer } from "react";
import { formReducer } from "../../helpers/reducers";
import TopBar from "./TopBar";
import NgFlagIcon from "../../assets/icons/NgFlagIcon";
import { UserContext } from "../../contexts/UserContext";

const EditProfile = () => {
    const { user } = useContext(UserContext)
    const [formData, setFormData] = useReducer(formReducer, user ?? {});

    const handleChange = (event) => {
		const isChecked = event.target.type === 'checkbox';
		setFormData({
			name: event.target.name,
			value: isChecked ? event.target.checked : event.target.value,
		});
	};

    const handleUpdate = () => {
        console.log(formData)
    }

    return (
        <div className="text-white flex flex-col w-full">
            <TopBar title="Edit Profile" />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 xl:gap-6 p-5 lg:p-8">

                <div>
                    <label htmlFor="name" className="block text-sm font-medium leading-6">
                        Full Name
                    </label>
                    <div className="mt-2">
                        <input
                            id="name"
                            name="name"
                            placeholder="Enter your name"
                            required readOnly
                            onChange={handleChange}
                            value={formData.fullname || ''}
                            className="block w-full h-12 rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="username" className="block text-sm font-medium leading-6">
                        Username
                    </label>
                    <div className="mt-2">
                        <input
                            id="username"
                            name="username"
                            placeholder="Enter your username"
                            required readOnly
                            onChange={handleChange}
                            value={formData.username || ''}
                            className="block w-full h-12 rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6">
                        Email Address
                    </label>
                    <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            required
                            readOnly
                            autoComplete="email"
                            onChange={handleChange}
                            value={formData.email || ''}
                            className="block w-full h-12 rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="phone" className="block text-sm font-medium leading-6">
                        Phone Number
                    </label>

                    <div className="mt-2 relative">
                        <div className="pointer-events-none absolute inset-y-0 left-1 flex items-center pl-3">
                            <span className="text-gray sm:text-sm flex items-center">
                                <NgFlagIcon/>
                                <span className="text-grey-secondary text-xs ml-2"> | +234</span>
                            </span>
                        </div>
                        <input
                            id="phone"
                            name="phone"
                            maxLength={11}
                            placeholder="Enter your phone number"
                            required
                            readOnly
                            autoComplete="email"
                            onChange={handleChange}
                            value={formData.phone || ''}
                            className="block h-12 w-full rounded-md border-0 py-2 pl-24 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                        />
                    </div>
                </div>
            </div>

            <div className="hidden p-5 lg:p-8 mt-2 lg:mt-0 w-full">
                <button type="button" className="text-xs font-bold bg-light-blue rounded-lg border border-light-blue p-3 w-full lg:w-64" onClick={handleUpdate}>
                    Save Changes
                </button>
            </div>
        </div>
    );
}
 
export default EditProfile;