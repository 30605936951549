import PropTypes from "prop-types";
import { useMemo, useState } from "react";

const DataTable = ({
    fields = [], items = [],
    onSelected = () => {},
    selectable = false,
    itemsPerPage = 10
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = useMemo(() => Math.ceil(items.length / itemsPerPage), [items, itemsPerPage]);

    const currentItems = useMemo(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        return items.slice(start, end);
    }, [items, currentPage, itemsPerPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowSelect = (item) => {
        const isSelected = selectedItems.includes(item);
        const newSelectedItems = isSelected ? selectedItems.filter((i) => i !== item) : [...selectedItems, item];
    
        setSelectedItems(newSelectedItems);
        onSelected(newSelectedItems);
    };

    const handleSelectAll = () => {
        const allItems = items;
        setSelectedItems(allItems);
        onSelected(allItems);
    };

    const handleDeselectAll = () => {
        setSelectedItems([]);
        onSelected([]);
    };

    const pagesToShow = useMemo(() => {
        const range = [];
        const start = Math.max(2, currentPage - 1);
        const end = Math.min(totalPages - 1, currentPage + 1);
    
        if (totalPages > 5) {
          if (currentPage > 3) {
            range.push(1);
          }
    
          if (currentPage > 4) {
            range.push('...');
          }
    
          for (let i = start; i <= end; i++) {
            range.push(i);
          }
    
          if (currentPage < totalPages - 2) {
            range.push('...');
          }
    
          if (totalPages > 1) {
            range.push(totalPages);
          }
        } else {
          for (let i = 1; i <= totalPages; i++) {
            range.push(i);
          }
        }
    
        return range;
    }, [currentPage, totalPages]);

    return (
        <div className="flex flex-col h-full flex-grow">
            <div className="flex-grow">
                <table className="min-w-full divide-y divide-grey-secondary">
                    <thead className="bg-purple-primary">
                        <tr>
                            {
                                selectable && (
                                    <th className="px-6 py-3 text-left text-xs font-bold uppercase tracking-wider">
                                    <input
                                        type="checkbox"
                                        className="accent-blue-primary bg-purple-primary rounded-sm ring ring-grey-primary focus:outline-none focus:ring focus:ring-grey-primary"
                                        checked={selectedItems.length === items.length}
                                        onChange={(e) => (e.target.checked ? handleSelectAll() : handleDeselectAll())}
                                    />
                                    </th>
                                )
                            }
                            {
                                fields?.map((field) => (
                                    <th key={field.key} className="px-6 py-3 text-left text-xs font-bold capitalize tracking-wider">
                                        { field.label }
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-grey-secondary">
                        {
                            currentItems?.map((item, index) => (
                                <tr key={index}>
                                    {
                                        selectable && (
                                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                                                <input
                                                type="checkbox"
                                                className="accent-blue-primary bg-purple-primary rounded-sm ring ring-grey-primary focus:outline-none focus:ring focus:ring-grey-primary"
                                                checked={selectedItems.includes(item)}
                                                onChange={() => handleRowSelect(item)}
                                                />
                                            </td>
                                        )
                                    }
                                    {
                                        fields?.map((field) => (
                                            <td key={field.key} className="px-6 py-4 whitespace-nowrap text-sm">
                                                { field.formatter ? field.formatter(item) : item[field.key] ?? '' }
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <div className="flex flex-col lg:flex-row justify-between items-center mt-4 px-4 pb-3 pt-4 border-t border-grey-secondary">
                <div className="text-sm">
                    Page { currentPage } of { totalPages }
                </div>

                <div className="flex space-x-2 mt-3 lg:mt-0">
                    {
                        pagesToShow?.map((page, index) => (
                            <button
                                key={index}
                                className={`px-4 py-2 rounded-md text-sm ${page === currentPage ? 'bg-[#273347] text-light-blue' : 'bg-purple-primary'}`}
                                onClick={() => (typeof page === 'number') && handlePageChange(page)}
                                disabled={page === '...'}>
                                { page }
                            </button>
                        ))
                    }
                </div>

                <div className="flex space-x-2 mt-3 lg:mt-0">
                    <button
                        className="px-4 py-2 bg-purple-primary rounded-md text-sm w-32 flex items-center justify-center"
                        onClick={() => (currentPage > 1) && handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                        </svg>

                        Previous
                    </button>
                    <button
                        className="px-4 py-2 bg-purple-primary rounded-md text-sm w-32 flex items-center justify-center"
                        onClick={() => (currentPage < totalPages) && handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}>
                        Next

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}

DataTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        formatter: PropTypes.func,
      })
    ).isRequired,
    selectable: PropTypes.bool,
    onSelected: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number
};
 
export default DataTable;