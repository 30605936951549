import { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { formReducer } from "../../helpers/reducers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SessionService from "../../services/SessionService";
import { UserContext } from "../../contexts/UserContext";
import { jwtDecode } from "jwt-decode";
import validator from "validator";
import Alert from "../../helpers/alerts";
import AuthenticationService from "../../services/AuthenticationService";
import Loader from "../shared/Loader";
import PasswordInput from "../shared/PasswordInput";

const { saveSessionData } = SessionService()
const { signInUser } = AuthenticationService()

const SignIn = () => {
    const [formData, setFormData] = useReducer(formReducer, {});
    const [loading, toggleLoading] = useState(false)
    const { setUser } = useContext(UserContext)
    const { state } = useLocation()
    const navigate = useNavigate()
    let mounted = useRef(true)

    const navigationRoute = useMemo(() => {
        return (state && state.from) ? state.from : '/dashboard'
    }, [state])

    const handleSubmit = event => {
        event.preventDefault();
        const { email, password } = formData;
        toggleLoading(true);

        signInUser(email, password)
            .then(response => {
                if (response && mounted.current) {
                    const { token } = response
                    if (!token || (token && !validator.isJWT(token))) return Alert('Token not present/invalid. Kindly contact Support', {}, 'error');

                    const { user_id } = jwtDecode(token)
                    const userData = { ...response, user_id }
                    saveSessionData({ ...userData })
                    setUser({ ...userData })
                    setTimeout(() => {
                        if (mounted.current) {
                            navigate(navigationRoute)
                        }
                    }, 1500)
                } else {
                    Alert('Unable to login. Kindly check your credentials and try again.', {}, 'error')
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
    }

    const handleChange = (event) => {
		const isChecked = event.target.type === 'checkbox';
		setFormData({
			name: event.target.name,
			value: isChecked ? event.target.checked : event.target.value,
		});
	};

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        }
    }, [])

    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="bg-grey-primary rounded-xl p-4 text-white flex flex-col justify-center w-full sm:w-4/5 mx-auto lg:w-3/5 xl:w-1/2">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="text-center text-xl font-bold leading-9 tracking-tight">
                        Sign In
                    </h2>
                    <p className="text-sm text-center">Enter your credentials to access your account</p>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6">
                                Email
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="Enter your email"
                                    required
                                    autoComplete="email"
                                    onChange={handleChange}
                                    value={formData.email || ''}
                                    className="block w-full rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                />
                            </div>
                        </div>

                        <PasswordInput name={'password'} value={formData.password || ''} onChange={handleChange} />

                        <div className="flex items-start justify-between flex-col sm:items-center sm:flex-row px-1 sm:px-0">
                            <div className="my-1 sm:my-0">
                                <label className="text-xs" htmlFor="rememberMe">
                                    <input id="rememberMe" name="rememberMe" type="checkbox" checked={formData.rememberMe || false} onChange={handleChange} className="rounded-sm bg-purple-primary"/> <span className="ml-1">Remember me for 30 days</span>
                                </label>
                            </div>
                                
                            <div className="text-xs my-1 sm:my-0">
                                <Link to="/forgot-password" className="font-semibold text-light-blue hover:text-blue-primary">
                                    Forgot password?
                                </Link>
                            </div>
                        </div>

                        <div>
                            <button type="submit" disabled={loading} className="flex w-full justify-center items-center rounded-md bg-blue-primary px-3 py-2 text-sm font-semibold leading-6 shadow-sm hover:bg-purple-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray">
                                { loading ? (<Loader className="mr-2"/>) : '' }
                                Log into Account
                            </button>
                        </div>
                    </form>

                    <p className="my-6 text-center text-sm">
                        Are you new here?{'  '}
                        <Link to="/sign-up" className="font-semibold leading-6 text-light-blue hover:text-blue-primary">
                            Create Account
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
 
export default SignIn;