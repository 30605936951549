import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { overviewStats } from "../../data/dashboardData";
import AddWallet from "../shared/AddWallet";
import { toCurrency } from "../../helpers/utils";
import DashboardService from "../../services/DashboardService";
import PropTypes from "prop-types";

const { checkWalletExists, getAccountRate } = DashboardService()

const TransactionStats = ({ transactions = [] }) => {
    const [walletAddress, setWalletAddress] = useState('')
    const [loading, toggleLoading] = useState(false)
    let mounted = useRef(true)
    let dataFetched = useRef(false)

    const pendingTransactions = useMemo(() => (transactions.reduce((total, tx) => total += (tx.status === '0') ? parseFloat(tx.amount) : 0, 0)), [transactions])
    const failedTransactions = useMemo(() => (transactions.reduce((total, tx) => total += (tx.status === '-1') ? parseFloat(tx.amount) : 0, 0)), [transactions])
    const successfulTransactions = useMemo(() => (transactions.reduce((total, tx) => total += (tx.status === '1') ? parseFloat(tx.amount) : 0, 0)), [transactions])

    useEffect(() => {
        mounted.current = true
        if (dataFetched.current) return;

        const getData = async () => {
            toggleLoading(true)

            await Promise.all([
                checkWalletExists(),
                getAccountRate()
            ])
                .then(results => {
                    const [walletResponse, rateResponse] = results
                    if (mounted.current) {
                        if (walletResponse) {
                            setWalletAddress(walletResponse?.wallet_address ?? '')
                        }
                        if (rateResponse) {

                        }
                    }
                })
                .finally(() => {
                    if (mounted.current) {
                        dataFetched.current = false
                        toggleLoading(false)
                    }
                })
        }

        getData()
        dataFetched.current = true;

        return () => {
            setWalletAddress('')
            mounted.current = false
        }
    }, [])

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-5 gap-4 p-3 lg:p-5">

            <div className="bg-grey-primary rounded-lg p-4 lg:p-6 xl:p-5 lg:col-span-3 xl:col-span-2 relative min-h-40">
                <img className="absolute z-0 bottom-0 right-0 opacity-30 w-16" src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/litecoin-right_owe3le.png'} alt="litecoin infographic 2"/>
                {
                    !walletAddress ? (
                        <Fragment>
                            <div className="text-xs w-2/3 lg:w-1/2">
                                <span className="leading-5">
                                    Please add your token wallet address to your dashboard to get started.
                                </span>
                            </div>

                            <AddWallet disabled={loading} onWalletAdded={address => setWalletAddress(address)}/>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="mt-3">
                                    <label htmlFor="wallet_address" className="block text-sm font-medium leading-6">
                                        Wallet Address
                                    </label>
                                    <div className="mt-2 relative w-4/5">
                                        
                                        <input
                                            id="wallet_address"
                                            name="wallet_address"
                                            readOnly
                                            value={walletAddress || ''}
                                            className="block w-full h-12 rounded-md border border-grey-primary py-2 pr-8 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-0 focus:border-gray focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                        />
                                    </div>
                                </div>
                        </Fragment>
                    )
                }
            </div>

            {
                overviewStats(pendingTransactions, failedTransactions, successfulTransactions)?.map(({ Icon, title, figure }, index) => (
                    <div className="bg-grey-primary rounded-lg p-4 lg:col-span-1 lg:p-6 xl:p-5 min-h-40" key={index}>
                        <Icon/>

                        <p className="text-sm font-light mt-5">{ title }</p>

                        <h3 className="font-bold mt-2 text-2xl">{ toCurrency(figure, 'USD') }</h3>
                    </div>
                ))
            }
        </div>
    );
}

TransactionStats.propTypes = {
    transactions: PropTypes.array,
}
 
export default TransactionStats;