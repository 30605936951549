import styled from "styled-components"
import AliceCarousel from 'react-alice-carousel';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
    height: fit-content;
    width: 100%;
    padding: 4em;
    .alice-carousel__prev-btn,
	.alice-carousel__next-btn {
        width: unset;
        position: absolute;
        bottom: 0;
    }
    .alice-carousel__prev-btn {
        right: 5%;
    }
    .alice-carousel__next-btn {
        right: 2%;
    }
    .alice-carousel__dots-item.__custom {
        color: gray;
    }
    .alice-carousel__dots-item.__active.__custom {
        color: var(--light-blue);
    }
    @media screen and (max-width: 1030px) {
        .alice-carousel__prev-btn {
            right: 10%;
        }
        .alice-carousel__next-btn {
            right: 4%;
        }
    }
    @media screen and (max-width: 800px) {
        padding: 3em;
        .alice-carousel__prev-btn {
            right: 15%;
        }
        .alice-carousel__next-btn {
            right: 8%;
        }
    }
    @media screen and (max-width: 500px) {
        padding: 2em;
        .alice-carousel__prev-btn,
	    .alice-carousel__next-btn {
            bottom: -20%;
        }
        .alice-carousel__prev-btn {
            right: 50%;
        }
        .alice-carousel__next-btn {
            right: 35%;
        }
    }
`

const SlideSection = ({ items, title }) => {
    return (
        <Wrapper>
            <h2 className="font-bold text-lg lg:text-3xl text-white text-center my-4">{ title }</h2>
            <AliceCarousel
                items={items}
                autoPlay={true}
                autoPlayInterval={3000}
				disableAutoPlayOnAction={true}
                infinite={true}
                responsive={{
					0: { items: 1 },
					600: {
						items: 1,
					},
					750: {
						items: 2
					},
					1024: {
						items: 3,
					},
					1800: {
						items: 4,
					},
				}}
                renderPrevButton={({ isDisabled }) => (
                    <button disabled={isDisabled} className="bg-grey-primary p-2 text-white border-none rounded-full w-8 h-8 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                    </button>
                )}
                renderNextButton={({ isDisabled }) => (
                    <button disabled={isDisabled} className="bg-grey-primary p-2 text-white border-none rounded-full w-8 h-8 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                )}
                renderDotsItem={() => (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                    </svg>

                )}/>
        </Wrapper>
    );
}

SlideSection.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
}

export default SlideSection;