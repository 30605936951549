import { Fragment, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ecosystemData } from "../../data/ecosystemData";
import styled from "styled-components";
import NewsletterSection from "../home/NewsletterSection";

const Image = styled.img`
    height: 700px;
    @media (max-width: 800px) {
        height: 500px;
    }
    @media (max-width: 500px) {
        height: 300px;
    }

`

const EcosystemDetails = () => {
    const { key } = useParams()
    const details = useMemo(() => ecosystemData.find(datum => datum.key === key) ?? {}, [key]);

    return (
        <div className="min-h-screen py-6 flex flex-col w-full text-white">
            <div className="flex flex-col w-full border-b border-grey-primary pb-10 bg-purple-primary relative z-1">
                <div className="w-full sm:w-4/5 lg:w-3/5 mx-auto px-3 lg:px-8">
                    <h2 className="text-center font-bold text-2xl lg:text-4xl">{ details.detailHeading }</h2>

                    <p className="text-center text-sm my-3">{ details.detailSubheading }</p>
                </div>

                <Image src={details.backgroundImage} alt={details.name} className="w-screen block mt-6"/>

                <div className="px-3 lg:px-8 w-full sm:w-4/5 lg:w-3/5 xl:w-1/2 mx-auto my-6">
                    <h2 className="text-center sm:text-left font-bold text-2xl lg:text-3xl uppercase">{ details.featureHeading }</h2>

                    { details?.featureSubheading && (<p className="pt-2 text-sm">{ details.featureSubheading }</p>) }

                    {
                        !!(details?.features && Array.isArray(details.features)) ? (
                            <ul className="list-disc p-4">
                                {
                                    details.features.map((feature, index) => (
                                        <li key={index} className="text-sm leading-6">{ feature }</li>
                                    ))
                                }
                            </ul>
                        ) : (
                            <p className="py-4 text-sm leading-5">{ details.features }</p>
                        )
                    }

                    <img src={details.supportingImage} alt={details.name} className="rounded-2xl lg:rounded-lg block object-cover w-full mt-4"/>

                    <h2 className="text-center sm:text-left font-bold text-xl lg:text-2xl uppercase mt-4">{ details?.benefitHeading || 'Benefits' }</h2>

                    { details?.benefitSubheading && (<p className="pt-2 text-sm leading-5">{ details.benefitSubheading }</p>) }

                    <ul className="list-disc p-4">
                        {
                            details?.benefits?.map((benefit, index) => (
                                <li key={index} className="text-sm leading-6">
                                    { benefit }
                                </li>
                            ))
                        }
                    </ul>

                    {
                        details?.extras && (
                            <Fragment>
                                <h2 className="text-center sm:text-left font-bold text-xl lg:text-2xl uppercase mt-4">{ details.extras?.extraHeading }</h2>

                                <ul className="list-disc p-4">
                                    {
                                        details.extras?.extraContent?.map((extra, index) => (
                                            <li key={index} className="text-sm leading-6">
                                                { extra }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Fragment>
                        )
                    }
                </div>
            </div>

            <NewsletterSection/>
        </div>
    );
}
 
export default EcosystemDetails;