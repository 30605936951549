export const DashboardIcon = ({ ...rest }) => {
    return (
        <svg { ...rest } width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3333 7.10002V3.31669C18.3333 2.14169 17.8 1.66669 16.475 1.66669H13.1083C11.7833 1.66669 11.25 2.14169 11.25 3.31669V7.09169C11.25 8.27502 11.7833 8.74169 13.1083 8.74169H16.475C17.8 8.75002 18.3333 8.27502 18.3333 7.10002Z" fill="currentColor"/>
            <path d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z" fill="currentColor"/>
            <path d="M8.74935 7.10002V3.31669C8.74935 2.14169 8.21602 1.66669 6.89102 1.66669H3.52435C2.19935 1.66669 1.66602 2.14169 1.66602 3.31669V7.09169C1.66602 8.27502 2.19935 8.74169 3.52435 8.74169H6.89102C8.21602 8.75002 8.74935 8.27502 8.74935 7.10002Z" fill="currentColor"/>
            <path d="M8.74935 16.475V13.1083C8.74935 11.7833 8.21602 11.25 6.89102 11.25H3.52435C2.19935 11.25 1.66602 11.7833 1.66602 13.1083V16.475C1.66602 17.8 2.19935 18.3333 3.52435 18.3333H6.89102C8.21602 18.3333 8.74935 17.8 8.74935 16.475Z" fill="currentColor"/>
        </svg>
        
    )
}

export const BuyTokenIcon = ({ ...rest }) => {
    return (
        <svg { ...rest } width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66602 14.2163H9.99935" stroke="currentColor" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.51599 11.25H8.14937C9.63271 11.25 9.99935 11.6167 9.99935 13.0833V16.5083C9.99935 17.975 9.63271 18.3417 8.14937 18.3417H3.51599C2.03266 18.3417 1.66602 17.975 1.66602 16.5083V13.0833C1.66602 11.6167 2.03266 11.25 3.51599 11.25Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.3333 12.4996C18.3333 15.7246 15.725 18.333 12.5 18.333L13.375 16.8746" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.66602 7.49965C1.66602 4.27465 4.27435 1.66632 7.49935 1.66632L6.62436 3.12465" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.416 9.16669C17.4871 9.16669 19.166 7.48775 19.166 5.41669C19.166 3.34562 17.4871 1.66669 15.416 1.66669C13.3449 1.66669 11.666 3.34562 11.666 5.41669C11.666 7.48775 13.3449 9.16669 15.416 9.16669Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const TransactionsIcon = ({ ...rest }) => {
    return (
        <svg { ...rest } width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3086 7.3999H14.6836" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.31641 7.3999L5.94141 8.0249L7.81641 6.1499" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.3086 13.2332H14.6836" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.31641 13.2332L5.94141 13.8582L7.81641 11.9832" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.49935 18.3334H12.4993C16.666 18.3334 18.3327 16.6667 18.3327 12.5V7.50002C18.3327 3.33335 16.666 1.66669 12.4993 1.66669H7.49935C3.33268 1.66669 1.66602 3.33335 1.66602 7.50002V12.5C1.66602 16.6667 3.33268 18.3334 7.49935 18.3334Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    )
}

export const ReferralIcon = ({ ...rest }) => {
    return (
        <svg { ...rest } width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1348 5.1416C15.8014 6.29993 16.9514 8.1416 17.1848 10.2666" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.90625 10.3081C3.12292 8.19144 4.25625 6.34977 5.90625 5.18311" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.82617 17.4504C7.79284 17.942 8.89284 18.217 10.0512 18.217C11.1678 18.217 12.2178 17.967 13.1595 17.5087" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.051 6.41635C11.3305 6.41635 12.3677 5.37915 12.3677 4.09969C12.3677 2.82023 11.3305 1.78302 10.051 1.78302C8.77158 1.78302 7.73438 2.82023 7.73438 4.09969C7.73438 5.37915 8.77158 6.41635 10.051 6.41635Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.02565 16.6003C5.30511 16.6003 6.34232 15.5631 6.34232 14.2836C6.34232 13.0042 5.30511 11.967 4.02565 11.967C2.74619 11.967 1.70898 13.0042 1.70898 14.2836C1.70898 15.5631 2.74619 16.6003 4.02565 16.6003Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.9749 16.6003C17.2543 16.6003 18.2915 15.5631 18.2915 14.2836C18.2915 13.0042 17.2543 11.967 15.9749 11.967C14.6954 11.967 13.6582 13.0042 13.6582 14.2836C13.6582 15.5631 14.6954 16.6003 15.9749 16.6003Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

 export const LogoutIcon = ({ ...rest }) => {
    return (
        <svg { ...rest } width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10L15 10M15 10L13 8M15 10L13 12M11 14L11 17C11 18.1046 10.1046 19 9 19L3 19C1.89543 19 1 18.1046 1 17L1 3C1 1.89543 1.89543 1 3 1L9 1C10.1046 1 11 1.89543 11 3L11 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
 }

export const PendingIcon = ({ ...rest }) => {
    return (
        <svg width="45" height="44" { ...rest } viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="42.7721" height="42.4209" rx="8" fill="#B19502" fillOpacity="0.15"/>
            <rect x="1" y="1" width="42.7721" height="42.4209" rx="8" stroke="#B19502"/>
            <path d="M28.7618 14.8567C28.6826 14.3101 28.1753 13.9312 27.6288 14.0103L18.7217 15.3004C18.1751 15.3795 17.7962 15.8868 17.8754 16.4334C17.9545 16.98 18.4618 17.3589 19.0084 17.2797L26.9258 16.133L28.0725 24.0504C28.1516 24.597 28.6589 24.9759 29.2055 24.8968C29.7521 24.8176 30.131 24.3103 30.0518 23.7637L28.7618 14.8567ZM17.8012 30.0193L28.5733 15.5985L26.9709 14.4016L16.1988 28.8224L17.8012 30.0193Z" fill="#B19502"/>
        </svg>
        
    )
}

export const FailedIcon = ({ ...rest }) => {
    return (
        <svg width="45" height="44" { ...rest } viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="43.1459" height="42.134" rx="8" fill="#F67867" fillOpacity="0.15"/>
            <rect x="1" y="1" width="43.1459" height="42.134" rx="8" stroke="#F67867"/>
            <path d="M16.0075 29.2513C16.0723 29.7998 16.5695 30.1919 17.118 30.127L26.0557 29.0706C26.6042 29.0058 26.9963 28.5086 26.9314 27.9601C26.8666 27.4117 26.3694 27.0196 25.821 27.0844L17.8763 28.0235L16.9372 20.0788C16.8724 19.5303 16.3752 19.1383 15.8268 19.2031C15.2783 19.2679 14.8862 19.7651 14.9511 20.3136L16.0075 29.2513ZM27.3613 14.3808L16.2154 28.5147L17.7858 29.7532L28.9317 15.6192L27.3613 14.3808Z" fill="#F67867"/>
        </svg>
        
    )
}

export const CompletedIcon = ({ ...rest }) => {
    return (
        <svg width="44" height="44" { ...rest } viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="42" height="42" rx="8" fill="#02B15A" fillOpacity="0.15"/>
            <rect x="1" y="1" width="42" height="42" rx="8" stroke="#02B15A"/>
            <path d="M18.8883 29.4244L11.7383 22.0911L13.0216 20.8077L18.8883 26.7661L30.9883 14.5744L32.2716 15.8577L18.8883 29.4244Z" fill="#02B15A"/>
        </svg>
    )
}

export const XReferral = ({ ...rest }) => {
    return (
        <svg { ...rest } width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_708_9369)">
                <path d="M6.7918 18.1246C14.3371 18.1246 18.4652 11.8719 18.4652 6.45118C18.4652 6.2754 18.4613 6.09572 18.4535 5.91993C19.2566 5.33918 19.9496 4.61985 20.5 3.79572C19.7521 4.12847 18.958 4.34579 18.1449 4.44025C19.0011 3.92706 19.6421 3.12086 19.9492 2.17111C19.1438 2.64843 18.263 2.98514 17.3445 3.16681C16.7257 2.50927 15.9075 2.0739 15.0164 1.92801C14.1253 1.78212 13.211 1.93384 12.4148 2.35971C11.6186 2.78559 10.9848 3.46189 10.6115 4.28406C10.2382 5.10623 10.1462 6.02848 10.3496 6.90822C8.71874 6.82638 7.12328 6.40272 5.66665 5.66472C4.21002 4.92671 2.92474 3.89083 1.89414 2.62423C1.37033 3.52734 1.21005 4.596 1.44586 5.61304C1.68167 6.63008 2.29589 7.51917 3.16367 8.09962C2.51219 8.07894 1.87498 7.90353 1.30469 7.5879V7.63868C1.3041 8.58643 1.63175 9.50512 2.23192 10.2386C2.8321 10.9721 3.66777 11.4751 4.59687 11.6621C3.99338 11.8272 3.35999 11.8513 2.7457 11.7324C3.00788 12.5475 3.51798 13.2604 4.20481 13.7716C4.89164 14.2828 5.72093 14.5668 6.57695 14.584C5.12369 15.7256 3.32848 16.3447 1.48047 16.3418C1.15274 16.3413 0.825333 16.3212 0.5 16.2817C2.37738 17.4861 4.56128 18.1258 6.7918 18.1246Z" fill="#98A2B3"/>
            </g>
            <defs>
                <clipPath id="clip0_708_9369">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
        
    )
}

export const FacebookReferral = ({ ...rest }) => {
    return (
        <svg { ...rest } width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_708_9372)">
                <path d="M20.5 10C20.5 4.47715 16.0229 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 14.9912 4.15684 19.1283 8.9375 19.8785V12.8906H6.39844V10H8.9375V7.79688C8.9375 5.29063 10.4305 3.90625 12.7146 3.90625C13.8084 3.90625 14.9531 4.10156 14.9531 4.10156V6.5625H13.6922C12.45 6.5625 12.0625 7.3334 12.0625 8.125V10H14.8359L14.3926 12.8906H12.0625V19.8785C16.8432 19.1283 20.5 14.9912 20.5 10Z" fill="#98A2B3"/>
            </g>
            <defs>
                <clipPath id="clip0_708_9372">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
        
    )
}

export const LinkedinReferral = ({ ...rest }) => {
    return (
        <svg { ...rest } width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0195 0H1.97656C1.16016 0 0.5 0.644531 0.5 1.44141V18.5547C0.5 19.3516 1.16016 20 1.97656 20H19.0195C19.8359 20 20.5 19.3516 20.5 18.5586V1.44141C20.5 0.644531 19.8359 0 19.0195 0ZM6.43359 17.043H3.46484V7.49609H6.43359V17.043ZM4.94922 6.19531C3.99609 6.19531 3.22656 5.42578 3.22656 4.47656C3.22656 3.52734 3.99609 2.75781 4.94922 2.75781C5.89844 2.75781 6.66797 3.52734 6.66797 4.47656C6.66797 5.42188 5.89844 6.19531 4.94922 6.19531ZM17.543 17.043H14.5781V12.4023C14.5781 11.2969 14.5586 9.87109 13.0352 9.87109C11.4922 9.87109 11.2578 11.0781 11.2578 12.3242V17.043H8.29688V7.49609H11.1406V8.80078H11.1797C11.5742 8.05078 12.543 7.25781 13.9844 7.25781C16.9883 7.25781 17.543 9.23438 17.543 11.8047V17.043Z" fill="#98A2B3"/>
        </svg>
        
    )
}