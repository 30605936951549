import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import SessionService from "../services/SessionService";

const { isTokenExpired, getSessionData, removeSessionData } = SessionService()

const EXPIRED = 'expired';
const INVALID = 'invalid';
const VALID = 'valid';

const Guard = ({
    component: Component,
    routeRedirect = "/sign-in",
    ...rest
}) => {
    let mounted = useRef(true)
    const getTokenValidity = () => {
        const token = getSessionData()?.token
        if (!token) {
            removeSessionData()
            return INVALID;
        } else if (token && isTokenExpired(token)) {
            removeSessionData()
            return EXPIRED;
        } else {
            return VALID;
        }
    }
    const { pathname } = useLocation();
    const [tokenStatus, setTokenStatus] = useState(getTokenValidity())

    const navigate = () => mounted.current ? (<Navigate to={{ pathname: routeRedirect }} state={{ from: pathname }} replace={true}/>) : undefined;

    const requireAuthentication = useMemo(() => {
        return !!([EXPIRED, INVALID].includes(tokenStatus))
    }, [tokenStatus])

    useEffect(() => {
        mounted.current = true;

        setTokenStatus(getTokenValidity())

        return () => {
            setTokenStatus(INVALID)
            mounted.current = false;
        }
    }, [pathname])

    return (
        requireAuthentication ? navigate() : (<Component {...rest}/>)
    );
}

Guard.propTypes = {
    component: PropTypes.elementType.isRequired,
    routeRedirect: PropTypes.string
}
 
export default Guard;