import ProductLogo from "../../assets/icons/logo";
import { Link } from "react-router-dom";
import footerLogoLarge from "../../assets/images/footer_logo_large.png";
import { footerData } from "../../data/footerData";
import FooterLogos from "./FooterLogos";

const getYear = () => (new Date()).getFullYear()

const Footer = () => {
    return (
        <div className="bg-purple-primary p-4 w-full text-white sm:px-12 border-t border-gray border-solid">
            <div className="w-full flex flex-col lg:flex-row lg:justify-evenly py-6 border-b border-gray border-solid">
                <div className="flex flex-col">
                    <ProductLogo className="h-8 w-10 my-2"/>
                    <p className="text-sm mt-2">Empower your ventures with AAST Token:</p>
                    <p className="text-sm">Customized Solutions for Sustainable Growth</p>
                    <FooterLogos className="justify-start"/>
                </div>

                <div className="grid grid-cols-2 gap-x-8 gap-y-10 lg:grid-cols-4 lg:gap-x-10 xl:gap-x-24 mt-6">
                    {
                        footerData?.map((datum, index) => (
                            <div className="flex-col flex tracking-wide" key={`${datum.title}-${index}`}>
                                <h6 className="text-light-blue mb-3 font-bold">{ datum.title }</h6>
                                {
                                    datum?.children.map((child, idx) =>  !!child.external ? (
                                        <a key={`${child.title}-${idx}`} rel="noreferrer" target={child.target ? '_blank' : undefined} href={child.link} className="leading-7 text-xs font-semibold">{ child.title }</a>
                                    ) : (
                                        <Link key={`${child.title}-${idx}`} to={child.link} className="leading-7 text-xs font-semibold">{ child.title }</Link>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="flex flex-col sm:flex-row p-6 items-center justify-center sm:justify-start tracking-wider font-semibold text-sm">
                <span className="whitespace-nowrap sm:mx-1">Copyright <span className="text-light-blue">AGC</span> { getYear() }.</span>
                <span className="whitespace-nowrap sm:mx-1">All Rights Reserved.</span>
            </div>
            <div className="w-full p-4 hidden md:block">
                <img src={footerLogoLarge} alt="AAST Large" className="w-3/5 xl:w-2/4 mx-auto block object-cover"/>
            </div>
        </div>
    );
}
 
export default Footer;