import { createContext, useReducer } from "react";
import { userReducer } from "../helpers/reducers";
import PropTypes from "prop-types";
import SessionService from "../services/SessionService";

const { getSessionData } = SessionService()

const UserContext = createContext('')

const UserProvider = ({ children }) => {
    const [user, setUser] = useReducer(userReducer, getSessionData() ?? {})

    return (
        <UserContext.Provider value={{ user, setUser }}>
            { children }
        </UserContext.Provider>
    );
}

 UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
 }
 
export { UserProvider, UserContext };