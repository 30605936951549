import { XFooterLogo, FacebookFooterLogo, TelegramFooterLogo } from "../assets/icons/footerLogos";

export const footerData = [
    {
        title: 'Platform',
        children: [
            {
                title: 'About',
                link: '/about'
            },
            {
                title: 'Careers',
                link: '/careers'
            },
            {
                title: 'News',
                link: '/blog'
            },
            {
                title: 'Press',
                link: '/press'
            },
            {
                title: 'Blog',
                link: '/blog'
            },
        ]
    },
    {
        title: 'Find us on',
        children: [
            {
                title: 'CoinMarketCap',
                link: 'https://coinmarketcap.com/currencies/aastoken/',
                external: true,
                target: true
            },
            {
                title: 'Coingecko',
                link: 'https://www.coingecko.com/en/coins/aastoken',
                external: true,
                target: true
            },
            {
                title: 'Exchanges',
                link: '/#buyTokens',
                external: true,
            },
            {
                title: 'Community',
                link: '/#community',
                external: true,
            },
            {
                title: 'Contact Us',
                link: '/contact-us'
            },
        ]
    },
    {
        title: 'Eco System',
        children: [
            {
                title: 'Real Estate',
                link: '/ecosystem/real-estate'
            },
            {
                title: 'Academy',
                link: '/ecosystem/academy'
            },
            {
                title: 'E-Commerce',
                link: '/ecosystem/commerce'
            },
            {
                title: 'Payment System',
                link: '/ecosystem/payment'
            },
            {
                title: 'Staking/Swap',
                link: '/swap'
            },
            {
                title: 'Charity',
                link: 'https://afriqjmfoundation.org/',
                external: true,
                target: true
            },
        ]
    },
    {
        title: 'Resources',
        children: [
            {
                title: 'Podcast',
                link: '/podcast'
            },
            {
                title: 'Audit',
                link: "https://www.cyberscope.io/audits/aas?s=09",
                external: true,
                target: true
            },
            {
                title: 'Support',
                link: '/contact-us'
            },
            {
                title: 'Whitepaper',
                link: '/whitepaper'
            },
            {
                title: 'FAQ',
                link: '/#faqs',
                external: true,
            },
            {
                title: 'Our Partners',
                link: '/partners'
            },
        ]
    },
]

export const footerLogos = [
    { Logo: XFooterLogo, link: 'https://x.com/AfriqArbitrage' },
    { Logo: FacebookFooterLogo, link: 'https://www.facebook.com/afriqjmarbitrage' },
    { Logo: TelegramFooterLogo, link: 'https://t.me/+xXMoBNbBPFBjOTI0' },
];