import PropTypes from "prop-types";
import EyeIcon from "../../assets/icons/EyeIcon";
import EyeSlashIcon from "../../assets/icons/EyeSlashIcon";
import { Fragment, useMemo, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";

const PASSWORD = "password";
const TEXT = "text";

const PasswordInput = ({
    children,
    label = 'Password',
    placeholder = 'Enter your password',
    minLength = 8,
    name,
    onChange,
    onInput,
    showPasswordStrength = false,
    showStrengthGuide = false,
    value,
    ...rest
}) => {
    const [type, setType] = useState(PASSWORD)

    const identifier = useMemo(() => label.toLowerCase().replaceAll(' ', '_'), [label])
    
    const handleEmit = (event) => {
        if (onInput) {
            onInput(event)
        }
        if (onChange) {
            onChange(event)
        }
    }

    const handlePasswordViewToggle = () => (type === PASSWORD) ? setType(TEXT) : setType(PASSWORD);

    return (
        <div { ...rest }>
            <label htmlFor={identifier} className="block text-sm font-medium leading-6">
                { label }
            </label>
            <div className="mt-2 relative">
                <input
                    id={identifier}
                    name={name}
                    type={type}
                    required
                    placeholder={placeholder}
                    autoComplete="current-password"
                    onChange={handleEmit}
                    value={value || ''}
                    className="block relative z-10 w-full rounded-md border-0 py-2 pr-12 shadow-sm placeholder:text-xs ring-1 ring-inset ring-purple-primary placeholder:text-gray focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                />
                <button className="absolute transition-all z-20 inset-y-0 right-3 flex items-center justify-center" type="button" onClick={handlePasswordViewToggle}>
                    {
                        (type === PASSWORD) ? (
                            <EyeIcon className={`size-5 text-gray-400`} />
                        ) : (
                            <EyeSlashIcon className={`size-5 text-gray-400`}/>
                        )
                    }
                    
                    
                </button>
            </div>
            {
                showPasswordStrength ? (
                    <Fragment>
                        <PasswordStrengthBar scoreWordStyle={{ fontSize: '0.75rem', lineHeight: '1rem' }} password={value || ''} className="mt-4" barColors={['#181A2A', '#0055E9', '#0055E9', '#0055E9', '#0055E9']} minLength={minLength}/>

                        {
                            showStrengthGuide ? (
                                <ul className="text-xs list-disc leading-6 px-4">
                                    <li>Upper and Lower case letters</li>
                                    <li>More than eight (8) characters</li>
                                    <li>Should contain a number and symbol</li>
                                </ul>
                            ) : (<></>)
                        }
                    </Fragment>
                ) : (<></>)
            }
            {
                children
            }
        </div>
    );
}

PasswordInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    minLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    showPasswordStrength: PropTypes.bool,
    showStrengthGuide: PropTypes.bool,
    value: PropTypes.any
}
 
export default PasswordInput;