import Timeline from "../shared/Timeline";
import { roadmapData } from "../../data/roadmapData";

const RoadmapSection = () => {
    return (
        <div className="relative bg-[#181a2a] text-white py-16 flex flex-col justify-center items-center">
            <div className="text-center">
                <h3 className="my-2 text-lg lg:text-3xl font-bold">Road Map</h3>
                <small className="block text-xs lg:text-lg">We translate your dream to reality</small>
            </div>

            <div className="overflow-hidden whitespace-nowrap w-full">
                <Timeline items={roadmapData.concat(roadmapData).concat(roadmapData)}/>
            </div>
        </div>
    );
}
 
export default RoadmapSection;