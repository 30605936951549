import { PhoneLogo, ChatLogo, XLogo, TelegramLogo, InstagramLogo, FacebookLogo, TiktokLogo, DiscordLogo, YoutubeLogo, ThreadsLogo,
    // EmailLogo 
} from "../assets/icons/contactLogos";

export const contactData = [
    // { Logo: EmailLogo, title: "Email Us", disclaimer: "We respond within 8 hours", info: "mailto:cs@afriqjmcoin.zendesk.com" },
    { Logo: ChatLogo, title: "Chat with Us", disclaimer: "We respond within 8 hours", info: "mailto:cs@afriqjmcoin.zendesk.com" },
    { Logo: PhoneLogo, title: "Call Us", disclaimer: "Mon-Fri from 8am to 5pm", info: "tel:+2347010998676" },
];

export const contactLogos = [
    { Logo: XLogo, link: 'https://x.com/AfriqArbitrage' },
    { Logo: TelegramLogo, link: 'https://t.me/+xXMoBNbBPFBjOTI0' },
    { Logo: InstagramLogo, link: 'https://www.instagram.com/afriq_arbitrage' },
    { Logo: FacebookLogo, link: 'https://www.facebook.com/afriqjmarbitrage' },
    { Logo: TiktokLogo, link: 'https://www.tiktok.com/@afriqarbitrage' },
    { Logo: DiscordLogo, link: '' },
    { Logo: YoutubeLogo, link: 'https://youtube.com/@afriqarbitrage' },
    { Logo: ThreadsLogo, link: 'https://www.threads.net/@afriq_arbitrage/' },
]