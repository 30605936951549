import { toast } from "react-toastify";

const Alert = (message = '', props = {}, type = 'info', error = null) => {
    if (!['info', 'success', 'error', 'warning'].includes(type)) return;

    let alert = toast[type]
    
    if ((type === 'error') && error) {
        // let errorData = error?.response?.data ?? error;
        // message += errorData?.message ? `. ${errorData.message}` : ``;
        // if (errorData.errors) {
        //     message += Object.values(errorData.errors).join(". ");
        // }
        console.error(error)
    }

    if (['info', 'success'].includes(type) && !message) {
        message = 'Success';
    }
    
    return alert(message.trim(), { ...props, toastId: 'customToast' });
}

export default Alert;