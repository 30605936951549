import { useEffect, useReducer, useRef, useState } from "react";
import { formReducer } from "../../helpers/reducers";
import { Link, useNavigate } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import Loader from "../shared/Loader";
import PasswordInput from "../shared/PasswordInput";

const { registerUser } = AuthenticationService();

const Register = () => {
    const [formData, setFormData] = useReducer(formReducer, {});
    const [loading, toggleLoading] = useState(false)
    let mounted = useRef(true)
    const navigate = useNavigate()

    const handleSubmit = event => {
        event.preventDefault();
        
        toggleLoading(true)
        registerUser(formData)
            .then(response => {
                if (response && mounted.current) {
                    navigate(`/confirm-registration?email=${formData.email ?? response.email}`)
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
    }

    const handleChange = (event) => {
		const isChecked = event.target.type === 'checkbox';
		setFormData({
			name: event.target.name,
			value: isChecked ? event.target.checked : event.target.value,
		});
	};

    useEffect(() => {
        mounted.current = true

        return () => {
            setFormData(null)
            mounted.current = false
        }
    }, [])

    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="bg-grey-primary rounded-xl p-4 text-white flex flex-col justify-center w-full sm:w-4/5 mx-auto lg:w-3/5 xl:w-1/2">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="text-center text-xl font-bold leading-9 tracking-tight">
                        Sign Up
                    </h2>
                    <p className="text-sm text-center">Sign Up to create your account</p>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="fullname" className="block text-sm font-medium leading-6">
                                Full Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="fullname"
                                    name="fullname"
                                    placeholder="Enter your full name"
                                    required
                                    onChange={handleChange}
                                    value={formData.fullname || ''}
                                    className="block w-full rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6">
                                Email
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="Enter your email"
                                    required
                                    autoComplete="email"
                                    onChange={handleChange}
                                    value={formData.email || ''}
                                    className="block w-full rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="phone" className="block text-sm font-medium leading-6">
                                Phone Number
                            </label>
                            <div className="mt-2">
                                <input
                                    id="phone"
                                    name="phone"
                                    placeholder="Enter your phone number"
                                    required
                                    autoComplete="phone"
                                    onChange={handleChange}
                                    value={formData.phone || ''}
                                    className="block w-full rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                />
                            </div>
                        </div>

                        <PasswordInput
                            showPasswordStrength={true}
                            label="Password"
                            name="password"
                            placeholder="Enter your password"
                            value={formData.password || ''}
                            onChange={handleChange}
                            />

                        <PasswordInput
                            label="Confirm Password"
                            name={'confirm_password'}
                            placeholder="Confirm your password..."
                            onChange={handleChange}
                            value={formData.confirm_password || ''}>
                            
                            {
                                (formData.password !== formData.confirm_password) && (
                                    <small className="text-grey-secondary text-xs">Must be the same as new password</small>
                                )
                            }
                        </PasswordInput>

                        <div>
                            <label htmlFor="referral_code" className="block text-sm font-medium leading-6">
                                Referral Link
                            </label>
                            <div className="mt-2">
                                <input
                                    id="referral_code"
                                    name="referral_code"
                                    placeholder="Enter your referral link"
                                    onChange={handleChange}
                                    value={formData.referral_code || ''}
                                    className="block w-full rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                />
                            </div>
                        </div>

                        <div>
                            <button type="submit" disabled={loading} className="flex w-full items-center justify-center rounded-md bg-blue-primary px-3 py-2 text-sm font-semibold leading-6 shadow-sm hover:bg-purple-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray">
                                { loading ? (<Loader className="mr-2"/>) : '' }
                               Create Account
                            </button>
                        </div>
                    </form>

                    <p className="my-6 text-center text-sm">
                        Already signed up?{'  '}
                        <Link to="/sign-in" disabled={loading} className="font-semibold leading-6 text-light-blue hover:text-blue-primary">
                            Login
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
 
export default Register;