import Alert from "../helpers/alerts";

const NewletterService = () => {
    const NEWSLETTER_TYPE = 'newsletter';
    const CONTACT_US_TYPE = 'contact_us';

    const addSubscriber = async ({ data = {}, type }) => {
        if (![NEWSLETTER_TYPE, CONTACT_US_TYPE].includes(type)) {
            Alert('Invalid params', {}, 'error');
            return null;
        }

        return fetch(`${process.env.REACT_APP_SERVER_URL}/save-google-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ type, data })
        })
        .then(response => response.text())
        .then(data => {
            Alert(data);
        })
        .catch(error => {
            Alert('Error encountered', {}, 'error', error)
        });
    }
    
    return {
        addSubscriber, NEWSLETTER_TYPE, CONTACT_US_TYPE
    };
}

export default NewletterService;