import { useEffect, useRef } from "react";
import styled from "styled-components";
import { logoLineOne, logoLineTwo } from "../../data/tokensData";

const LogoWrapper = styled.div`
    transition: all ease;
    .logo-track-1 {
        animation: slideLeft 20s linear infinite;
    }
    .logo-track-2 {
        animation: slideRight 20s linear infinite;
    }
    @keyframes slideLeft {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-50%);
        }
    }
    @keyframes slideRight {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(50%);
        }
    }
`

const renderLogos = (logos = [], logoClassName = '', ref) => {
    let classes = `inline-flex justify-center items-center text-center mt-4 transition-all ${logoClassName}`;
    return (
        <LogoWrapper ref={ref} className={`whitespace-nowrap w-full overflow-hidden`}>
            <div className={classes}>
                {
                    logos?.concat(logos).concat(logos).concat(logos).map(({ Logo, link }, index) => (
                        <a href={link} rel="noreferrer" target="_blank" key={index} className="hover:scale-105 transition-all bg-grey-primary rounded-xl mx-3 lg:mx-6 p-3 flex items-center justify-center w-28 h-28 xl:w-36 xl:h-36">
                            <Logo className="block object-cover w-16 xl:w-20"/>
                        </a>
                    ))
                }
            </div>
        </LogoWrapper>
    )
}

const TokenSection = () => {
    const logoTrackOneRef = useRef(null)
    const logoTrackTwoRef = useRef(null)

    useEffect(() => {
        // used to fix bug of second line of logos not sliding to infinity to the right end.
        if (logoTrackTwoRef.current) {
            logoTrackTwoRef.current.scrollLeft = logoTrackTwoRef.current.scrollWidth;
        }
    }, [])

    return (
        <div className="relative bg-[#181a2a] text-white py-16 px-4 lg:px-16 flex flex-col justify-center items-center" id="buyTokens">
            <h2 className="my-2 text-lg lg:text-3xl text-center font-bold">Where to buy AAST?</h2>

            <p className="text-sm text-center my-4 sm:my-0 lg:w-1/3 py-4">
                AAST is available for trading on centralized and decentralized exchanges - find your preferred platform to buy and sell AAST!
            </p>

            {
                renderLogos(logoLineOne, 'logo-track-1', logoTrackOneRef)
            }

            {
                renderLogos(logoLineTwo, 'logo-track-2', logoTrackTwoRef)
            }

        </div>
    );
}
 
export default TokenSection;