import TopBar from "./TopBar";
import CopyIcon from "../../assets/icons/copyIcon";
import { useCallback, useContext, useMemo, useReducer, useRef, useState } from "react";
import TransactionsTable from "./TransactionsTable";
import TransactionStats from "./TransactionStats";
import { UserContext } from "../../contexts/UserContext";
import BuyTokenModal from "./BuyTokenModal";
import { formReducer } from "../../helpers/reducers";
import CustomSelect from "../shared/CustomSelect";
import { paymentOptions } from "../../data/dashboardData";

const Overview = () => {
    const [buyTokenForm, setBuyTokenForm] = useReducer(formReducer, {})
    const [transactions, setTransactions] = useState([])
    const textRef = useRef(null)
    const { user } = useContext(UserContext)

    const firstName = useMemo(() => user?.fullname ? user.fullname.split(' ')[0] : 'User', [user])

    const copyToClipboard = event => {
        event.stopPropagation()
        const text = textRef.current.innerText;
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }

    const handleTransactionsEmit = useCallback((txs) => {
        setTransactions(txs)
    }, [])

    return (
        <div className="text-white flex flex-col w-full">
            <TopBar title={`Welcome back, ${firstName}`} text={"Here is what's happening on your account today"}/>

            <TransactionStats transactions={transactions}/>

            <div className="p-3 lg:p-5 flex flex-col xl:flex-row-reverse mt-4 xl:mt-0 w-full">
                <div className="flex flex-col xl:w-1/3">
                    <div className="bg-grey-primary rounded-lg p-4 lg:p-6 xl:p-5 flex flex-col">
                        <h6 className="font-bold">Buy Token</h6>

                        <div className="mt-3">
                            <label htmlFor="amount" className="block text-sm font-medium leading-6">
                                Amount to Buy (USD)
                            </label>
                            <div className="mt-2">
                                <input
                                    id="amount"
                                    name="amount"
                                    placeholder="Enter amount"
                                    required
                                    type="number"
                                    onChange={event => setBuyTokenForm({ name: event.target.name, value: event.target.value })}
                                    value={buyTokenForm.amount || ''}
                                    className="block w-full h-12 rounded-md border border-grey-primary py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                />
                                <small className="text-xs text-gray">You get 0 AAST</small>
                            </div>
                        </div>

                        <div className="mt-3">
                            <label htmlFor="payment" className="block text-sm font-medium leading-6">
                                Payment Method
                            </label>
                            <div className="mt-2 relative showdow-sm">
                                <CustomSelect id="payment" name="payment" options={paymentOptions}
                                    placeholder={'Select Payment method'}
                                    reduce={option => option.value} label={'name'}
                                    onInput={event => setBuyTokenForm({
                                        name: "payment_method",
                                        value: event,
                                    })}
                                    value={buyTokenForm.payment_method || ''}
                                    selectWrapperClass={'bg-purple-primary'}
                                    optionsContainerClass={'bg-purple-primary'}
                                    selectedOptionClass={'h-12 w-full rounded-md p-2 px-3 shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary border-0'}/>
                            </div>
                        </div>

                        <div className="lg:mx-auto xl:mx-0">
                            <BuyTokenModal data={buyTokenForm} buttonClass="w-full lg:w-40 xl:w-full py-3 text-sm font-bold bg-blue-primary border border-blue-primary hover:bg-purple-primary hover:text-blue-primary rounded-md mt-8 flex items-center justify-center"/>
                        </div>
                    </div>

                    <div className="bg-grey-primary rounded-lg p-4 lg:p-6 xl:p-5 mt-6 flex flex-col">
                        <h6 className="font-bold text-center text-lg">Refer Friends</h6>

                        <div>
                            <img src="https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/referral_feaq6r.png" alt="refer" className="mx-auto block object-cover w-48"/>
                        </div>

                        <div>
                            <label htmlFor="link" className="block text-sm font-medium leading-6">Your Referral link</label>

                            <div className="relative w-full flex justify-between items-center border-grey-primary border rounded-md p-4 pr-6 mb-4 mt-2 bg-purple-primary text-white h-12 whitespace-nowrap">
                                <span className="w-full text-sm whitespace-normal" role="textbox" name="link" id="link" ref={textRef}>
                                    { user?.referral ?? '' }
                                </span>
                                <CopyIcon className="cursor-pointer" onClick={copyToClipboard}/>
                            </div>
                        </div>

                        <div className="lg:mx-auto xl:mx-0">
                            <button className="w-full lg:w-40 xl:w-full py-3 text-sm font-bold border border-light-blue hover:bg-light-blue text-light-blue hover:text-white rounded-md mt-3">
                                Share
                            </button>
                        </div>
                    </div>
                </div>

                <TransactionsTable className="xl:mt-0 xl:mr-4 xl:w-2/3" onTransactionsFetched={handleTransactionsEmit}/>
            </div>
        </div>
    );
}
 
export default Overview;