import { useParams } from "react-router-dom";
import BlogService from "../../services/BlogService";
import { useEffect, useRef, useState } from "react";
import { transformBlogEntries } from "../../helpers/utils";
import FooterLogos from "../shared/FooterLogos";
import parse from 'html-react-parser';
import styled from "styled-components";
import CopyIcon from "../../assets/icons/copyIcon";
import BlogGroup from "./BlogGroup";
import NewsletterSection from "../home/NewsletterSection";

const { getEntry, getAllEntries } = BlogService();
const Wrapper = styled.div`
    h1, h2, h3, h4, h5, h6 {
        margin: 0.5em 0;
        font-size: 1.2em;
    }

    ul {
        list-style: disc;
        padding: 1em;
        line-height: 1.5;
    }
    @media (min-width: 1024px) {
        h1, h2, h3, h4, h5, h6 {
            font-size: 1.5em;
        }
    }
`

const BlogDetails = () => {
    const { id } = useParams()
    const [entry, setEntry] = useState({})
    const [entries, setEntries] = useState([])
    let mounted = useRef(true)
    let dataFetched = useRef(false)

    useEffect(() => {
        mounted.current = true
        if (dataFetched.current) return;

        const getBlogData = async () => {
            try {
                await Promise.all([
                    getEntry(id),
                    getAllEntries({
                        limit: 5,
                        order: 'sys.createdAt',
                    })
                ])
                .then(results => {
                    const [blogEntry, { items: blogEntries }] = results;
                    if (mounted.current) {
                        if (blogEntry) {
                            setEntry(transformBlogEntries([blogEntry]).find(blog => blog.id === id) ?? {})
                        }
                        if (blogEntries) {
                            setEntries(transformBlogEntries([...blogEntries].filter(entry => entry.sys?.id !== id)))
                        }
                    }
                })
                .finally(() => dataFetched.current = false)
            } catch (error) {
                console.error(error)
            }
        }

        getBlogData()
        dataFetched.current = true;

        return () => {
            mounted.current = false;
            setEntry({})
            setEntries([])
        }
    }, [id])

    return (
        <div className="min-h-screen py-6 flex flex-col w-full text-white">
            <div className="flex flex-col px-3 sm:px-8 w-full bg-purple-primary relative">
                <small className="text-light-blue text-xs tracking-wide font-bold text-center">
                    Published { entry?.date }
                </small>

                <div className="mx-auto mt-3 w-4/5">
                    <h3 className="text-center text-2xl font-bold">{ entry?.title }</h3>
                </div>
                <div className="mx-auto w-full mt-3">
                    <FooterLogos className="justify-center"/>
                </div>

                <div className="w-full mt-4 xl:w-2/3 xl:mx-auto lg:mt-8">
                    <img src={entry?.image ?? ''} alt={entry?.title} className="w-full block object-cover rounded-lg"/>
                </div>

                <Wrapper className="mt-6 p-3 pb-10 border-b border-grey-primary text-sm lg:w-2/3 xl:w-3/5 lg:mx-auto leading-6" data-aos="fade-up" data-aos-duration="1500">
                    {
                        parse(entry?.content ?? '')
                    }
                </Wrapper>

                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center my-6 lg:w-2/3 lg:mx-auto">
                    <div className="flex items-center justify-start">
                        <img src={entry?.authorImage ?? ''} alt="author" className="w-10 h-10 rounded-full mr-3 block object-cover"/>

                        <h6 className="text-sm font-bold">{ entry?.author }</h6>
                    </div>

                    <div className="flex items-center justify-start mt-3">
                        <div className="mr-5 flex items-center text-xs">
                            <CopyIcon className="mr-2 w-6"/> <span className="font-bold">Copy Link</span>
                        </div>

                        <FooterLogos/>
                    </div>
                </div>
            </div>

            <div className="border-t border-grey-primary pt-10">
                <BlogGroup title="From the Blog" items={entries} description="The latest industry news, interviews, technologies, and resources."/>
            </div>

            <NewsletterSection/>
        </div>
    );
}
 
export default BlogDetails;