import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { returnUniqueClass } from '../../helpers/utils';

const CustomSelect = ({
    label,
    onChange,
    onInput,
    options = [],
    placeholder,
    reduce,
    renderOption,
    renderSelectedOption,
    value,
    selectedOptionClass,
    singleOptionClass,
    optionsContainerClass,
    selectWrapperClass,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const initialOption = options.find(option => (reduce ? reduce(option) : option) === value) || null;
        setSelectedOption(initialOption);
    }, [value, options, reduce]);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        const optionValue = reduce ? reduce(option) : option;

        // Emit events if they are provided
        if (onChange) onChange(optionValue);
        if (onInput) onInput(optionValue);
    };

    const renderSelectedDisplay = () => {
        if (selectedOption) {
            if (renderSelectedOption) {
                return renderSelectedOption(selectedOption);
            } 
            return (
                <div className={`flex items-center space-x-2 text-sm`}>
                    {
                        selectedOption?.icon && (
                            <span className="icon-wrapper">{ selectedOption.icon }</span>
                        )
                    }
                    <span>{ selectedOption?.[label] }</span>
                </div>
            );
        }
        return <span className="text-gray text-xs">{ placeholder }</span>;
    };

    const renderOptionDisplay = (option) => {
        if (renderOption) {
            return renderOption(option);
        }
        return (
            <div className={returnUniqueClass(`flex items-center text-sm ${singleOptionClass ?? ''}`)}>
                {
                    option.icon && (
                        <span className="icon-wrapper">{ option.icon }</span>
                    )
                }
                <span className="ml-2">{ option[label] }</span>
            </div>
        );
    };

    return (
        <div className={returnUniqueClass(`relative inline-block w-full min-w-fit ${selectWrapperClass ?? ''}`)} { ...rest }>
            <div className={returnUniqueClass(`flex items-center justify-between border border-gray cursor-pointer p-1 ${selectedOptionClass ?? ''}`)} onClick={toggleDropdown}>
                { renderSelectedDisplay() }
                <span className="text-gray ml-2">&#9662;</span>
            </div>
            {
                isOpen && (
                    <div className={returnUniqueClass(`absolute w-full min-w-fit mt-2 border border-gray rounded-lg z-10 ${optionsContainerClass ?? ''}`)}>
                        {
                            options?.map((option, index) => (
                                <div key={index} onClick={() => handleOptionClick(option)} className="p-2 border-b border-grey-primary cursor-pointer hover:bg-grey-primary rounded-lg">
                                    { renderOptionDisplay(option) }
                                </div>
                            ))
                        }
                    </div>
                )
            }
      </div>
    );
}

CustomSelect.propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.node,
        ]), // Icon can be a React component or HTML element
      })
    ).isRequired,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    renderSelectedOption: PropTypes.func, // Function to render selected option
    renderOption: PropTypes.func, // Function to render each option
    reduce: PropTypes.func, // Function to determine option value
    placeholder: PropTypes.string,
    selectedOptionClass: PropTypes.string,
    singleOptionClass: PropTypes.string,
    optionsContainerClass: PropTypes.string,
    selectWrapperClass: PropTypes.string,
  };
 
export default CustomSelect;