import ConfirmRegister from "../components/auth/ConfirmRegister";
import ForgotPassword from "../components/auth/ForgotPassword";
import Register from "../components/auth/Register";
import ResetPassword from "../components/auth/ResetPassword";
import SignIn from "../components/auth/SignIn";

const authRoutes = [
    {
        path: 'sign-in',
        element: <SignIn/>
    },
    {
        path: 'sign-up',
        element: <Register/>
    },
    {
        path: 'forgot-password',
        element: <ForgotPassword/>
    },
    {
        path: "reset-password",
        element: <ResetPassword/>
    },
    {
        path: "confirm-registration",
        element: <ConfirmRegister/>
    }
];

export default authRoutes;