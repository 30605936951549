import PropTypes from 'prop-types';
import BlogCard from './BlogCard';

const BlogGroup = ({ items, title, description }) => {
    return (
        <div className="flex flex-col w-full border-b border-grey-primary pb-10 mt-4" data-aos="fade-up" data-aos-duration="1500">
            <h6 className="my-3 text-center font-bold text-xl">{ title }</h6>

            {
                description && (
                    <p className='my-3 text-sm text-center'>{ description }</p>
                )
            }

            <div className="grid grid-cols-1 gap-x-10 gap-y-10 md:grid-cols-2">
                {
                    items?.map((item, index) => (
                        <BlogCard key={index} entry={item ?? {}} className="my-3 lg:my-0"/>
                    ))
                }
            </div>
        </div>
    );
}

BlogGroup.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string
}
 
export default BlogGroup;