export const faqData = [
    { title: 'What is AAS token (AAST)?', description: "AAST is the utility token of Afriq Group of Companies (AGC) and the native token of Afriq Exchange (AFRIQX), a subsidiary of AGC Ecosystem. It's the people's Cryptocurrency." },
    { title: "What is the mission of AGC Ecosystem?", description: "AGC is a community-powered ecosystem that aims to add value to people's lives through innovative technology solutions." },
    { title: "What is the vision of AGC Ecosystem?", description: [
        "Empowering Lives through Financial Inclusion, Technological Innovations, and Community Development.",
        "Addressing Hunger; Enhancing Financial Stability; Facilitating Digital Access, Tokenization of Real-World Assets, E-Commerce, E-Payment Services, and Digital Literacy; Reducing Unemployment, Providing Digital Asset Offerings and Exchange Services."
    ] },
    { title: 'Who are the target beneficiaries of AAS token?', description: 'Digital asset traders, Institutional Investors, High Net-Worth Investors, Low Net-Worth Investors, Less Privileged Individuals, Students, and Local Communities' },
    { title: 'On which chain is AAS token built?', description: 'BEP20 Network: Binance Smart Chain is fast and not expensive in terms of gas fee.' },
    { title: 'Where can AAST be purchased?', description: "AAST is currently listed and trading on six Centralized Exchanges and one Decentralized Exchange: MEXC, BITMART, ASCENDEX, LATOKEN, XT.COM, BICONOMY, and TRUST WALLET (PANCAKE SWAP)." },
    { title: "Where can the contract address of AAST be found?", description: "Go to: https://coinmarketcap.com/currencies/aastoken https://www.coingecko.com/en/coins/aastoken" }
]