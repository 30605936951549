import { useReducer, useState, useRef, Fragment, useEffect } from "react";
import { formReducer } from "../../helpers/reducers";
import { Link, useNavigate } from "react-router-dom";
import InputCode from "../shared/InputCode";
import { formatCountdown } from "../../helpers/utils";
import AuthenticationService from "../../services/AuthenticationService";
import Loader from "../shared/Loader";

const { userForgotPassword, resendForgotCode, verifyForgotCode } = AuthenticationService();

const ForgotPassword = () => {
    const [formData, setFormData] = useReducer(formReducer, {});
    const [confirmEmail, setConfirmEmail] = useState(true)
    const [inputCode, setInputCode] = useState(false)
    const [count, setCount] = useState(0)
    const [loading, toggleLoading] = useState(false)
    const navigate = useNavigate()
    let mounted = useRef(true)
    let counter = useRef(0)
    let countdownId = useRef(null)

    const handleChange = (event) => {
		const isChecked = event.target.type === 'checkbox';
		setFormData({
			name: event.target.name,
			value: isChecked ? event.target.checked : event.target.value,
		});
	};

    const countdown = () => {
        if (counter.current > 0) {
            countdownId.current = setTimeout(() => {
                counter.current -= 1
                setCount(counter.current)
                countdown()
            }, 1000);
        }
    }

    const triggerCountdownSequence = () => {
        counter.current = 60
        setCount(counter.current)
        countdown()
    }

    const redirectToCodeInput = event => {
        event.preventDefault()
        toggleLoading(true)

        userForgotPassword(formData.email)
            .then(response => {
                if (mounted.current && response) {
                    setConfirmEmail(false)
                    setInputCode(true)
                    triggerCountdownSequence()
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
    }

    const handleCodeResend = () => {
        toggleLoading(true)

        resendForgotCode(formData.email)
            .then(response => {
                if (mounted.current && response) {
                    triggerCountdownSequence()
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
    }

    const handleCodeInput = (code) => {
        toggleLoading(true)

        verifyForgotCode(formData.email, code)
            .then(response => {
                if (mounted.current && response) {
                    navigate('/reset-password', { state: { email: formData.email } });
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
    }

    useEffect(() => {
        mounted.current = true

        return () => {
            if (countdownId.current) {
                clearTimeout(countdownId.current)
            }
            setCount(0)
            setConfirmEmail(true)
            setInputCode(false)
            toggleLoading(false)
            counter.current = 0
            countdownId.current = null
            mounted.current = false;
        }
    }, [])

    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="bg-grey-primary rounded-xl p-4 text-white flex flex-col justify-center w-full sm:w-4/5 mx-auto lg:w-3/5 xl:w-1/2">
                {
                    confirmEmail && (
                        <Fragment>
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <h2 className="text-center text-xl font-bold leading-9 tracking-tight">
                                    Forgot your Password?
                                </h2>
                                <p className="text-sm text-center">We will send you an email with the verification code to your email address</p>
                            </div>

                            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                <form onSubmit={redirectToCodeInput} className="space-y-6">

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium leading-6">
                                            Email
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Enter your email"
                                                required
                                                autoComplete="email"
                                                onChange={handleChange}
                                                value={formData.email || ''}
                                                className="block w-full rounded-md border-0 py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button type="submit" disabled={loading} className="flex w-full justify-center items-center rounded-md bg-blue-primary px-3 py-2 text-sm font-semibold leading-6 shadow-sm hover:bg-purple-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray">
                                            { loading ? (<Loader className="mr-2"/>) : '' }
                                            Reset
                                        </button>
                                    </div>
                                </form>

                                <p className="my-6 text-center text-sm">
                                    Remembered your password?{'  '}
                                    <Link to="/sign-in" className="font-semibold leading-6 text-light-blue hover:text-blue-primary">
                                        Log In
                                    </Link>
                                </p>
                            </div>
                        </Fragment>
                    )
                }

                {
                    inputCode && (
                        <Fragment>
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <h2 className="text-center text-xl font-bold leading-9 tracking-tight">
                                    Forgot your Password?
                                </h2>
                                <p className="text-sm text-center">We have sent an email with the verification code to <span className="font-bold">{ formData?.email ?? 'your email' }</span></p>
                            </div>

                            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                <div className="space-y-6">
                                    <div className="my-4">
                                        <InputCode length={6} label="Verification Code" onComplete={handleCodeInput}
                                            loading={loading}/>
                                    </div>
                                </div>

                                {
                                    loading && (
                                        <div className="flex items-center justify-center text-light-blue">
                                            <Loader/>
                                        </div>
                                    )
                                }

                                <p className="my-6 text-center text-sm">
                                    Didn't get the code?{'  '}
                                    <button onClick={handleCodeResend} disabled={(count !== 0) || loading} className="font-bold leading-6 text-white hover:text-light-blue border-none bg-transparent">
                                        Try Again {
                                            (count !== 0) && (`in 00:${ formatCountdown(count) }`)
                                        }
                                    </button>
                                </p>
                            </div>
                        </Fragment>
                    )
                }
            </div>
        </div>
    );
}
 
export default ForgotPassword;