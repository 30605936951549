import { Fragment, useContext, useEffect, useRef, useState } from "react";
import Logo from "../../assets/icons/logo";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { sidebarMenu } from "../../data/dashboardData";
import MenuOpenIcon from "../../assets/icons/menuOpenIcon";
import MenuCloseIcon from "../../assets/icons/menuCloseIcon";
import { LogoutIcon } from "../../assets/icons/dashboardIcons";
import UserMenu from "../shared/UserMenu";
import NotificationsDropdown from "../shared/NotificationsDropdown";
import { UserContext } from "../../contexts/UserContext";
import AuthenticationService from "../../services/AuthenticationService";

const { logoutUser } = AuthenticationService()

const Sidebar = () => {
    const [showSidebar, toggleShowSidebar] = useState(false);
    const { setUser } = useContext(UserContext)
    const navigate = useNavigate()
    const sidebarRef = useRef(null);

    const handleSidebarShow = event => {
        event.preventDefault()
        toggleShowSidebar(true)
    }

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            toggleShowSidebar(false);
        }
    };

    const handleLogout = () => {
        logoutUser()
            .then(response => {
                if (response) {
                    setUser({})
                    navigate('/')
                }
            })
    }

    useEffect(() => {
        if (showSidebar) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSidebar]);

    return (
        <Fragment>
            <div className={`${showSidebar ? 'hidden' : 'flex sm:hidden'} items-center justify-between p-2 pb-3 border-b border-grey-primary`}>
                <div className={`flex lg:flex-1`}>
                    <Link to="/" className={`my-2 p-2`} >
                        <span className="sr-only">AAST</span>
                        <Logo className="h-8 w-auto" key={1}/>
                    </Link>
                </div>

                <div className="flex items-center">
                    <NotificationsDropdown className="mr-2"/>

                    <UserMenu/>

                    <button onClick={handleSidebarShow} data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className={`items-center ml-2 p-2 text-sm rounded-lg hover:bg-grey-primary focus:outline-none focus:ring-2 focus:ring-grey-primary inline-flex`}>
                        <span className="sr-only">Open sidebar</span>
                        <MenuOpenIcon />
                    </button>
                </div>
            </div>

            <aside ref={sidebarRef} id="aast-sidebar" className={`fixed top-0 left-0 z-[100] w-full sm:w-64 h-screen transition-transform ${showSidebar ? 'translate-x-0' : '-translate-x-full sm:translate-x-0'} border-r border-grey-primary bg-purple-primary`} aria-label="Sidebar">
                <div className="h-full py-4 overflow-y-auto flex flex-col">
                    
                    <div className="flex items-center justify-between px-3 sm:px-5 border-b pb-3 border-grey-primary sm:border-b-0 sm:pb-0">
                        <Link to="/" className="my-2 p-1.5">
                            <span className="sr-only">AAST</span>
                            <Logo className="h-8 w-auto" key={2}/>
                        </Link>

                        <span role="button" onClick={() => toggleShowSidebar(false)} className="sm:hidden">
                            <span className="sr-only">Close sidebar</span>
                            <MenuCloseIcon/>
                        </span>

                    </div>
                    <ul className="space-y-2 mt-4 lg:mt-8 px-5 flex flex-col justify-between grow">
                        <div>
                            {
                                sidebarMenu?.map(({ Logo, name, link }, index) => (
                                    <li key={index} className="my-2">
                                        <NavLink end to={`${link}`} className={({ isActive }) => `flex items-center p-3 text-xs hover:text-light-blue rounded-xl hover:bg-grey-primary group ${isActive ? 'sm:bg-grey-primary text-light-blue' : 'text-gray'}`} onClick={() => toggleShowSidebar(false)}>
                                            <Logo className="transition duration-75 group-hover:text-light-blue"/>
                                            <span className="ms-3">{ name }</span>
                                        </NavLink>
                                    </li>
                                ))
                            }
                        </div>

                        <div>
                            <li className="my-2">
                                <button type="button" onClick={handleLogout} className={`w-full flex items-center p-3 text-xs hover:text-light-blue rounded-xl hover:bg-grey-primary group text-gray`}>
                                    <LogoutIcon className="transition duration-75 group-hover:text-light-blue"/>
                                    <span className="ms-3">Logout</span>
                                </button>
                            </li>
                        </div>
                    </ul>
                </div>
            </aside>
        </Fragment>
    );
}
 
export default Sidebar;