import { Outlet } from "react-router-dom";
import Sidebar from "../components/dashboard/Sidebar";
const Dashboard = () => {
    

    return (
        <div className="min-h-screen text-white w-full relative">
            <Sidebar/>

            <div className="z-1 relative sm:ml-64 max-w-screen">
                <Outlet/>
            </div>
        </div>
    );
}
 
export default Dashboard;