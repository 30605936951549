import { footerLogos } from "../../data/footerData";

const FooterLogos = ({ className = '', ...rest }) => {
    return (
        <div className={`mt-2 flex items-center ${className}`} { ...rest }>
            {
                footerLogos?.map(({ Logo, link }, index) => (
                    <a key={index} className="flex items-center justify-center rounded-full p-3 bg-grey-primary mr-2 w-10 h-10" href={link} target="_blank" rel="noreferrer">
                        <Logo/>
                    </a>
                ))
            }
        </div>
    );
}
 
export default FooterLogos;