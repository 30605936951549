import { Link, useNavigate } from "react-router-dom";
import CaretDown from "../../assets/icons/caretDown";
import UserIcon from "../../assets/icons/userIcon";
import Dropdown from "./Dropdown";
import { userMenu } from "../../data/dashboardData";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import AuthenticationService from "../../services/AuthenticationService";

const { logoutUser } = AuthenticationService()
const UserMenu = () => {
    const { user, setUser } = useContext(UserContext)
    const navigate = useNavigate()

    const handleLogout = () => {
        logoutUser()
            .then(response => {
                if (response) {
                    setUser({})
                    navigate('/')
                }
            })
    }

    return (
        <Dropdown actionElement={(
            <span role="button" className="p-1 sm:px-3 w-10 sm:w-fit h-10 rounded-full bg-grey-primary flex items-center justify-center">
                <UserIcon/>
                <span className="hidden items-center ml-2 text-sm sm:flex">
                    { user.fullname }
                    <CaretDown/>
                </span>
            </span>
        )} headerElement={(
            <div className="px-4 py-3 text-sm text-white bg-grey-primary p-2">
                <div className="font-bold">{ user.fullname }</div>
                <div className="font-light truncate mt-1">{ user.email }</div>
            </div>
        )}>
            {
                userMenu?.map((menu, index) => (
                    <Link role="menuitem" to={menu.link} key={index} className="block px-4 py-2 my-1 text-xs hover:bg-grey-primary">
                        { menu.title }
                    </Link>
                ))
            }
            <div className="border-t border-grey-primary"></div>
            <span role="button" className="block px-4 py-2 my-1 text-xs hover:bg-grey-primary" onClick={handleLogout}>
                Logout
            </span>
        </Dropdown>
    );
}
 
export default UserMenu;