import { useCallback, useState } from "react";
// import ExportIcon from "../../assets/icons/exportIcon";
import TopBar from "./TopBar";
import TransactionsTable from "./TransactionsTable";
import TransactionStats from "./TransactionStats";

const Transactions = () => {
    const [transactions, setTransactions] = useState([])

    const handleTransactionsEmit = useCallback((txs) => {
        setTransactions(txs)
    }, [])

    return (
        <div className="text-white flex flex-col w-full" style={{ minHeight: '100vh' }}>
            <TopBar title={'Transactions'}/>

            <TransactionStats transactions={transactions}/>

            <div className="w-full flex justify-between items-center mt-6 pb-4 px-4 border-b border-grey-primary">
                <h6 className="text-sm font-bold">
                    Transaction History
                </h6>

                {/* <span className="flex items-center" role="button">
                    <span className="text-xs font-bold">Export</span>
                    <ExportIcon className="ml-1"/>
                </span> */}
            </div>

            <div className="px-5 w-full flex flex-grow">
                <TransactionsTable onTransactionsFetched={handleTransactionsEmit} />
            </div>
        </div>
    );
}
 
export default Transactions;