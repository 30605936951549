import React, { Fragment, useEffect, useRef, useState } from "react";
import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import PropTypes from "prop-types";
import Loader from "./Loader";
import DashboardService from "../../services/DashboardService";

const { addWalletAddress } = DashboardService();

const AddWallet = ({ actionElement, onWalletAdded = () => {} }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [address, setAddress] = useState('')
    const [loading, toggleLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    let mounted = useRef(true)

    const handleAddressSubmit = () => {
        toggleLoading(true)

        addWalletAddress(address)
            .then(response => {
                if (mounted.current && response) {
                    setShowSuccess(true)
                    if (!!address) {
                        onWalletAdded(address);
                    }
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
        
    }

    const handleAddressInput = event => {
        setAddress(event.target.value)
    }

    const handleModalClose = () => {
        if (loading) return;

        setIsOpen(false)
        setShowSuccess(false)
        setAddress('')
    }

    useEffect(() => {
        mounted.current = true
        
        return () => {
            setShowSuccess(false)
            toggleLoading(false)
            setAddress('')
            setIsOpen(false)
            mounted.current = false
        }
    }, [])

    return (
        <Fragment>
            {
                actionElement ? (
                    React.cloneElement(actionElement)
                ) : (
                    <button className="text-xs font-bold rounded-lg py-3 px-3 w-fit border bg-light-blue mt-8 border-light-blue hover:text-light-blue hover:bg-transparent" onClick={() => setIsOpen(true)}>
                        Add Wallet Address
                    </button>
                )
            }
            <Dialog open={isOpen} onClose={handleModalClose} className="fixed z-[120] inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0" transition>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 text-white">
                    <DialogPanel transition className="w-full max-w-lg space-y-4 rounded-lg bg-grey-primary p-8 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0">
                        {
                            showSuccess ? (
                                <Fragment>
                                    <div className="mx-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-[green] animate__heartBeat animate__animated mx-auto">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </div>
                                    <DialogTitle className="font-bold text-center text-lg">Your wallet has been added successfully!</DialogTitle>
                                    <Description className={`text-center text-sm mt-3 leading-5`}>Head over to your dashboard to view your wallet token and details.</Description>

                                    <div className="">
                                        <button onClick={handleModalClose} className="bg-blue-primary block w-40 mx-auto py-3 text-xs rounded-md font-bold mt-4">Done</button>
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <DialogTitle className="font-bold text-center text-xl">Add Wallet</DialogTitle>
                                    <Description className={`text-center text-xs mt-3 leading-5`}>In order to ensure the safe distribution of the tokens you recently bought, kindly provide your immutable decentralized wallet address (e.g Trust Wallet, MetaMask).</Description>
            
                                    <div className="mt-3">
                                        <label htmlFor="wallet_address" className="block text-sm font-medium leading-6">
                                            Wallet Address
                                        </label>
                                        <div className="mt-2 relative">
                                            
                                            <input
                                                id="wallet_address"
                                                name="wallet_address"
                                                placeholder="Enter Wallet address..."
                                                required
                                                onInput={handleAddressInput}
                                                value={address || ''}
                                                className="block w-full h-12 rounded-md border border-grey-primary py-2 pr-8 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                            />
                                        </div>
                                    </div>
            
                                    <div className="flex gap-4">
                                        <button onClick={handleAddressSubmit} disabled={loading} className="bg-blue-primary w-full py-3 text-xs rounded-md font-bold mt-4 flex items-center justify-center">
                                            { loading ? (<Loader className="mr-2"/>) : '' }
                                            Update
                                        </button>
                                    </div>
                                </Fragment>
                            )
                        }
                    </DialogPanel>
                </div>
            </Dialog>
        </Fragment>
    );
}

AddWallet.propTypes = {
    actionElement: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
    ]),
    onWalletAdded: PropTypes.func.isRequired
}
 
export default AddWallet;