import { Description, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { Fragment, useReducer, useState } from "react";
import { formReducer } from "../../helpers/reducers";
import Loader from "../shared/Loader";
import AuthenticationService from "../../services/AuthenticationService";
import PasswordInput from "../shared/PasswordInput";

const { changePassword } = AuthenticationService()

const ChangePasswordModal = () => {
    const [showDialog, toggleShowDialog] = useState(false)
    const [loading, toggleLoading] = useState(false)
    const [formData, setFormData] = useReducer(formReducer, {});

    const handleModalClose = () => {
        if (loading) return;

        setFormData()
        toggleShowDialog(false)
    }

    const handleChange = (event) => {
		const isChecked = event.target.type === 'checkbox';
		setFormData({
			name: event.target.name,
			value: isChecked ? event.target.checked : event.target.value,
		});
	};

    const handleSubmit = () => {
        toggleLoading(true)

        changePassword(formData)
            .then(() => {
                toggleLoading(false) 
                handleModalClose()
            })
    }

    return (
        <Fragment>
            <button className="font-bold text-sm text-light-blue" onClick={() => toggleShowDialog(true)} type="button">Update</button>

            <Dialog open={showDialog} onClose={handleModalClose} className="fixed z-[120] inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0" transition>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 text-white">
                    <DialogPanel transition className="max-w-lg space-y-4 rounded-lg bg-grey-primary p-4 py-8 md:p-8 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 w-full">
                        <DialogTitle className="font-bold text-center text-xl">Change your Password</DialogTitle>
                        <Description className={`text-center text-xs mt-3 leading-5`}>Make changes to your current password</Description>

                        <div>
                            <PasswordInput
                                className="mt-3"
                                onChange={handleChange}
                                label="Current Password"
                                placeholder="Enter your current password..."
                                name="current_password"
                                value={formData.current_password || ''}/>

                            <PasswordInput
                                className="mt-3"
                                onChange={handleChange}
                                label="New Password"
                                placeholder="Enter new password..."
                                name="new_password"
                                value={formData.new_password || ''}
                                showPasswordStrength={true}/>

                            <PasswordInput
                                className="mt-3"
                                onChange={handleChange}
                                label="Confirm new Password"
                                placeholder="Confirm your new password..."
                                name="confirm_new_password"
                                value={formData.confirm_new_password || ''}>

                                { (formData.new_password && (formData.new_password !== formData.confirm_new_password)) && (<small className="text-grey-secondary text-xs">Must be the same as new password</small>) }
                            </PasswordInput>

                        </div>

                        <div className="flex gap-4">
                            <button disabled={loading} onClick={handleSubmit} className="border border-blue-primary flex items-center justify-center hover:bg-transparent hover:text-blue-primary bg-blue-primary w-full py-3 text-xs rounded-md font-bold mt-4">
                                { loading ? (<Loader className="mr-2"/>) : '' }
                                Update
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </Fragment>
    );
}
 
export default ChangePasswordModal;