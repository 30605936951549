import { useEffect, useMemo, useRef, useState } from "react";
import InputCode from "../shared/InputCode";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatCountdown } from "../../helpers/utils";
import AuthenticationService from "../../services/AuthenticationService";
import Loader from "../shared/Loader";

const { resendVerificationCode, verifyOtpCode } = AuthenticationService()

const ConfirmRegister = () => {
    const [loading, toggleLoading] = useState(false)
    const [count, setCount] = useState(0)
    const [searchParams,] = useSearchParams()
    const navigate = useNavigate()
    let counter = useRef(0)
    let mounted = useRef(true)
    let countdownId = useRef(null)

    const email = useMemo(() => {
        return searchParams.get('email')
    }, [searchParams])

    const handleCodeInput = (code) => {
        toggleLoading(true)

        verifyOtpCode(email, code)
            .then(response => {
                if (mounted.current && response) {
                    navigate('/sign-in')
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            }) 
    }

    const handleOtpResend = () => {
        toggleLoading(true)

        resendVerificationCode(email)
            .then(response => {
                if (mounted.current && response) {
                    counter.current = 60;
                    setCount(counter.current)
                    countdown()
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
    }

    const countdown = () => {
        if (counter.current > 0) {
            countdownId.current = setTimeout(() => {
                counter.current -= 1
                setCount(counter.current)
                countdown()
            }, 1000);
        }
    }

    useEffect(() => {
        mounted.current = true;

        return () => {
            if (countdownId.current) {
                clearTimeout(countdownId.current)
            }
            setCount(0)
            toggleLoading(false)
            counter.current = 0
            countdownId.current = null;
            mounted.current = false;
        }
    }, [])

    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="bg-grey-primary rounded-xl p-4 text-white flex flex-col justify-center w-full sm:w-4/5 mx-auto lg:w-3/5 xl:w-1/2">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="text-center text-xl font-bold leading-9 tracking-tight">
                        Confirm your Email Address
                    </h2>
                    <p className="text-sm text-center">Enter OTP Code sent to your email address in order to complete your registration</p>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="space-y-6">
                        <div className="my-4">
                            <InputCode length={6} label="OTP Code" onComplete={handleCodeInput} loading={loading}/>
                        </div>
                    </div>

                    {
                        loading && (
                            <div className="flex items-center justify-center text-light-blue">
                                <Loader/>
                            </div>
                        )
                    }

                    <p className="my-6 text-center text-sm">
                        Didn't get the code?{'  '}
                        <button onClick={handleOtpResend} disabled={count !== 0} className="font-bold leading-6 text-white hover:text-light-blue border-none bg-transparent">
                            Send Again {
                                (count !== 0) && (`in 00:${ formatCountdown(count) }`)
                            }
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}
 
export default ConfirmRegister;