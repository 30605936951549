import { contactLogos } from "../../data/contactData";

const ContactSection = () => {
    return (
        <div data-aos="fade-up" data-aos-duration="1500" className="bg-purple-primary text-white py-16 xl:py-24 flex flex-col lg:flex-row-reverse lg:justify-evenly items-center border-b border-gray" id="community">
            <div className="text-center font-bold">
                <small className="block text-xs lg:text-lg">Community</small>
                <h3 className="my-2 text-lg lg:text-3xl">Join the AAST Family</h3>
            </div>

            <div className="grid grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-4 mt-4 transition-all">
                {
                    contactLogos?.map(({ Logo, link }, index) => (
                        <a href={link} target="_blank" rel="noreferrer" key={index} className="hover:scale-105 transition-all bg-grey-primary rounded-xl p-3 flex items-center justify-center w-28 h-28 xl:w-36 xl:h-36">
                            <Logo className="block object-cover w-16 xl:w-20"/>
                        </a>
                    ))
                }
            </div>
        </div>
    );
}
 
export default ContactSection;