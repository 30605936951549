import { Description, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import React, { Fragment, useContext, useMemo, useState } from "react";
import Loader from "../shared/Loader";
import { UserContext } from "../../contexts/UserContext";
// import Alert from "../../helpers/alerts";
import { Link } from "react-router-dom";
import DashboardService from "../../services/DashboardService";

const { buyToken } = DashboardService()

const BuyTokenModal = ({ actionElement, buttonClass = '', data = {} }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [password, setPassword] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [loading, toggleLoading] = useState(false)
    const formData = useMemo(() => data ?? {}, [data])
    const { user } = useContext(UserContext)

    const handlePasswordInput = event => {
        setPassword(event.target.value)
    }

    const handleModalOpen = () => {
        // if (!formData?.amount && !formData.payment_method) {
        //     Alert('Kindly input a valid amount and select a valid payment method to proceed');
        //     return;
        // }
        setIsOpen(true)
    }

    const handleModalClose = () => {
        if (loading) return;

        setPassword('')
        toggleLoading(false)
        setShowSuccess(false)
        setIsOpen(false)
    }

    const handleBuyToken = () => {
        toggleLoading(true)
        console.log(formData, password, user.email)
        const requestData = {
            email: user.email,
            password,
            payment_method: formData.payment_method,
            amount: formData.amount
        }

        buyToken(requestData)
            .then(response => {
                if (response) {
                    setShowSuccess(true)
                }
            })
            .finally(() => {
                toggleLoading(false)
            })
    }

    return (
        <Fragment>
            {
                actionElement ? (
                    React.cloneElement(actionElement)
                ) : (
                    <button className={buttonClass} onClick={handleModalOpen}>
                        { loading ? (<Loader className='mr-2'/>) : '' }
                        Buy Token
                    </button>
                )
            }
            <Dialog open={isOpen} onClose={handleModalClose} className="fixed z-[120] inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0" transition>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 text-white">
                    <DialogPanel transition className="max-w-lg space-y-4 rounded-lg bg-grey-primary p-8 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 w-full">
                        {
                            true ? (
                                <Fragment>
                                    <div className="mx-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-light-blue animate__heartBeat animate__animated mx-auto">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                        </svg>
                                    </div>

                                    <DialogTitle className="font-bold text-center text-lg">Feature Unavailable!</DialogTitle>
                                    <Description className={`text-center text-sm mt-3 leading-5`}>Sorry, pre-sale has ended. Kindly visit our homepage for updates on where to buy AAST</Description>

                                    <div className="">
                                        <Link to={'/'} onClick={() => setIsOpen(false)} className="flex items-center justify-center bg-blue-primary w-40 mx-auto py-3 text-xs rounded-md font-bold mt-4">Go to Homepage</Link>
                                    </div>
                                </Fragment>
                            ) : showSuccess ? (
                                <Fragment>
                                    <div className="mx-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 text-[green] animate__heartBeat animate__animated mx-auto">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </div>
                                    <DialogTitle className="font-bold text-center text-lg">Congrats! Your purchase of 10 AAST was successful.</DialogTitle>
                                    <Description className={`text-center text-sm mt-3 leading-5`}>Head over to your dashboard to view your balance and details.</Description>

                                    <div className="">
                                        <button onClick={handleModalClose} className="bg-blue-primary block w-40 mx-auto py-3 text-xs rounded-md font-bold mt-4">Done</button>
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <DialogTitle className="font-bold text-center text-xl">Enter Password</DialogTitle>
                                    <Description className={`text-center text-xs mt-3 leading-5`}>Enter your password to authourize this action.</Description>

                                    <div className="mt-3">
                                        <label htmlFor="password" className="block text-sm font-medium leading-6">
                                            Password
                                        </label>
                                        <div className="mt-2 relative">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                placeholder="Enter your password"
                                                required
                                                onInput={handlePasswordInput}
                                                value={password || ''}
                                                className="block w-full h-12 rounded-md border border-grey-primary py-2 pr-8 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex gap-4">
                                        <button disabled={loading} onClick={handleBuyToken} className="bg-blue-primary w-full py-3 text-xs rounded-md font-bold mt-4 border border-blue-primary hover:text-blue-primary hover:bg-transparent flex items-center justify-center">
                                            { loading ? (<Loader className='mr-2'/>) : '' }
                                            Buy
                                        </button>
                                    </div>
                                </Fragment>
                            )
                        }
                    </DialogPanel>
                </div>
            </Dialog>
        </Fragment>
    );
}
 
export default BuyTokenModal;