import { Fragment, useEffect, useReducer, useRef, useState } from "react";
import { formReducer } from "../../helpers/reducers";
import KeyLogo from "../../assets/icons/keyLogo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../shared/Loader";
import Alert from "../../helpers/alerts";
import PasswordInput from "../shared/PasswordInput";
import AuthenticationService from "../../services/AuthenticationService";

const { resetUserPassword } = AuthenticationService();

const ResetPassword = () => {
    const [formData, setFormData] = useReducer(formReducer, {});
    const [resetPassword, setResetPassword] = useState(true)
    const [resetSuccess, setResetSuccess] = useState(false)
    const [loading, toggleLoading] = useState(false)
    const { state } = useLocation()
    const navigate = useNavigate()
    let mounted = useRef(true)

    const handleChange = (event) => {
		const isChecked = event.target.type === 'checkbox';
		setFormData({
			name: event.target.name,
			value: isChecked ? event.target.checked : event.target.value,
		});
	};
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        toggleLoading(true)
        
        resetUserPassword(state.email, formData.password, formData.confirm_password)
            .then(response => {
                if (mounted.current && response) {
                    setResetPassword(false)
                    setResetSuccess(true)
                }
            })
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
    }

    useEffect(() => {
        mounted.current = true

        if ((!state || (state && !state?.email)) && mounted.current) {
            Alert('Error occured. Try Process again.')
            navigate('/forgot-password');
        }

        return () => {
            setFormData()
            setResetPassword(true)
            toggleLoading(false)
            setResetSuccess(false)
            mounted.current = false
        }
    }, [state, navigate])
    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="bg-grey-primary rounded-xl p-4 text-white flex flex-col justify-center w-full sm:w-4/5 mx-auto lg:w-3/5 xl:w-1/2">
                {
                    resetPassword && (
                        <Fragment>
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <h2 className="text-center text-xl font-bold leading-9 tracking-tight">
                                    Enter new Password
                                </h2>
                                <p className="text-sm text-center">Set up your new password below.</p>
                            </div>

                            <div className="my-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                <form onSubmit={handleSubmit} className="space-y-6">

                                    <PasswordInput
                                        label="Password"
                                        name="password"
                                        placeholder="Enter your new password"
                                        onChange={handleChange}
                                        value={formData.password || ''}
                                        showPasswordStrength={true}
                                        showStrengthGuide={true}/>

                                    <PasswordInput
                                        label="Confirm Password"
                                        name="confirm_password"
                                        onChange={handleChange}
                                        className="mt-3"
                                        placeholder="Confirm your new password..."
                                        value={formData.confirm_password || ''}>

                                        { (formData.new_password && (formData.new_password !== formData.confirm_new_password)) && (<small className="text-grey-secondary text-xs">Must be the same as new password</small>) }
                                    </PasswordInput>

                                    <div>
                                        <button type="submit" disabled={loading} className="flex w-full justify-center items-center rounded-md bg-blue-primary px-3 py-2 text-sm font-semibold leading-6 shadow-sm hover:bg-purple-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray">
                                            { loading ? (<Loader className="mr-2"/>) : '' }
                                            Reset
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Fragment>
                    )
                }

                {
                    resetSuccess && (
                        <Fragment>
                            <KeyLogo className="my-4 mx-auto"/>
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <h2 className="text-center text-lg font-bold leading-9 tracking-tight">
                                    Password Reset Successful
                                </h2>
                                <p className="text-xs text-gray text-center">Your password has been reset successfully.<br/> Proceed to login.</p>
                            </div>

                            <div className="flex items-center justify-center">
                                <Link to="/sign-in" className="my-6 flex w-40 justify-center rounded-md bg-blue-primary px-3 py-2 text-sm font-semibold leading-6 shadow-sm hover:bg-purple-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray">
                                    Login
                                </Link>
                            </div>
                        </Fragment>
                    )
                }
            </div>
        </div>
    );
}
 
export default ResetPassword;