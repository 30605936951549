import { XtCloud, BitmartCloud, LatokenCloud, TrustCloud, AscendCloud, MetamaskCloud, MexcCloud } from "../../assets/icons/logoCloud";
import styled from "styled-components";

const LogoWrapper = styled.div`
    .logo-track {
        animation: slide 20s linear infinite;
    }
    @keyframes slide {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-50%);
        }
    }
`

const logos = [XtCloud, BitmartCloud, LatokenCloud, TrustCloud, AscendCloud, MetamaskCloud, MexcCloud];

const LogoCloud = () => {
    return (
        <LogoWrapper className="overflow-hidden whitespace-nowrap w-full h-24 border-y-1 border-grey-primary border bg-purple-primary flex items-center">
            <div className="logo-track inline-flex justify-center items-center">
                {
                    logos?.concat(logos).map((Logo, index) => (
                        <Logo key={index} className="inline-block mx-6"/>
                    ))
                }
            </div>
        </LogoWrapper>
    );
}
 
export default LogoCloud;