import BlogCard from "../blog/BlogCard";
import BlogService from "../../services/BlogService";
import { useState, useEffect, useRef, Fragment } from "react";
import SlideSection from "../shared/SlideSection";
import { transformBlogEntries } from "../../helpers/utils";
import { Dialog, DialogPanel, CloseButton } from "@headlessui/react";

const { getAllEntries, getEntry } = BlogService();

const convertItems = (entries = []) => {
    return entries.map((entry, index) => (
        <BlogCard entry={entry} key={index}/>
    ))
}

const BlogSection = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [popupInfo, setPopInfo] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const popupId = useRef(process.env?.REACT_APP_POPUP_ID ?? null)
    let mounted = useRef(true)
    let dataFetched = useRef(false)

    const handleModalClose = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        mounted.current = true
        if (dataFetched.current) return;

        const getEntries = async () => {
            let requests = [getAllEntries({
                limit: 5,
                order: 'sys.createdAt',
                include: 2
            })]

            if (popupId.current) {
                requests.push(getEntry(popupId.current))
            }

            try {
                await Promise.all(requests)
                    .then(results => {
                        const [{ items: blogEntries }, blogEntry] = results;
                        if (mounted.current) {
                            if (blogEntries) {
                                setBlogPosts(transformBlogEntries([...blogEntries]).filter(blog => blog.id !== popupId.current))
                            }
                            if (blogEntry) {
                                setPopInfo(blogEntry?.fields ?? {})
                                setIsOpen(blogEntry?.fields?.popupCondition ?? false)
                            }
                        }
                    })
                    .finally(() => dataFetched.current = false)
            } catch (error) {
                console.error(error)
            }
        }

        getEntries()
        dataFetched.current = true;

        return () => {
            setBlogPosts([])
            setIsOpen(false)
            setPopInfo({})
            mounted.current = false;
        }
    }, []);

    return (
        <Fragment>
            <SlideSection title={'AAST Latest News'} items={convertItems(blogPosts)}/>

            <Dialog open={isOpen} onClose={handleModalClose} className="fixed z-[120] inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0" transition>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 text-white">
                    <DialogPanel transition className="lg:w-4/5 w-full space-y-4 rounded-lg bg-grey-primary p-3 lg:p-8 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 h-fit">
                        <div className="flex justify-end items-center">
                            <CloseButton>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </CloseButton>
                        </div>
                        <div className="mx-auto">
                            <a href={popupInfo?.popupLink ?? ''} target="_blank" rel="noreferrer" className="no-underline">
                                {/* Desktop */}
                                <img src={popupInfo?.popupImageDesktop?.fields?.file?.url ?? ''} alt={popupInfo?.popupImageDesktop?.fields?.file?.fileName ?? ''} className="hidden lg:block object-cover w-full rounded-lg"/>
                                {/* Mobile */}
                                <img src={popupInfo?.popupImageDesktop?.fields?.file?.url ?? ''} alt={popupInfo?.popupImageMobile?.fields?.file?.fileName ?? ''} className="block lg:hidden object-cover w-full rounded-lg"/>
                            </a>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </Fragment>
    );
}
 
export default BlogSection;