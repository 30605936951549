// import { Link } from "react-router-dom";
import styled from "styled-components";
import { faqData } from "../../data/faqData";

const FaqWrapper = styled.div`
    #collapsible {
        overflow: hidden;
        transition: all 0.5s ease-in;
        -webkit-transition: height 0.5s;
    }
    .hide {
        display: none;
    }
    .show {
        display: block;
    }
`

const FaqSection = () => {

    const toggleCollapsible = (index) => {
        const collapsible = document.getElementById(`collapsible-${index}`)
        if (collapsible) {
            if (collapsible.classList.contains('hide')) {
                collapsible.classList.remove('hide');
                collapsible.classList.add('show');
            } else {
                collapsible.classList.remove('show');
                collapsible.classList.add('hide');
            }
        }
    }

    return (
        <div data-aos="fade-up" data-aos-duration="1500" className="relative bg-purple-primary text-white py-16 px-4 lg:px-16 flex flex-col lg:flex-row justify-center" id="faqs">
            <div>
                <h2 className="text-lg lg:text-3xl text-center lg:text-left font-bold">Frequently Asked Questions</h2>

                <p className="text-sm text-center lg:text-left sm:my-0 py-1">
                    Do you still have any questions? Contact our Team via <a href="mailto:cs@afriqjmcoin.zendesk.com" className="underline">cs@afriqjmcoin.zendesk.com</a>
                </p>

                {/* <div className="my-2 flex justify-center lg:justify-start items-center">
                    <Link className="w-40 hover:bg-purple-primary rounded-lg bg-blue-primary px-3.5 py-2.5 text-sm font-bold text-white shadow-sm border border-blue-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white flex items-center justify-center" to="/faqs">
                        See All FAQs
                    </Link>
                </div> */}
            </div>

            <FaqWrapper className="lg:mt-0 mt-6 w-full sm:px-4">
                {
                    faqData?.map((datum, index) => (
                        <div key={index} className={`mt-4 border border-grey-primary rounded-lg p-4 w-full flex flex-col ${index === 0 ? 'lg:mt-0' : ''}`}>
                            <div className="w-full flex justify-between items-center py-4">
                                <span className="text-md font-bold w-5/6 sm:w-full">{ datum.title }</span>
                                <button type="button" className="bg-grey-primary rounded-lg border-none p-2" onClick={() => toggleCollapsible(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </button>
                            </div>

                            <div id={`collapsible-${index}`} className="animate__flipInX animate__animated py-4 hide border-t border-grey-primary">
                                {
                                    (datum.description && Array.isArray(datum.description)) ? (
                                        <ul className="px-3">
                                            {
                                                datum.description?.map((text, index) => (
                                                    <li className="text-sm list-disc my-1" key={index}>
                                                        { text }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    ) : (
                                        <span className="text-sm">
                                            { datum?.description ?? "" }
                                        </span>
                                    ) 
                                }
                                
                            </div>
                        </div>
                    ))
                }
            </FaqWrapper>
        </div>
    );
}
 
export default FaqSection;