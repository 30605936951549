const KeyLogo = ({ ...rest }) => {
    return (
        <svg { ...rest } width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M85.14 0.5H34.86C13.02 0.5 0 13.52 0 35.36V85.58C0 107.48 13.02 120.5 34.86 120.5H85.08C106.92 120.5 119.94 107.48 119.94 85.64V35.36C120 13.52 106.98 0.5 85.14 0.5Z" fill="#181A2A"/>
            <path d="M83.3402 37.1599C74.4602 28.3399 60.0602 28.3399 51.2402 37.1599C45.0602 43.2799 43.2002 52.1599 45.6002 59.9599L31.5002 74.0599C30.5402 75.0799 29.8202 77.0599 30.0602 78.4999L30.9602 85.0399C31.2602 87.1999 33.3002 89.2399 35.4602 89.5399L42.0002 90.4999C43.4402 90.6799 45.4202 90.0799 46.4402 88.9999L48.9002 86.5399C49.5002 85.9999 49.5002 85.0399 48.9002 84.4399L43.0802 78.6199C42.2402 77.7799 42.2402 76.3399 43.0802 75.4399C43.9202 74.5999 45.3602 74.5999 46.2602 75.4399L52.0802 81.2599C52.6202 81.7999 53.5802 81.7999 54.1802 81.2599L60.5402 74.9599C68.2802 77.3599 77.1602 75.4999 83.3402 69.3799C92.2202 60.4399 92.2202 46.0399 83.3402 37.1599ZM67.5002 60.4999C63.3602 60.4999 60.0002 57.1399 60.0002 52.9999C60.0002 48.8599 63.3602 45.4999 67.5002 45.4999C71.6402 45.4999 75.0002 48.8599 75.0002 52.9999C75.0002 57.1399 71.6402 60.4999 67.5002 60.4999Z" fill="#0055E9"/>
        </svg>

    );
}
 
export default KeyLogo;