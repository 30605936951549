import HeroSection from "../components/home/HeroSection";
import LogoCloud from "../components/home/LogoCloud";
import ContractAddressSection from "../components/home/ContractAddressSection";
import HomeStatistics from "../components/home/HomeStatistics";
import HomeEcosystem from "../components/home/HomeEcosystem";
import BlogSection from "../components/home/BlogSection";
import ContactSection from "../components/home/ContactSection";
import NewsletterSection from "../components/home/NewsletterSection";
import TokenSection from "../components/home/TokenSection";
import ValuesSection from "../components/home/ValuesSection";
import RoadmapSection from "../components/home/RoadmapSection";
import FaqSection from "../components/home/FaqSection";

const Home = () => {

    return (
        <div>
            <HeroSection/>

            <LogoCloud/>

            <ContractAddressSection/>
            
            <HomeStatistics/>

            <HomeEcosystem/>

            <BlogSection/>

            <ValuesSection/>

            <RoadmapSection/>

            <TokenSection/>

            <FaqSection/>

            <ContactSection/>

            <NewsletterSection/>
        </div>
    );
}
 
export default Home;