import Home from "../views/Home";
import authRoutes from "./auth";
import homeRoutes from "./home";
import dashboardRoutes from "./dashboard";

const allRoutes = [
    {
        index: true,
        element: <Home/>
    },
    ...authRoutes,
    ...homeRoutes,
    ...dashboardRoutes
]

export default allRoutes;