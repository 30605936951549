import validator from "validator";
import { decrypt, encrypt } from "../helpers/utils";
import { jwtDecode } from "jwt-decode";

function SessionService() {
    const storageName = 'aast-session-storage';

    const hasSessionData = () => {
        return sessionStorage.hasOwnProperty(storageName)
    }

    const getSessionData = () => {
        if (!hasSessionData()) {
            return null;
        }

        const dataFromStorage = sessionStorage.getItem(storageName)
        const decryptedDataFromStorage = decrypt(dataFromStorage, true);

        return { ...decryptedDataFromStorage }
    }

    const removeSessionData = () => {
        sessionStorage.removeItem(storageName);
    }

    const saveSessionData = (data) => {
        if (!data) return false;

        const encryptedData = encrypt(data)
        sessionStorage.setItem(storageName, encryptedData);

        return true;
    }

    const getTokenExpirationDate = token => {
        if (!validator.isJWT(token)) {
            return null
        }
        
        let decoded = jwtDecode(token)
        if (typeof decoded.exp === 'undefined') {
            return null
        }

        return new Date(decoded.exp * 1000)
    }

    const isTokenExpired = (token = null) => {
        if (!token) token = getSessionData()?.token;

        if (!token) {
            return true;
        }

        const exp_date = getTokenExpirationDate(token)

        if (exp_date === null) {
            return false
        }

        return ((new Date().valueOf() + (0)) > exp_date.valueOf())
    }

    return {
        getSessionData, removeSessionData, saveSessionData, isTokenExpired
    }
}

export default SessionService;