import { Fragment, useState } from "react";
import { Description, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import PasswordInput from "../shared/PasswordInput";

const DeleteAccountModal = () => {
    const [showDialog, toggleShowDialog] = useState(false)
    const [password, setPassword] = useState('')

    const handleModalClose = () => {
        toggleShowDialog(false)
    }

    return (
        <Fragment>
            <button className={`font-bold text-sm text-red-primary`} onClick={() => toggleShowDialog(true)} type="button">Delete</button>

            <Dialog open={showDialog} onClose={handleModalClose} className="fixed z-[120] inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0" transition>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 text-white">
                    <DialogPanel transition className="max-w-lg space-y-4 rounded-lg bg-grey-primary p-4 py-8 md:p-8 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 w-full">
                        <DialogTitle className="font-bold text-center text-xl">Delete Account</DialogTitle>
                        <Description className={`text-center text-xs mt-3 leading-5`}>We ask you for your password to confirm your identity</Description>

                        <PasswordInput
                            className="mt-3"
                            label="Password"
                            placeholder="Enter your current password..."
                            name="password"
                            onInput={event => setPassword(event.target.value)}
                            value={password || ''}>

                            <small className={`text-red-primary text-xs mt-3 block`}>You cannot undo this once you go ahead</small>
                        </PasswordInput>

                        <div className="flex gap-4">
                            <button onClick={() => toggleShowDialog(false)} className="bg-blue-primary w-full py-3 text-xs rounded-md font-bold mt-4">Delete</button>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </Fragment>
    );
}
 
export default DeleteAccountModal;