import { useEffect, useMemo, useRef, useState } from "react";
import { transactionTableFields } from "../../data/dashboardData";
import DataTable from "../shared/DataTable";
import DashboardService from "../../services/DashboardService";
import PropTypes from "prop-types";

const { getTransactions } = DashboardService()

const TransactionsTable = ({ className, onTransactionsFetched = () => {}, ...rest }) => {
    const [transactions, setTransactions] = useState([])
    let mounted = useRef(true)
    let dataFetched = useRef(false)

    const successfulTransactions = useMemo(() => (transactions.reduce((total, tx) => total += (tx.status === '1') ? parseFloat(tx.amount) : 0, 0)).toFixed(4), [transactions])

    useEffect(() => {
        mounted.current = true
        if (dataFetched.current) return;

        const getUserTransactions = () => {
            getTransactions()
                .then(response => {
                    if (mounted.current && response && Array.isArray(response)) {
                        setTransactions(response)
                        onTransactionsFetched(response)
                    }
                })
                .finally(() => {
                    dataFetched.current = false
                })
        }

        getUserTransactions()
        dataFetched.current = true

        return () => {
            setTransactions([])
            mounted.current = false
        }
    }, [onTransactionsFetched])

    return (
        <div { ...rest } className={`flex flex-col bg-grey-primary rounded-lg mt-6 overflow-x-auto w-full flex-grow ${className}`} id="tableDiv">
            <div className="p-2 flex items-center justify-between">
                <span className="text-[green] text-xs whitespace-nowrap">Total successful transaction(s) { successfulTransactions } (USDT)</span>

                {/* <div className="relative rounded-md shadow-sm ml-3">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray sm:text-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                        </span>
                    </div>
                    <input
                        id="search"
                        name="search"
                        type="text"
                        placeholder="Search here..."
                        className="block w-64 rounded-lg border-0 py-1.5 pl-8 pr-8 text-gray-900 ring-1 ring-inset ring-gray placeholder:text-sm placeholder:text-gray-400 bg-grey-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6"
                    />
                </div>

                <div className="py-2 px-3 text-sm text-gray ring-1 ring-inset ring-gray border-0 rounded-lg ml-3 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
                    </svg>
                    <span className="text-gray">Filter</span>
                </div> */}
            </div>

            <DataTable fields={transactionTableFields} items={transactions} selectable={true} onSelected={items => console.log({items})}/>
        </div>
    );
}

TransactionsTable.propTypes = {
    onTransactionsFetched: PropTypes.func,
    className: PropTypes.string
}
 
export default TransactionsTable;