import styled from "styled-components";

const Wrapper = styled.div`
    animation: slide 40s linear infinite;
    .timeline-item {
        width: fit-content; 
        height: 150px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .timeline-item.top::before {
        border-width: 10px 10px 0 10px;
        border-color: var(--grey-primary) transparent transparent transparent;
    }

    .timeline-item.bottom::before {
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent var(--grey-primary) transparent;
    }

    .timeline-item.top::after {
        bottom: -55px;
    }

    .timeline-item.bottom::after {
        top: -55px;
    }

    .timeline-title {
        padding: 2px 5px;
    }
    @keyframes slide {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-50%);
        }
    }
`

const Timeline = ({ items }) => {
   
    return (
        <Wrapper id='scrollingDiv' className="timeline mt-10 flex relative py-5 w-fit justify-center">
            {
                items.map((item, index) => (
                    <div key={index} className={`timeline-item ${index % 2 === 0 ? 'top self-start mb-64 before:-bottom-2.5' : 'bottom self-end mt-64 before:-top-2.5'} relative p-2.5 m-2.5 bg-grey-primary border-none rounded-xl before:content-[''] after:content-['] before:absolute after:absolute before:left-1/2 after:left-1/2 before:w-0 before:h-0 after:w-3 after:h-3 before:border-solid before:-translate-x-1/2 after:-translate-x-1/2 after:bg-grey-primary after:rounded-full`}>
                        <div className={`timeline-title ${index % 2 === 0 ? 'top -bottom-10' : 'bottom -top-10'} absolute left-1/2 -translate-x-1/2 bg-purple-primary font-bold whitespace-nowrap`}>
                            { item.title }
                        </div>
                        <div className="timeline-content text-xs p-2">
                            <ul className="leading-5 list-disc">
                                {
                                    item?.description.map((desc, idx) =>  (
                                        <li key={idx}>{ desc }</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                ))
            }
            <div className="timeline-line absolute top-1/2 left-0 right-0 w-full h-0.5 bg-grey-primary z-0"></div>
        </Wrapper>
    );
}
 
export default Timeline;