import BlogDetails from "../components/blog/BlogDetails";
import EcosystemDetails from "../components/ecosystem/EcosystemDetails";
import About from "../views/About";
import Blog from "../views/Blog";
import ContactUs from "../views/ContactUs";
import Documents from "../views/Documents";
import Ecosystem from "../views/Ecosystem";
import Swap from "../views/Swap";

const homeRoutes = [
    {
        path: 'about',
        element: <About/>
    },
    {
        path: 'ecosystem',
        children: [
            {
                element: <Ecosystem/>,
                index: true
            },
            {
                path: ':key',
                element: <EcosystemDetails/>
            }
        ]
    },
    {
        path: 'contact-us',
        element: <ContactUs/>
    },
    {
        path: 'swap',
        element: <Swap/>
    },
    {
        path: 'documents',
        element: <Documents/>
    },
    {
        path: 'blog',
        children: [
            {
                element: <Blog/>,
                index: true
            },
            {
                path: ':id',
                element: <BlogDetails/> 
            }
        ]
    },
];

export default homeRoutes;