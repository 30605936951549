import { MexcLogo, LatokenLogo, TrustLogo, MetamaskLogo, XtLogo, AscendLogo, BitmartLogo, PancakeswapLogo, BiconomyLogo } from "../assets/icons/tokenLogos";

export const logoLineOne = [
    { Logo: MexcLogo, link: 'https://www.mexc.com/exchange/AAST_USDT' },
    { Logo: LatokenLogo, link: 'https://latoken.com/exchange/AAST_USDT' },
    { Logo: TrustLogo, link: 'https://trustwallet.com/' },
    { Logo: MetamaskLogo, link: 'https://metamask.io/' },
    { Logo: PancakeswapLogo, link: 'https://pancakeswap.finance/swap?outputCurrency=0xb1e998b346dddacd06f01db50645be52dafb93db' }
];

export const logoLineTwo = [
    // { Logo: OtcLogo, link: '' },
    { Logo: XtLogo, link: 'https://www.xt.com/en/trade/aast_usdt' },
    { Logo: AscendLogo, link: 'https://www.ascendex.com/en/basic/cashtrade-spottrading/usdt/aast' },
    { Logo: BitmartLogo, link: 'https://www.bitmart.com/trade/en?symbol=AAST_USDT' },
    { Logo: BiconomyLogo, link: 'https://www.biconomy.com/exchange/AAST_USDT' },
];