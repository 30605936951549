import ContactSection from "../components/home/ContactSection";
import FaqSection from "../components/home/FaqSection";
import NewsletterSection from "../components/home/NewsletterSection";
import { documentsData } from "../data/documentsData";
import DocumentIcon from "../assets/icons/documentIcon";

const Documents = () => {

    const handleDownload = async (downloadLink = '') => {
        if (!downloadLink) return;

        const filename = downloadLink.split("/").pop();
        try {
            const response = await fetch(downloadLink, {
              mode: 'cors',
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
          }
    }

    return (
        <div className="min-h-screen py-6 flex flex-col w-full text-white">
            <div className="flex flex-col w-full border-b border-grey-primary pb-10 px-6 lg:px-8" style={{ minHeight: '80vh' }}>
                <h2 className="text-center font-bold text-2xl lg:text-4xl">Documents</h2>

                <p className="text-center text-sm my-3">Access AAST's foundational documents, including White Paper and Roadmap, for in-depth insights into our vision and strategy.</p>

                <div data-aos="fade-up" data-aos-duration="1500" className="grid grid-cols-1 gap-x-10 gap-y-10 text-left sm:grid-cols-2 xl:grid-cols-3 transition-all lg:my-8 xl:px-10 mt-6">
                    {
                        documentsData?.map((datum, index) => (
                            <div key={index} className="relative bg-grey-primary rounded-3xl px-4 py-8 sm:p-8 shadow-md group">
                                {/* Div content */}
                                <div className="w-full flex h-full relative z-10">
                                    <div className="w-1/6">
                                        <DocumentIcon/>
                                    </div>
                                    <div className="w-5/6">
                                        <h6 className="font-bold text-lg">{ datum.title }</h6>
                                        <p className="text-sm mt-4">{ datum.description }</p>
                                    </div>
                                </div>

                                {/* Div overlay */}
                                <div className="absolute w-full h-full inset-0 z-20 rounded-3xl transition-opacity duration-300 bg-black opacity-0 group-hover:opacity-50"></div>
                                
                                {/* Div Action buttons */}
                                <div className={`absolute w-full h-full inset-0 z-30 rounded-3xl flex items-center justify-center transition-opacity duration-300 opacity-0 group-hover:opacity-100`}>
                                    <a href={datum.link}  rel="noreferrer" target="_blank" className="border border-light-blue hover:bg-light-blue hover:text-white text-light-blue rounded-3xl py-2.5 px-3 text-sm font-bold mr-2">
                                        Preview
                                    </a>

                                    <button type="button" onClick={() => handleDownload(datum.link)} className="border border-light-blue hover:bg-light-blue hover:text-white text-light-blue rounded-3xl py-2.5 px-3 text-sm font-bold ml-2">
                                        Download
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <FaqSection/>

            <ContactSection/>

            <NewsletterSection/>
        </div>
    );
}
 
export default Documents;