export const AboutLogoOne = ({ ...rest }) => {
    return (
        <svg { ...rest } width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="65" height="65" rx="7.5" fill="#47B2E3" fillOpacity="0.1"/>
            <rect x="0.5" y="0.5" width="65" height="65" rx="7.5" stroke="#47B2E3"/>
            <path d="M45.8351 33.1023L45.6084 32.7906C45.2118 32.3089 44.7443 31.9264 44.2059 31.6431C43.4834 31.2323 42.6618 31.0198 41.8118 31.0198H24.1743C23.3243 31.0198 22.5168 31.2323 21.7801 31.6431C21.2276 31.9406 20.7318 32.3514 20.321 32.8614C19.5134 33.8956 19.1309 35.1706 19.2584 36.4456L19.7826 43.0614C19.9668 45.0589 20.2076 47.5239 24.6985 47.5239H41.3018C45.7926 47.5239 46.0193 45.0589 46.2176 43.0473L46.7418 36.4598C46.8693 35.2698 46.5576 34.0798 45.8351 33.1023ZM36.386 40.9223H29.6001C29.0476 40.9223 28.6084 40.4689 28.6084 39.9306C28.6084 39.3923 29.0476 38.9389 29.6001 38.9389H36.386C36.9385 38.9389 37.3776 39.3923 37.3776 39.9306C37.3776 40.4831 36.9385 40.9223 36.386 40.9223Z" fill="#47B2E3"/>
            <path d="M45.128 28.5355C45.1812 29.0779 44.5929 29.441 44.0761 29.268C43.361 29.0286 42.6083 28.9089 41.8256 28.9089H24.1739C23.3851 28.9089 22.6084 29.0366 21.8837 29.28C21.3731 29.4514 20.7881 29.1002 20.7881 28.5616V25.7922C20.7881 20.7347 22.3323 19.1906 27.3898 19.1906H29.0614C31.0873 19.1906 31.7248 19.8422 32.5464 20.9047L34.2464 23.1714C34.6006 23.6531 34.6148 23.6814 35.2381 23.6814H38.6098C43.0378 23.6814 44.7681 24.8675 45.128 28.5355Z" fill="#47B2E3"/>
        </svg>
    )
}

export const AboutLogoTwo = ({ ...rest }) => {
    return (
        <svg { ...rest } width="67" height="66" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.833008" y="0.5" width="65" height="65" rx="7.5" fill="#47B2E3" fillOpacity="0.1"/>
            <rect x="0.833008" y="0.5" width="65" height="65" rx="7.5" stroke="#47B2E3"/>
            <path d="M44.8084 25.8317C44.8084 26.5967 44.3976 27.2909 43.7459 27.645L41.2809 28.9767L39.1842 30.0959L34.8351 32.4475C34.3676 32.7025 33.8576 32.83 33.3334 32.83C32.8092 32.83 32.2992 32.7025 31.8317 32.4475L22.9209 27.645C22.2692 27.2909 21.8584 26.5967 21.8584 25.8317C21.8584 25.0667 22.2692 24.3725 22.9209 24.0184L25.7117 22.5167L27.9359 21.3125L31.8317 19.2159C32.7667 18.7059 33.9001 18.7059 34.8351 19.2159L43.7459 24.0184C44.3976 24.3725 44.8084 25.0667 44.8084 25.8317Z" fill="#47B2E3"/>
            <path d="M30.3572 34.1191L22.0697 29.9825C21.4322 29.6566 20.6955 29.6991 20.0864 30.0675C19.4772 30.4358 19.123 31.0875 19.123 31.7958V39.63C19.123 40.99 19.8739 42.2083 21.0922 42.8175L29.3797 46.9541C29.663 47.0958 29.9747 47.1666 30.2864 47.1666C30.6547 47.1666 31.023 47.0675 31.3489 46.855C31.958 46.4866 32.3122 45.835 32.3122 45.1266V37.2925C32.3264 35.9466 31.5755 34.7283 30.3572 34.1191Z" fill="#47B2E3"/>
            <path d="M47.5422 31.7957V39.6299C47.5422 40.9757 46.7914 42.1941 45.573 42.8032L37.2855 46.9541C37.0022 47.0957 36.6905 47.1666 36.3789 47.1666C36.0105 47.1666 35.6422 47.0674 35.3022 46.8549C34.7072 46.4866 34.3389 45.8349 34.3389 45.1266V37.3066C34.3389 35.9466 35.0897 34.7282 36.308 34.1191L39.3539 32.6032L41.4789 31.5407L44.5955 29.9824C45.233 29.6566 45.9697 29.6849 46.5789 30.0674C47.1739 30.4357 47.5422 31.0874 47.5422 31.7957Z" fill="#47B2E3"/>
            <path d="M41.2811 28.9767L39.1844 30.0958L25.7119 22.5167L27.9361 21.3125L40.9411 28.6508C41.0827 28.7358 41.1961 28.8492 41.2811 28.9767Z" fill="#47B2E3"/>
            <path d="M41.4795 31.5409V34.7567C41.4795 35.3376 40.9978 35.8192 40.417 35.8192C39.8362 35.8192 39.3545 35.3376 39.3545 34.7567V32.6034L41.4795 31.5409Z" fill="#47B2E3"/>
        </svg>
    )
}

export const AboutLogoThree = ({ ...rest }) => {
    return (
        <svg { ...rest } width="67" height="66" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.16699" y="0.5" width="65" height="65" rx="7.5" fill="#47B2E3" fillOpacity="0.1"/>
            <rect x="1.16699" y="0.5" width="65" height="65" rx="7.5" stroke="#47B2E3"/>
            <path d="M26.5833 27.3334H22.3333C20.775 27.3334 19.5 26.0584 19.5 24.5V21.6667C19.5 20.1084 20.775 18.8334 22.3333 18.8334H26.5833C28.1417 18.8334 29.4167 20.1084 29.4167 21.6667V24.5C29.4167 26.0584 28.1417 27.3334 26.5833 27.3334Z" fill="#47B2E3"/>
            <path d="M46.134 25.9167H41.034C40.099 25.9167 39.334 25.1517 39.334 24.2167V21.95C39.334 21.015 40.099 20.25 41.034 20.25H46.134C47.069 20.25 47.834 21.015 47.834 21.95V24.2167C47.834 25.1517 47.069 25.9167 46.134 25.9167Z" fill="#47B2E3"/>
            <path d="M46.134 36.5417H41.034C40.099 36.5417 39.334 35.7767 39.334 34.8417V32.575C39.334 31.64 40.099 30.875 41.034 30.875H46.134C47.069 30.875 47.834 31.64 47.834 32.575V34.8417C47.834 35.7767 47.069 36.5417 46.134 36.5417Z" fill="#47B2E3"/>
            <path opacity="0.96" d="M39.3337 34.7709C39.9145 34.7709 40.3962 34.2892 40.3962 33.7084C40.3962 33.1275 39.9145 32.6459 39.3337 32.6459H35.4378V24.1459H39.3337C39.9145 24.1459 40.3962 23.6642 40.3962 23.0834C40.3962 22.5025 39.9145 22.0209 39.3337 22.0209H29.417C28.8362 22.0209 28.3545 22.5025 28.3545 23.0834C28.3545 23.6642 28.8362 24.1459 29.417 24.1459H33.3128V41.5C33.3128 43.6534 35.0553 45.3959 37.2087 45.3959H39.3337C39.9145 45.3959 40.3962 44.9142 40.3962 44.3334C40.3962 43.7525 39.9145 43.2709 39.3337 43.2709H37.2087C36.2312 43.2709 35.4378 42.4775 35.4378 41.5V34.7709H39.3337Z" fill="#47B2E3"/>
            <path d="M46.134 47.1667H41.034C40.099 47.1667 39.334 46.4017 39.334 45.4667V43.2C39.334 42.265 40.099 41.5 41.034 41.5H46.134C47.069 41.5 47.834 42.265 47.834 43.2V45.4667C47.834 46.4017 47.069 47.1667 46.134 47.1667Z" fill="#47B2E3"/>
        </svg>
    )
}