import React, { useState } from "react";
import PropTypes from "prop-types";

const Dropdown = ({ actionElement, className = '', iconElement, headerElement, children, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };

    return (
        <div className={`relative inline-block text-left ${className}`} { ...rest }>
            {
                actionElement ? (
                    React.cloneElement(actionElement, { onClick: toggleDropdown })
                ) : (
                    <button onClick={toggleDropdown}
                        className="inline-flex justify-center items-center w-full rounded-lg border border-blue-primary shadow-sm px-4 py-2 bg-blue-primary text-sm font-medium text-white hover:bg-purple-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-primary">
                        <span className="mr-2">Action</span>
                        {
                            iconElement ? (
                                React.cloneElement(iconElement)
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                            )
                        }
                    </button>
                )
            }
    
            {
                isOpen && (
                    <div className="origin-top-right absolute -left-[140px] sm:-left-[70px] mt-3 min-w-fit w-56 rounded-lg shadow-lg bg-purple-primary ring-1 ring-grey-primary z-40">
                        {
                            headerElement ? (
                                React.cloneElement(headerElement)
                            ) : (<></>)
                        }
                        <div className="py-1 text-grey-secondary" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            { children }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

const elementProptype = PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
]);

Dropdown.propTypes = {
    actionElement: elementProptype,
    children: PropTypes.node.isRequired,
    iconElement: elementProptype,
    headerElement: elementProptype
}
 
export default Dropdown;