import {
    DashboardIcon, TransactionsIcon, BuyTokenIcon, ReferralIcon,
    FailedIcon, PendingIcon, CompletedIcon, XReferral,
    FacebookReferral, LinkedinReferral
} from "../assets/icons/dashboardIcons";
import { toCurrency } from "../helpers/utils";

export const sidebarMenu = [
    { name: 'Dashboard', link: '/dashboard', Logo: DashboardIcon },
    { name: 'Buy Token', link: '/dashboard/buy-token', Logo: BuyTokenIcon },
    { name: 'Transactions', link: '/dashboard/transactions', Logo: TransactionsIcon },
    { name: 'Referrals', link: '/dashboard/referrals', Logo: ReferralIcon },
];

export const userMenu = [
    { title: 'Edit Profile', link: '/dashboard/edit-profile' },
    { title: 'My Account', link: '/dashboard/my-account' },
];

export const overviewStats = (pending = 0, failed = 0, successful = 0) => ([
    { Icon: CompletedIcon, title: "Successful", figure: successful },
    { Icon: FailedIcon, title: "Failed", figure: failed },
    { Icon: PendingIcon, title: "Pending", figure: pending },
])

export const referalLinks = (link = '') => ([
    { Icon: XReferral, title: 'Share on Twitter', link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=Check%20out%20this%20amazing%20referral!` },
    { Icon: FacebookReferral, title: 'Share on Facebook', link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}` },
    { Icon: LinkedinReferral, title: 'Share on LinkedIn', link: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}` },
])

const statusMapping = {
    "1": { class: "text-[green]", text: "Success" },
    "0": { class: "text-[orange]", text: "Pending" },
    "-1": { class: "text-[red]", text: "Failed" }
}

const paymentMapping = {
    "1": "USDT",
    "2": "AAS ROI"
}

export const paymentOptions = [
    { value: 1, name: 'Buy With USDT' },
    { value: 2, name: 'Buy With AAS ROI' },
]

export const transactionTableFields = [
    { key: 'date', label: 'Date', formatter: (item) => (new Date(item.date)).toDateString() },
    { key: 'amount', label: 'Amount (USDT)', formatter: (item) => toCurrency(item.amount, "USD") },
    { key: 'quantity', label: 'Amount (AAST)' },
    { key: 'rate', label: 'Rate' },
    { key: 'payment_method', label: 'Payment Method', formatter: (item) => (<span>{ paymentMapping[item?.payment_method.toString()] }</span>) },
    { key: 'status', label: 'Status', formatter: (item) => (<span className={`${statusMapping[item?.status.toString()]?.class} text-xs`}>{ statusMapping[item?.status.toString()]?.text }</span>) },
];