import { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const InputCode = ({ allowAlphabets = true, length, label, loading, onComplete }) => {
    const [code, setCode] = useState([...Array(length)].map(() => ""));
    const inputs = useRef([]);

    const processInput = (e, slot) => {
        const num = e.target.value;
        if (/[^0-9]/.test(num) && !allowAlphabets) return;
        const newCode = [...code];
        newCode[slot] = num.toUpperCase();
        setCode(newCode);
        if (slot !== length - 1) {
          inputs.current[slot + 1].focus();
        }
        if (newCode.every(number => number !== "")) {
          onComplete(newCode.join(""));
        }
    }

    const onKeyUp = (e, slot) => {
        if (e.keyCode === 8 && !code[slot] && slot !== 0) {
          const newCode = [...code];
          newCode[slot - 1] = "";
          setCode(newCode);
          inputs.current[slot - 1].focus();
        }
    }

    return (
        <div className='flex flex-col items-center p-2.5'>
            <label htmlFor='code' className='text-sm font-bold capitalize mb-2.5'>{ label }</label>
            <div className='flex justify-start items-center'>
                {
                    code?.map((number, index) => (
                        <input
                            id="code"
                            name="code"
                            key={index}
                            inputMode='text'
                            maxLength={1}
                            value={number}
                            autoFocus={!code[0].length && index === 0}
                            readOnly={loading}
                            onChange={e => processInput(e, index)}
                            onKeyUp={e => onKeyUp(e, index)}
                            ref={ref => inputs.current.push(ref)}
                            className='border border-purple-primary bg-purple-primary text-white shadow-none text-center h-14 w-11 rounded-lg mx-1 focus:border-light-blue hover:border-light-blue border-solid text-3xl'
                        />
                    ))
                }
            </div>
        </div>
    );
}

InputCode.propTypes = {
    allowAlphabets: PropTypes.bool,
    label: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired
}
 
export default InputCode;