import NewsletterSection from "../components/home/NewsletterSection";
import { ecosystemData } from "../data/ecosystemData";
import { Link } from "react-router-dom";

const Ecosystem = () => {

    return (
        <div className="min-h-screen py-6 flex flex-col w-full text-white">
            <div className="flex flex-col w-full border-b border-grey-primary pb-10 px-3 lg:px-8 bg-purple-primary">
                <h2 className="text-center font-bold text-2xl lg:text-4xl">Ecosystem</h2>

                <p className="text-center text-sm my-3">Empowering Users Through Diverse Solutions Within AAS Token's Integrated Ecosystem</p>

                <div className="bg-purple-primary flex flex-col w-full lg:my-10">
                    {
                        ecosystemData?.map((datum, index) => (
                            <div key={datum.key} className={`animate__animated animate__delay-1s w-full my-4 flex flex-col ${index % 2 === 0 ? 'lg:flex-row-reverse animate__backInLeft' : 'lg:flex-row animate__backInRight'} lg:justify-evenly xl:px-16 sm:px-8`}>
                                <div className="lg:w-1/2 xl:w-1/3">
                                    <img src={datum.image} className="w-96 h-96 block object-cover mx-auto xl:mx-0 rounded-xl" alt={datum.name}/>
                                </div>

                                <div className={`flex flex-col lg:justify-center lg:py-6 lg:w-1/2 xl:w-1/3`}>
                                    <div className="mt-4 p-2 lg:my-4 relative">
                                        <h3 className="font-bold"><span className="mr-1 font-extrabold lg:hidden">{ index + 1 }.</span>{ datum.name }</h3>
                                        <datum.TitleIcon className="lg:block hidden absolute w-16 -top-14 fill-gray"/>
                                    </div>

                                    <p className="p-2 mt-1 text-sm leading-6">{ datum.description }</p>

                                    <div className="p-2">
                                        <Link to={datum.key} className="mt-3 w-36 p-3 bg-blue-primary text-xs font-bold flex items-center justify-center rounded-lg border border-blue-primary hover:bg-transparent hover:text-blue-primary">
                                            View Details
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <NewsletterSection/>
        </div>
    );
}
 
export default Ecosystem;