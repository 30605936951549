import validator from "validator";
import ApiService from "./ApiService";
import SessionService from "./SessionService";
import Alert from "../helpers/alerts";

const { post } = ApiService()
const { removeSessionData } = SessionService()

function AuthenticationService() {

    const signInUser = async (email = '', password = '') => {
        if (!password || !email || (email && !validator.isEmail(email))) {
            Alert('Invalid or empty parameters given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }

        return await post("auth/login/", { email, password })
    }

    const connectUser = async (email = '', password = '') => {
        if (!password || !email || (email && !validator.isEmail(email))) {
            Alert('Invalid or empty parameters given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }
        return await post("account/connect/", { email, password });
    }

    const registerUser = async ({ email, fullname, phone, password, confirm_password, referral_code } = {}) => {
        if (!email || !fullname || !phone || (email && !validator.isEmail(email))) {
            Alert('Invalid or empty parameters given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }

        if (!password || !confirm_password || (password && (password !== confirm_password))) {
            Alert('Given password fields do not match', {}, 'error')
            return await new Promise((resolve) => resolve(null));
        }

        return await post("auth/create-account/", { email, fullname, phone, password, confirm_password, referral_code })
    }

    const userForgotPassword = async (email = '') => {
        if (!email || (email && !validator.isEmail(email))) {
            Alert('Invalid email parameter given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }

        return await post("auth/forgot-password/", { email });
    }
    
    const resendVerificationCode = async (email = '') => {
        if (!email || (email && !validator.isEmail(email))) {
            Alert('Invalid email given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }

        return await post("auth/resend/", { email })
    }

    const resendForgotCode = async (email = '') => {
        if (!email || (email && !validator.isEmail(email))) {
            Alert('Invalid email parameter given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }

        return await post("auth/resend-forgot-code", { email })
    }

    const verifyOtpCode = async (email = '', activation_code = '') => {
        if (!email || !activation_code || (email && !validator.isEmail(email))) {
            Alert('Invalid parameters given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }

        return await post('auth/verify', { email, activation_code });
    }

    const verifyForgotCode = async (email = '', forgot_code = '') => {
        if (!email || (email && !validator.isEmail(email)) || !forgot_code) {
            Alert('Invalid or empty parameters given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }

        return await post("auth/verify-forgot/", { email, forgot_code });
    }

    const resetUserPassword = async (email = '', password = '', confirm_password = '') => {
        if (!email || !password || !confirm_password || (password && confirm_password && (password !== confirm_password))) {
            Alert('Invalid or empty parameters given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }
        return await post("auth/reset-password", { email, password, confirm_password })
    }

    const changePassword = async ({ current_password = '', new_password = '', confirm_new_password = '' }) => {
        if (!current_password || !new_password || !confirm_new_password) {
            Alert('Invalid or empty passwords given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }
        return await post("account/change-password", { current_password, new_password, confirm_new_password })
    }

    const logoutUser = async () => {
        return await new Promise((resolve) => {
            removeSessionData()
            resolve(true)
        })
    }

    return {
        signInUser, registerUser,
        userForgotPassword, resendVerificationCode,
        resendForgotCode, verifyForgotCode,
        connectUser, verifyOtpCode, changePassword,
        logoutUser, resetUserPassword
    }
}

 export default AuthenticationService;