export const roadmapData = [
    {
        title: 'Quarter 1 2024',
        description: [
            'Presale Launch',
            'Whitepaper Release',
            'DEX & CEX Listings',
            'Platform Building begins.'
        ]
    },
    {
        title: 'Quarter 2 2024',
        description: [
            'Platform Building continues',
            'Strategic Community Building',
            'The AAS Merchandise Marketplace Testing',
            'Beta Platform Release',
        ]
    },
    {
        title: 'Quarter 3 2024',
        description: [
            'Strategic Partnerships Integration',
            'Afriq Solar-Powered Estate Dev. begins.',
            'E-commerce & Payment System Beta Testing',
            'The Afriq Payment System Launch',
            'Community Governance Integration'
        ]
    },
    {
        title: 'Quarter 4 2024',
        description: [
            'Learn-to-Earn Optimization',
            'E-commerce & Payment System Launch',
            'Afriq Academy (Learn2Earn System) Launch',
            'BRC-20 Bridge/Swap Dev. starts',
            'Decentralized Finance (DeFi) System Launch',
        ]
    },
    {
        title: 'Quarter 1 2025',
        description: [
            'Strategic Partnerships continue.',
            'Research & Development',
            'Strategic Global Adoption',
            'Strategic Collaborations',
            'Afriq Solar-Powered Estate Dev. continues.'
        ]
    },
    {
        title: 'Quarter 2 2025',
        description: [
            'Strategic Partnerships continues',
            'Research and Development continues',
            'Strategic Collaborations',
            'First phase of Presale Claim (20%)',
            'Bridge/Swap to BRC-20 Dev. Completion',
            'Afriq Solar-Powered Estate Dev. continues'
        ]
    },
    {
        title: 'Quarter 3 2025',
        description: [
            'Strategic Partnerships continues',
            'Tokenization of Real-world Assets (RWA)',
            'Strategic Global Adoption continues.',
            'Decentralized Finance (DeFi) System',
            'Afriq Solar-Powered Estate Dev. continues.'
        ]
    },
    {
        title: 'Quarter 4 2025',
        description: [
            'Strategic Partnerships continue.',
            'Research and Development continue.',
            'Second phase of Presale Claim (30%)',
            'Strategic Global Adoption continues.',
            'Continuous Expansion on the Use Cases',
            'Afriq Solar-Powered Estate Dev. Launch',
        ]
    },
    {
        title: 'Beyond 2025',
        description: [
            'Strategic Partnerships continue.',
            'Full Platform Release',
            'Third phase of Presale Claim (20%)',
            'Last phase of Presale Claim (30%)',
            'Strategic Collaborations',
            'Continuous Improvement & Projects Expansion',
            'Regular Updates for Afriq Community',
        ]
    }
]