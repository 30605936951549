import { useState } from "react";
import NewletterService from "../services/NewletterService";
import Loader from "../components/shared/Loader";

const { addSubscriber, NEWSLETTER_TYPE } = NewletterService();

const Swap = () => {
    const [email, setEmail] = useState('')
    const [loading, toggleLoading] = useState(false)

    const handleSubscription = event => {
        event.preventDefault();
        toggleLoading(true)
        const requestData = {
            type: NEWSLETTER_TYPE,
            data: {
                email
            }
        }

        addSubscriber(requestData)
            .finally(() => {
                toggleLoading(false)
            });
    }

    return (
        <div className="min-h-screen relative py-6 flex flex-col w-full text-white px-6 lg:px-16 xl:px-24 lg:justify-center lg:items-start">
            <img className="absolute hidden lg:block top-0 right-0 bottom-0 z-[-1]" src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/world-web_wurxkp.png'} alt="litecoin infographic 2"/>
            <img className="absolute hidden lg:block bottom-0 top-0 right-0 z-[-2]" src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/litecoin-swap_wdkvhz.png'} alt="litecoin infographic 1"/>
            <h2 className="text-center lg:text-left font-bold text-2xl lg:text-4xl lg:w-1/2 xl:w-2/5 w-4/5 mx-auto lg:mx-0">We are building something awesome!</h2>

            <p className="text-sm mt-4 lg:my-6 lg:w-3/5 xl:w-2/5 lg:mx-0 text-center lg:text-left mx-auto w-full">Discover the future of Finance with AAS Token Your Passport to a Decentralized World!</p>

            <div className="w-full lg:w-2/3 xl:w-1/2 lg:inline-flex">
                <div className="w-full sm:w-4/5 lg:w-3/5 mx-auto lg:mx-0 mt-6">
                    <label htmlFor="email" className="block text-sm font-medium leading-6">
                        Let's notify you when we are up
                    </label>
                    <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            required
                            autoComplete="email"
                            onInput={e => setEmail(e.target.value)}
                            value={email || ''}
                            className="block w-full h-12 rounded-lg border border-grey-primary py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                        />
                    </div>
                </div>

                <div className="flex justify-center items-center lg:flex-col lg:justify-end lg:ml-4 lg:w-fit w-full mt-6 relative z-2">
                    <button onClick={handleSubscription} disabled={loading} className="border border-light-blue hover:bg-transparent hover:text-light-blue bg-light-blue rounded-lg p-3 lg:h-12 text-xs font-bold w-40 min-w-fit flex items-center justify-center">
                        { loading ? (<Loader className="mr-2"/>) : '' }
                        Subscribe
                    </button>
                </div>
            </div>

            
        </div>
    );
}
 
export default Swap;