export const MexcLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/mexc_lbtthe.png'} alt="mexc"/>
    )
}

export const LatokenLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/latoken_yg9qya.png'} alt="latoken"/>
    )
}

export const TrustLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/trust_efg7bq.png'} alt="trust"/>
    )
}

export const MetamaskLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/metamask_okallc.png'} alt="metamask"/>
    )
}

export const OtcLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/otc_gmyhn0.png'} alt="otc"/>
    )
}

export const XtLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/xt_rkwmzv.png'} alt="xt"/>
    )
}

export const AscendLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/ascend_weomqu.png'} alt="ascend"/>
    )
}

export const BitmartLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/bitmart_zni2mq.png'} alt="bitmart"/>
    )
}

export const PancakeswapLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/pancakeswap_hywnqz.svg'} alt="pancakeswap"/>
    )
}

export const BiconomyLogo = ({ ...rest }) => {
    return (
        <img { ...rest } src="https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/Group_yrivl9.png" alt="biconomy"/>
    )
}