import ChangePasswordModal from "./ChangePasswordModal";
import DeleteAccountModal from "./DeleteAccountModal";
import TopBar from "./TopBar";

const MyAccount = () => {
    return (
        <div className="text-white flex flex-col w-full">
            <TopBar title="My Account"/>

            <div className="p-5 lg:p-8 flex flex-col">
                <div className="flex flex-col border-b border-grey-primary pb-4 my-3">
                    <h6 className="font-bold text-md lg:text-lg">Login and Security</h6>

                    <div className="flex justify-between items-center mt-4">
                        <span className="text-sm">Update password</span>

                        <ChangePasswordModal/>
                    </div>
                </div>

                <div className="flex flex-col border-b border-grey-primary pb-4 my-3">
                    <h6 className="font-bold text-md lg:text-lg">Account</h6>

                    <div className="flex justify-between items-center mt-4">
                        <span className="text-sm">Delete your account</span>

                        <DeleteAccountModal/>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default MyAccount;