export const formReducer = (state, event = null) => {
    if (!event) {
		return {};
	}
	if (event.type === 'add') {
		return {
			...state,
			...event.value,
		};
	}
	return {
		...state,
		[event.name]: event.value,
	};
}

export const userReducer = (state, user = {}) => {
    if (!Object.keys(user).length) {
        return {}
    }
    return {
        ...state,
        ...user
    }
}