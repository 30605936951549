import { useContext, useEffect, useMemo, useRef, useState } from "react";
import CopyIcon from "../../assets/icons/copyIcon";
import vector from "../../assets/background.svg";
import TopBar from "./TopBar";
import { toCurrency } from "../../helpers/utils";
import SendInvitationModal from "./SendInvitationModal";
import { UserContext } from "../../contexts/UserContext";
import DashboardService from "../../services/DashboardService";

const { getReferrals } = DashboardService()

const Referrals = () => {
    const { user } = useContext(UserContext)
    const [referrals, setReferrals] = useState([])
    const textRef = useRef(null)
    let mounted = useRef(true)
    let dataFetched = useRef(false)

    const copyToClipboard = event => {
        event.stopPropagation()
        const text = textRef.current.innerText;
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }

    const totalEarnings = useMemo(() => referrals.reduce((total) => total += 3, 0), [referrals])

    useEffect(() => {
        mounted.current = true
        if (dataFetched.current) return;

        const getUserReferrals = () => {
            getReferrals()
                .then(response => {
                    if (mounted.current && response) {
                        setReferrals(Array.isArray(response) ? response : [])
                    }
                })
                .finally(() => {
                    dataFetched.current = false
                })
        }

        getUserReferrals()
        dataFetched.current = true

        return () => {
            setReferrals([])
            mounted.current = false
        }
    }, [])

    return (
        <div className="text-white flex flex-col w-full">
            <TopBar title="Referrals"/>

            <div className="flex flex-col p-3 lg:p-5 flex-grow">
                <div className="flex flex-col lg:flex-row-reverse lg:justify-between lg:items-center lg:bg-grey-primary lg:rounded-xl lg:py-3 lg:pl-10 lg:pr-5 xl:w-4/5 relative shadow-md">
                    <img  src={vector} alt="background" className='absolute hidden inset-x-0 inset-y-0 pointer-events-none lg:block object-cover w-full h-full opacity-25'/>
                    <div>
                        <img src="https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/referral_feaq6r.png" alt="refer" className="mx-auto block object-cover w-52 lg:w-48"/>
                    </div>

                    <div className="lg:w-3/5 z-10">
                        <h6 className="font-bold text-lg lg:text-2xl lg:font-extrabold">Refer and Earn!</h6>

                        <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between mt-4">
                            <div className="flex-grow">
                                <label htmlFor="link" className="block text-sm font-medium leading-6">Your Referral link</label>

                                <div className="relative w-full flex justify-between items-center border-grey-primary border rounded-md p-4 pr-6 mb-4 lg:mb-0 lg:mt-1 mt-2 bg-purple-primary text-white h-12 whitespace-nowrap">
                                    <span className="w-full text-sm" role="textbox" name="link" id="link" ref={textRef}>
                                        { user?.referral || '' }
                                    </span>
                                    <CopyIcon className="cursor-pointer" onClick={copyToClipboard}/>
                                </div>
                            </div>

                            <div className="">
                                <SendInvitationModal link={user?.referral || ''}/>
                            </div>
                        </div>
                    </div>
                    <img className="absolute hidden lg:block z-0 bottom-0 left-24 xl:left-1/4 pointer-events-none rotate-180 opacity-30 w-20" src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/litecoin-right_owe3le.png'} alt="litecoin infographic 2"/>
                </div>

                <div className="mt-6 lg:w-1/2 pb-5 border-b border-grey-primary">
                    <h6 className="font-bold">Referral Earnings</h6>

                    <div className="flex justify-between mt-4">
                        <div className="w-2/5">
                            <span className="font-bold text-sm">Total Earnings</span>
                            <p className="text-light-blue font-bold">{ toCurrency(totalEarnings, 'USD') }</p>
                        </div>

                        <div className="border-l border-2 border-grey-secondary"></div>

                        <div className="w-2/5">
                            <span className="font-bold text-sm">Total Referrals</span>
                            <p className="text-light-blue font-bold">{ referrals.length }</p>
                        </div>
                    </div>
                </div>

                <div className="mt-6 xl:w-1/2">
                    <h6 className="font-bold">Your Invites</h6>

                    {
                        referrals?.length ? (
                            <div className="mt-6 w-full flex flex-col">
                                <div className="grid grid-cols-5 gap-1 pt-2 pb-4 border-b border-gray-800">
                                    <span className="font-bold text-sm col-span-3">Name</span>
                                    <span className="font-bold text-sm col-span-1">Date Joined</span>
                                    <span className="font-bold text-sm col-span-1">Referral Payout</span>
                                </div>

                                {
                                    referrals?.map((referral, index) => (
                                        <div key={index} className="grid grid-cols-5 gap-1 py-4 border-b border-gray-800">
                                            <span className="text-sm col-span-3">{ referral.fullname }</span>
                                            <span className="text-sm col-span-1">{ referral.created_on ? (new Date(parseFloat(referral.created_on))).toDateString() : 'N/A' }</span>
                                            <span className="text-sm col-span-1">{ toCurrency(3, 'USD') }</span>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className="mx-auto mt-6">
                                <img src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/Gift_hkma6p.png'} alt="gift" className="mx-auto block object-cover"/>

                                <p className="font-bold mt-6 text-center">You have no referrals yet.</p>

                                <p className="text-sm sm:w-4/5 lg:w-1/2 text-center mx-auto mt-3">
                                    Copy your referral code below or share your referral link with friends to earn extra effico points when they upgrade to premiium
                                </p>
                            </div>
                        )
                    }
                    
                    
                </div>
            </div>
        </div>
    );
}
 
export default Referrals;