import { useEffect, useReducer, useRef, useState } from "react";
import { contactData } from "../data/contactData";
import { formReducer } from "../helpers/reducers";
import FaqSection from "../components/home/FaqSection";
import NewsletterSection from "../components/home/NewsletterSection";
import ContactSection from "../components/home/ContactSection";
import Loader from "../components/shared/Loader";
import NewletterService from "../services/NewletterService";

const { addSubscriber, CONTACT_US_TYPE } = NewletterService()

const ContactUs = () => {
    const [formData, setFormData] = useReducer(formReducer, {});
    const [loading, toggleLoading] = useState(false)
    let mounted = useRef(true)

    const handleSubmit = event => {
        event.preventDefault();
        toggleLoading(true)
        const requestData = {
            type: CONTACT_US_TYPE,
            data: { ...formData }
        }

        addSubscriber(requestData)
            .finally(() => {
                if (mounted.current) {
                    toggleLoading(false)
                }
            })
    }

    const handleChange = (event) => {
		setFormData({
			name: event.target.name,
			value: event.target.value,
		});
	};

    useEffect(() => {
        mounted.current = true

        return () => {
            setFormData()
            toggleLoading(false)
            mounted.current = false
        }
    }, [])

    return (
        <div className="min-h-screen py-6 flex flex-col w-full text-white">
            <div className="flex flex-col w-full border-b border-grey-primary pb-10 px-6 lg:px-8">
                <h6 className="font-bold text-center text-light-blue text-xs lg:text-lg">Contact Us</h6>

                <div className="my-4 mx-2">
                    <h2 className="text-center font-bold text-2xl lg:text-4xl xl:w-3/5 xl:mx-auto">
                        We'd love to hear from you
                    </h2>
                </div>

                <div data-aos="fade-up" data-aos-duration="1500" className={`grid grid-cols-1 gap-x-6 gap-y-10 text-left lg:grid-cols-${contactData.length <= 3 ? contactData.length : '3'} transition-all lg:my-8 xl:px-0 lg:px-10`}>
                    {
                        contactData?.map(({ Logo, title, disclaimer, info }, index) => (
                            <div key={index} className="flex flex-col py-4 px-7 xl:py-8 xl:px-14 rounded-3xl bg-grey-primary sm:w-3/4 sm:mx-auto lg:w-full xl:w-4/5 xl:h-80 h-64 justify-center">
                                <Logo className="w-12 sm:w-16"/>

                                <p className="mt-4 text-sm leading-6">{ title }</p>

                                <small className="text-xs text-gray">{disclaimer}</small>

                                <a className="text-xs mt-2 font-bold" href={info} rel="noreferrer">{ info?.split(":")[1] }</a>
                            </div>
                        ))
                    }
                </div>

                <div className="mt-4 w-full mx-auto lg:my-8 lg:px-10 xl:px-16">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="flex md:flex-row flex-col justify-center">
                            <div className="w-full md:mr-2">
                                <label htmlFor="name" className="block text-sm font-medium leading-6">
                                    Full Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="name"
                                        name="name"
                                        placeholder="Enter first name"
                                        required
                                        onChange={handleChange}
                                        value={formData.name || ''}
                                        className="block w-full h-12 rounded-md border border-grey-primary py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                                    />
                                </div>
                            </div>

                            <div className="w-full md:ml-2 mt-4 md:mt-0">
                                <label htmlFor="email" className="block text-sm font-medium leading-6">
                                    Email
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="Enter your email"
                                        required
                                        autoComplete="email"
                                        onChange={handleChange}
                                        value={formData.email || ''}
                                        className="block w-full h-12 rounded-md border border-grey-primary py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="subject" className="block text-sm font-medium leading-6">
                                Subject
                            </label>
                            <div className="mt-2">
                                <input
                                    id="subject"
                                    name="subject"
                                    placeholder="Enter your subject"
                                    required
                                    onChange={handleChange}
                                    value={formData.subject || ''}
                                    className="block w-full h-12 rounded-md border border-grey-primary py-2 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="message" className="block text-sm font-medium leading-6">
                                Message
                            </label>
                            <div className="mt-2">
                                <textarea 
                                    id="message"
                                    name="message"
                                    required
                                    placeholder="Enter your message here..."
                                    rows="4"
                                    onChange={handleChange}
                                    value={formData.message || ''}
                                    className="block w-full rounded-md border border-grey-primary p-3 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary">
                                </textarea>
                            </div>
                        </div>

                        <div className="flex items-center justify-center mt-8">
                            <button disabled={loading} type="submit" className="text-xs font-bold py-3 flex items-center justify-center w-40 min-w-fit rounded-lg bg-light-blue border border-light-blue hover:bg-transparent hover:text-light-blue">
                                { loading ? (<Loader className="mr-2"/>) : '' }
                                Send Message
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <FaqSection/>

            <ContactSection/>

            <NewsletterSection/>
        </div>
    );
}
 
export default ContactUs;