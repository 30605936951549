import { useState } from "react";
import NewletterService from "../../services/NewletterService";
import Loader from "../shared/Loader";

const { addSubscriber, NEWSLETTER_TYPE } = NewletterService();

const NewsletterSection = () => {
    const [input, setInput] = useState('');
    const [loading, toggleLoading] = useState(false)

    const handleSubscription = event => {
        event.preventDefault();
        toggleLoading(true)
        const requestData = {
            type: NEWSLETTER_TYPE,
            data: {
                email: input
            }
        }

        addSubscriber(requestData)
            .finally(() => {
                toggleLoading(false)
            });
    }

    return (
        <div className="text-white py-16 px-4 lg:px-16" data-aos="fade-up" data-aos-duration="1500">
            <div className="bg-grey-primary rounded-xl p-4 flex flex-col justify-center items-center w-full lg:mx-auto lg:w-4/5">
                <h2 className="text-2xl font-bold my-4">Want to stay updated?</h2>

                <p className="text-sm font-bold text-center my-4 lg:my-0">
                    Sign up to our newsletter so you don't miss any news
                </p>

                <div className="flex flex-col w-full mt-5 lg:flex-row lg:justify-center">

                    <input className="bg-[#2e2f3e] w-full p-3 rounded-lg my-4 placeholder:text-gray placeholder:text-sm lg:w-96 block" placeholder="Enter your email" onInput={event => setInput(event.target.value)}/>

                    <button disabled={loading} className="bg-light-blue flex items-center justify-center min-w-fit rounded-lg w-full my-4 p-3 font-bold text-sm lg:w-36 lg:mx-4 border border-light-blue hover:bg-transparent hover:text-light-blue" onClick={handleSubscription} type="button">
                        { loading ? (<Loader className="mr-2"/>) : '' }
                        Subscribe
                    </button>
                </div>

                <small className="text-xs text-gray">We care about your data in our <span className="underline underline-offset-3 cursor-pointer">privacy policy</span></small>
            </div>

            
        </div>
    );
}
 
export default NewsletterSection;