import { Description, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { referalLinks } from "../../data/dashboardData";

const SendInvitationModal = ({ actionElement, link }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [email, setEmail] = useState('')
    const links = useRef(referalLinks(link))
    const [showSuccess, setShowSuccess] = useState(false)

    const handleModalClose = () => {
        setIsOpen(false)
        setShowSuccess(false)
        setEmail('')
    }

    useEffect(() => {
        links.current = referalLinks(link)
    }, [link])

    return (
        <Fragment>
            {
                actionElement ? (
                    React.cloneElement(actionElement)
                ) : (
                    <button className="w-full lg:w-32 lg:ml-3 py-3 text-sm font-bold bg-blue-primary border border-blue-primary hover:bg-purple-primary hover:text-blue-primary rounded-md mt-4 lg:mt-0" onClick={() => setIsOpen(true)} type="button">
                        Share
                    </button>
                )
            }
            <Dialog open={isOpen} onClose={handleModalClose} className="fixed z-[120] inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0" transition>
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 text-white">
                    <DialogPanel transition className="max-w-lg space-y-4 rounded-lg bg-grey-primary p-8 duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 w-full">
                        {
                            !showSuccess ? (
                                <Fragment>
                                    <DialogTitle className="font-bold text-center text-xl">Send Invitation</DialogTitle>

                                    <div className="mt-3">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6">
                                            Email
                                        </label>
                                        <div className="mt-2 relative">
                                            <input
                                                id="email"
                                                name="email"
                                                placeholder="example@email.com"
                                                required
                                                type="email"
                                                onInput={event => setEmail(event.target.value)}
                                                value={email || ''}
                                                className="block w-full h-12 rounded-md border border-grey-primary py-2 pr-8 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-purple-primary"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex gap-4">
                                        <button onClick={() => setShowSuccess(true)} className="bg-blue-primary w-full py-3 text-xs rounded-md font-bold mt-4">Send Invite</button>
                                    </div>

                                    <div className="flex items-center justify-between w-full flex-wrap">
                                        {
                                            links.current?.map(({ Icon, title, link }, index) => (
                                                <a key={index} href={link} target="_blank" rel="noopener noreferrer" className="mt-3 flex items-center text-xs text-grey-secondary whitespace-nowrap">
                                                    <Icon className="mr-1"/>
                                                    { title }
                                                </a>
                                            ))
                                        }
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div>
                                        <img src="https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/Social_01_askcny.png" alt="Hand shake" className="block mx-auto object-cover"/>
                                    </div>

                                    <DialogTitle className="font-bold text-center text-lg">Invite Sent</DialogTitle>
                                    <Description className={`text-center text-sm mt-3 mb-3 leading-5`}>Your friend should get an email soon. You will get 100 free credits when registration has been confirmed.</Description>

                                    <div className="w-full flex items-center justify-evenly mt-6">
                                        <button className="rounded-lg py-2 px-3 border w-36 border-light-blue text-light-blue hover:bg-light-blue hover:text-white text-sm font-bold" onClick={() => setIsOpen(false)}>
                                            Done
                                        </button>

                                        <button className="rounded-lg py-2 px-3 border w-52 border-blue-primary bg-blue-primary hover:bg-purple-primary hover:text-blue-primary text-sm font-bold" onClick={() => setShowSuccess(false)}>
                                            Invite more Friends
                                        </button>
                                    </div>
                                </Fragment>
                            )
                        }
                    </DialogPanel>
                </div>
            </Dialog>
        </Fragment>
    );
}

SendInvitationModal.propTypes = {
    actionElement: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
    ]),
    link: PropTypes.string.isRequired
}
 
export default SendInvitationModal;