import axios from "axios";
import SessionService from "./SessionService";
import Alert from "../helpers/alerts";

const url = process.env.REACT_APP_API_URL;
const { getSessionData, removeSessionData } = SessionService();

const Axios = axios.create({
	baseURL: url,
});

const getHeader = () => ({
	'Content-Type': 'application/json',
	'x-access-token': getSessionData()?.token
})

const returnError = (error) => {
	Alert('Error encountered.', {}, 'error', error)
	return null;
}

function ApiService() {

	const post = async (url = '', params = {}) => {
		if (!url) return;
		Axios.defaults.headers = getHeader()

		try {
			const { data } = await Axios.post(url, params)
			if (data?.status !== true) return null;

			if (data?.message) {
				Alert(data.message)
			}
			return data?.data ?? data;
		} catch (error) {
			if (error?.response?.status === 400) {
				return null;
			} else if (error?.response?.status === 403) {
				removeSessionData();
				return returnError(error);
			} else {
				return returnError(error);
			}
		}
	}

	const get = async (url = '', params = {}) => {
		if (!url) return;
		Axios.defaults.headers = getHeader()

		try {
			const { data } = await Axios.get(url, { params })
			if (data?.status !== true) return null;
			return data?.data ?? data;
		} catch (error) {
			if (error?.response?.status === 400) {
				return null;
			} else if (error?.response?.status === 403) {
				removeSessionData();
				return returnError(error);
			} else {
				return returnError(error);
			}
		}
	}

	const put = async (url = '', params = {}) => {
		if (!url) return;
		Axios.defaults.headers = getHeader()

		try {
			const { data } = await Axios.put(url, params)
			if (data?.status !== true) return null;
			if (data?.message) {
				Alert(data.message)
			}
			return data?.data ?? data;
		} catch (error) {
			if (error?.response?.status === 400) {
				return null;
			} else if (error?.response?.status === 403) {
				removeSessionData();
				return returnError(error);
			} else {
				return returnError(error);
			}
		}
	}

	// delete service 
	const excise = async (url = '', params = {}) => {
		if (!url) return;
		Axios.defaults.headers = getHeader()

		try {
			const { data } = await Axios.delete(url, { params })
			if (data?.status !== true) return null;
			if (data?.message) {
				Alert(data.message)
			}
			return data?.data ?? data;
		} catch (error) {
			if (error?.response?.status === 400) {
				return null;
			} else if (error?.response?.status === 403) {
				removeSessionData();
				return returnError(error);
			} else {
				return returnError(error);
			}
		}
	}

	return {
		post, put, get, excise
	}
}

export default ApiService;