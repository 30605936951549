import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import vector from "../assets/background.svg";
import Header from '../components/shared/Header';
import Footer from '../components/shared/Footer';
import { ToastContainer } from 'react-toastify';
import { useEffect, Fragment, useMemo } from 'react';
import AOS from 'aos';
import { UserProvider } from '../contexts/UserContext';

function App() {
	const { pathname } = useLocation();

  const onWindowResize = () => {
    AOS.refresh();
  }

  const isDashboardPath = useMemo(() => {
    return pathname.split('/').includes('dashboard');
  }, [pathname]);

  useEffect(() => {
    AOS.init();

    window.addEventListener('resize', onWindowResize);

    return () => {
        window.removeEventListener('resize', onWindowResize);
    }
  }, []);

  useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

  return (
    <div className="App relative overflow-hidden">
      <UserProvider>
        {
          !isDashboardPath && (
            <Fragment>
              <img  src={vector} alt="background" className='fixed inset-x-0 inset-y-0 pointer-events-none block object-cover w-full opacity-25'/>
              
              <Header/>
            </Fragment>
          )
        }

        <Outlet/>

        {
          !isDashboardPath && (
            <Fragment>
              <Footer/>
            </Fragment>
          )
        }
      </UserProvider>
      <ToastContainer stacked position="top-right" theme='colored' autoClose={4000}/>
    </div>
  );
}

export default App;
