import { faker } from "@faker-js/faker";
import CryptoJS from "crypto-js";

export const transformBlogEntries = (items = []) => items.map(({ fields, sys }) => ({
    author: fields?.blogAuthor,
    authorImage: fields?.authorImage?.fields?.file?.url,
    category: fields?.blogType,
    date: (new Date(fields?.datePublished).toDateString()),
    description: fields?.blogSummary,
    id: sys?.id,
    image: fields?.blogImage?.fields?.file?.url,
    title: fields?.blogTitle,
    content: fields?.blogContent,
}))

export const groupBy = (array = [], property = '') => array.filter(arrayObject => Boolean(arrayObject[property])).reduce((group, arrayObject) => {
    const key = arrayObject[property];
    if (!group[key]) {
      group[key] = [];
    }
    group[key].push(arrayObject);
    
    return group;
}, {});

export const toCurrency = function (value, currency = null) {
  if (!currency) return parseFloat(value).toFixed(2);
  if (!value) value = 0;


  let formatCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  return formatCurrency.format(value); 
};

export const formatNumber = (num = 0) => {
  if (num >= 1_000_000_000) {
    return `${(num / 1_000_000_000).toFixed(0)}B`;
  } else if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(0)}M`;
  } else if (num >= 1_000) {
    return `${(num / 1_000).toFixed(0)}K`;
  } else {
    return num.toString();
  }
};

export const formatCountdown = (number) => {
  return number.toString().padStart(2, '0');
}

export const returnUniqueClass = (classes = '') => {
  const classArray = classes.split(' ')
  const uniqueClasses = new Set(classArray)

  return Array.from(uniqueClasses).join(' ');
}

export const encrypt = (data = null) => {
  if (!data) return;

  const key = process.env.REACT_APP_ENCRYPTION_SECRET_KEY

  if (typeof data === 'object') {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  }

  return CryptoJS.AES.encrypt(data, key).toString();
}

export const decrypt = (data = null, isObject = false) => {
  if (!data) return;

  const key = process.env.REACT_APP_ENCRYPTION_SECRET_KEY
  const bytes = CryptoJS.AES.decrypt(data, key)

  if (isObject) {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  
  return bytes.toString(CryptoJS.enc.Utf8);
}

function base64urlEncode(str) {
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export const generateFakeJWT = () => {
  const header = base64urlEncode(JSON.stringify({ alg: 'HS256', typ: 'JWT' })).toString('base64url');
  const currentTime = Math.floor(Date.now() / 1000);
  const expirationTime = currentTime + (5 * 60 * 60);
  const payload = base64urlEncode(JSON.stringify({
    user_id: faker.string.uuid(),
    email: faker.internet.email(),
    iat: currentTime,
    exp: expirationTime
  })).toString('base64url');
  const signature = faker.string.alphanumeric(43); // Fake signature

  return `${header}.${payload}.${signature}`;
}