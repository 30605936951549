import BellIcon from "../../assets/icons/bellIcon";
import Dropdown from "./Dropdown";

const NotificationsDropdown = ({ ...rest }) => {
    return (
        <Dropdown { ...rest } actionElement={(
            <span role="button" className="p-1 sm:px-3 w-10 sm:w-fit h-10 rounded-full bg-grey-primary flex items-center justify-center">
                <BellIcon/>
            </span>
        )}>
            <div className="p-3">
                Notifications Coming
            </div>
        </Dropdown>
    );
}
 
export default NotificationsDropdown;