import EcosystemCard from "../ecosystem/EcosystemCard";
import SlideSection from "../shared/SlideSection";
import { ecosystemData } from "../../data/ecosystemData";

const convertItems = (items = []) => {
    return items.map((item, index) => (
        <EcosystemCard item={item} key={index}/>
    ))
}

const HomeEcosystem = () => {
    return (
        <SlideSection items={convertItems(ecosystemData)} title={'AAST Ecosystem'}/>
    );
}
 
export default HomeEcosystem;