import NotificationsDropdown from "../shared/NotificationsDropdown";
import UserMenu from "../shared/UserMenu";
import PropTypes from "prop-types";

const TopBar = ({ title, text }) => {
    return (
        <div className="flex flex-col-reverse lg:flex-row items-start justify-between p-3 xl:p-5 lg:border-b lg:border-grey-primary">
            <div className="">
                <h2 className="font-bold text-xl whitespace-nowrap">
                    { title }
                </h2>
                {
                    text && (
                        <p className="text-sm whitespace-nowrap">
                            { text }
                        </p>
                    )
                }
            </div>

            <div className="hidden sm:flex items-center justify-end w-full mb-4 lg:mb-0">
                <NotificationsDropdown className="mr-2"/>

                <UserMenu/>
            </div>
        </div>
    );
}

TopBar.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string.isRequired
}
 
export default TopBar;