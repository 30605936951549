export const XFooterLogo = ({ ...rest }) => {
    return (
        <svg width="16" height="16" { ...rest } viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.39634 6.68657L14.8569 0.475586H13.5631L8.81954 5.86737L5.03363 0.475586H0.666016L6.39234 8.62965L0.666016 15.1423H1.95983L6.96608 9.44701L10.9651 15.1423H15.3327L9.39634 6.68657ZM7.6237 8.70123L7.04261 7.88876L2.42641 1.42999H4.41397L8.14049 6.64436L8.71912 7.45683L13.5625 14.2343H11.5749L7.6237 8.70123Z" fill="white"/>
        </svg>
    )
}

export const FacebookFooterLogo = ({ ...rest }) => {
    return (
        <svg width="10" height="18" { ...rest } viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.12038 17.6104V9.58063H0.400391V6.45101H3.12038V4.14244C3.12038 1.46466 4.76604 0.00585938 7.17073 0.00585938C8.32233 0.00585938 9.31224 0.0911965 9.60059 0.129823V2.92886H7.93337C6.62548 2.92886 6.37216 3.54688 6.37216 4.45235V6.45101H9.49099L9.08497 9.58063H6.37216V17.6104H3.12038Z" fill="white"/>
        </svg>
    )
}

export const TelegramFooterLogo = ({ ...rest }) => {
    return (
        <svg width="20" height="17" { ...rest } viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.50754 7.76804L13.0296 3.02483C14.1759 2.53074 18.0297 0.949673 18.0297 0.949673C18.0297 0.949673 19.8084 0.257955 19.6701 1.93784C19.6306 2.62956 19.2155 5.06045 18.8203 7.66922L17.5752 15.4362C17.5752 15.4362 17.4763 16.5825 16.6265 16.7801C15.7767 16.9778 14.3933 16.0884 14.1561 15.8908C13.9585 15.7327 10.4406 13.5192 9.15597 12.4322C8.80023 12.1357 8.40496 11.5428 9.19549 10.8511C10.9742 9.21076 13.1086 7.1949 14.3933 5.91028C14.9862 5.31738 15.5791 3.93394 13.1086 5.61383L6.15194 10.278C6.15194 10.278 5.3614 10.7721 3.87915 10.3175C2.3969 9.86295 0.657719 9.27005 0.657719 9.27005C0.657719 9.27005 -0.50832 8.55857 1.50754 7.76804Z" fill="white"/>
        </svg>
    )
}