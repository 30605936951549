import BuyToken from "../components/dashboard/BuyToken";
import EditProfile from "../components/dashboard/EditProfile";
import MyAccount from "../components/dashboard/MyAccount";
import Overview from "../components/dashboard/Overview";
import Referrals from "../components/dashboard/Referrals";
import Transactions from "../components/dashboard/Transactions";
import Dashboard from "../views/Dashboard";
import Guard from "./Guard";

const dashboardRoutes = [
    {
        path: 'dashboard',
        element: <Guard component={Dashboard}/>,
        children: [
            {
                index: true,
                element: <Overview/>
            },
            {
                path: "buy-token",
                element: <BuyToken/>
            },
            {
                path: "transactions",
                element: <Transactions />
            },
            {
                path: "referrals",
                element: <Referrals/>
            },
            {
                path: "edit-profile",
                element: <EditProfile/>
            },
            {
                path: "my-account",
                element: <MyAccount/>
            }
        ]
    }
];

export default dashboardRoutes;