import PropTypes from "prop-types";

const Loader = ({ size = 18, color = 'currentColor', strokeWidth = 3, ...rest }) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 50 50"
        style={{ animation: 'rotate 1s linear infinite' }}
        { ...rest }>
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          style={{
            strokeDasharray: '100, 200',
            strokeDashoffset: 0,
            animation: 'dash 1.5s ease-in-out infinite',
          }}/>
        <style>{`
          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
          @keyframes dash {
            0% {
              stroke-dasharray: 1, 200;
              stroke-dashoffset: 0;
            }
            50% {
              stroke-dasharray: 100, 200;
              stroke-dashoffset: -15;
            }
            100% {
              stroke-dasharray: 100, 200;
              stroke-dashoffset: -125;
            }
          }
        `}</style>
      </svg>
    );
};

Loader.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    strokeWidth: PropTypes.number
}
 
export default Loader;