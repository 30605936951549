import validator from "validator";
import ApiService from "./ApiService";
import Alert from "../helpers/alerts";

const { post, get } = ApiService()

function DashboardService() {

    const getAccountRate = async () => {
        return await get("account/rate/");
    }

    const buyToken = async ({ amount, email, password, payment_method = 1 } = {}) => {
        if (!email || (email && !validator.isEmail(email)) || !password || !amount) {
            Alert('Invalid parameters given', {}, 'error');
            return await new Promise((resolve) => resolve(null));
        }
        
        return await post("account/buy/", { amount, email, password, payment_method });
    }

    const checkWalletExists = async () => {
        return await get("account/wallet-address/")
    }

    const addWalletAddress = async (wallet_address = '') => {
        if (!wallet_address) {
            Alert('Enter a valid address')
            return await new Promise((resolve) => resolve(null));
        }

        return await post("account/update-wallet-address/", { wallet_address })
    }

    const getTransactions = async () => {
        return await get("account/payments/");
    }

    const getReferrals = async () => {
        return await get("account/referrals/");
    }

    const getReferralDetails = async () => {
        return await get("account/referral-bonus/");
    }

    return {
        getAccountRate, buyToken,
        checkWalletExists, addWalletAddress,
        getTransactions, getReferrals,
        getReferralDetails
    }
}

export default DashboardService;