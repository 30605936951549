import { useEffect, useRef, useState } from "react";
import BlogService from "../services/BlogService";
import { transformBlogEntries, groupBy } from "../helpers/utils";
import BlogCard from "../components/blog/BlogCard";
import BlogGroup from "../components/blog/BlogGroup";
import NewsletterSection from "../components/home/NewsletterSection";

const { getAllEntries } = BlogService()

const Blog = () => {
    const [recentBlogPosts, setRecentBlogPosts] = useState([]);
    const [groupedBlogPosts, setGroupedBlogPosts] = useState({});
    const [rowView, setRowView] = useState(false)
    let mounted = useRef(true)
    let dataFetched = useRef(false)

    const onWindowResize = () => {
        let width = window.innerWidth;
        if (width > 1023) {
            setRowView(true)
        } else {
            setRowView(false)
        }
    }

    useEffect(() => {
        mounted.current = true
        if (dataFetched.current) return;

        const getEntries = async () => {
            try {
                await getAllEntries({
                    limit: 20,
                    order: 'sys.createdAt',
                    include: 2
                }).then(({ items }) => {
                    if (mounted.current && items) {
                        const blogEntries = transformBlogEntries([...items]).filter(blog => blog.id !== process.env.REACT_APP_POPUP_ID) //transform and filter out popup blog entry
                        setRecentBlogPosts([...blogEntries].slice(0, 3).reverse())
                        setGroupedBlogPosts(groupBy([...blogEntries], 'category'))
                    }
                }).finally(() => dataFetched.current = false)
            } catch (error) {
                console.error(error)
            } 
        }

        getEntries();
        dataFetched.current = true;

        return () => {
            mounted.current = false;
            setRecentBlogPosts([])
            setGroupedBlogPosts({})
        }
    }, []);

    useEffect(() => {
        onWindowResize()
        window.addEventListener('resize', onWindowResize);
    
        return () => {
            window.removeEventListener('resize', onWindowResize);
        }
    }, []);

    return (
        <div className="min-h-screen py-6 flex flex-col w-full text-white">
            <div className="flex flex-col w-full px-2 lg:px-8" style={{ minHeight: '80vh' }}>

                {/* Recent Blogs */}
                <div className="flex flex-col w-full border-b border-grey-primary pb-10">
                    <h6 className="my-3 text-center font-bold">Recent Blogs</h6>

                    <div className="flex-col flex lg:flex-row w-full">
                        <BlogCard entry={recentBlogPosts[0] ?? {}} className="my-3 lg:my-0"/>
                        <div className="flex flex-col lg:w-3/5 xl:w-1/2">
                            <BlogCard entry={recentBlogPosts[1] ?? {}} row={rowView} className="my-3 lg:my-0"/>
                            <BlogCard entry={recentBlogPosts[2] ?? {}} row={rowView} className="my-3 lg:my-0"/>
                        </div>
                    </div>
                </div>
                {/* Blog Categories */}
                
                {
                    Object.keys(groupedBlogPosts)?.map((key, index) => (
                        <BlogGroup title={key} key={index} items={groupedBlogPosts[key] ?? []}/>
                    ))
                }
            </div>

            <NewsletterSection/>
        </div>
    );
}
 
export default Blog;