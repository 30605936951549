import { Link } from "react-router-dom";
import { useState } from "react";
import { valuesData } from "../../data/valuesData";

const ValuesSection = () => {
    const [selectedValue, setSelectedValue] = useState(valuesData[0])

    return (
        <div data-aos="fade-up" data-aos-duration="1500" className="relative bg-[#181a2a] text-white py-6 lg:py-16 px-4 sm:px-10 lg:px-16 flex flex-col justify-center items-center">
            <div className="text-center font-bold">
                <small className="block text-xs lg:text-lg">Core Values</small>
                <h3 className="my-2 text-lg lg:text-3xl">AAST's Three Pillars</h3>
            </div>

            <div className="w-full flex flex-col lg:flex-row-reverse lg:justify-evenly lg:mt-4">
                <div className="w-full lg:w-3/5 xl:w-1/3 my-4">
                    <img className="rounded-xl w-full block object-cover" src={selectedValue.image} alt="core values" />
                </div>

                <div className="overflow-hidden whitespace-nowrap flex items-center w-fit relative mt-6 lg:items-start lg:mr-4">

                    <div className="">
                        {
                            valuesData?.map((value, index) => (
                                <div key={index} className={`cursor-pointer p-4 flex-col flex border-l-4 ${(selectedValue.title === value.title) ? 'border-blue-primary' : 'border-white'}`} onMouseEnter={() => setSelectedValue(value)} onClick={() => setSelectedValue(value)}>
                                    <h3 className="text-md font-bold">{ value.title }</h3>
                                    <p className="whitespace-normal text-xs my-1">{ value.description }</p>
                                    {
                                        value.external ? (
                                            <a href={value.link} target={value.target ? '_blank' : undefined} rel="noreferrer" className="hover:text-white text-xs text-blue-primary flex items-center my-2 font-bold">
                                                { value.linkText }
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4 ml-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                                </svg>
                                            </a>
                                        ) : (
                                            <Link to={value.link} className="hover:text-white text-xs text-blue-primary flex items-center my-2 font-bold">
                                                { value.linkText }
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4 ml-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                                </svg>
                                            </Link>
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}
 
export default ValuesSection;