import ApiService from "./ApiService";

const { get } = ApiService()

function SharedService() {
    
    const getTokenStats = async () => {
        return await get("account/tokenomics/");
    }

    return {
        getTokenStats
    }
}

export default SharedService;