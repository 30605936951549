import { createClient } from "contentful";
const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_DELIVERY_ACCESS_TOKEN } = process.env

const BlogService = () => {
    const client = createClient({
        space: REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: REACT_APP_CONTENTFUL_DELIVERY_ACCESS_TOKEN
    })

    const getAllEntries = (params = {}) => {
        return client.getEntries(params)
    }

    const getEntry = (id, params = {}) => {
        return client.getEntry(id, params)
    }

    return {
        getAllEntries,
        getEntry
    }
}

export default BlogService;