import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const defaultPropsEntry = {
    image: '',
    author: '',
    title: '',
    description: '',
    date: '',
    id: '',
    category: '',
    content: ''
}

const BlogCard = ({ entry = defaultPropsEntry, className = '', row = false, ...rest }) => {
    return (
        <div className={`mx-auto ${row ? 'w-full' : 'xl:w-96 w-80'} h-fit relative flex flex-${row ? 'row justify-between items-center' : 'col'} p-2 ${className}`} {...rest}>
            <div className={`${row ? 'w-1/2 h-full mr-3' : 'w-full'}`}>
                <img src={entry.image} alt="eco" className={`rounded-xl block w-full object-cover ${row ? 'h-full' : ''}`}/>
            </div>
            
            <div className={`${row ? 'w-1/2' : 'w-full'}`}>
                <small className={`block ${row ? 'mt-0' : 'mt-4'} tracking-wide font-bold text-xs text-light-blue`}>{ entry.author }  <span className="font-extrabold">.</span>  { entry.date }</small>

                <div className={`${row ? 'mt-3' : 'mt-4'} flex justify-between w-full text-white font-bold`}>
                    <span>
                        { entry.title }
                    </span>

                    <Link to={`/blog/${entry.id}`} className='hover:text-light-blue'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                        </svg>
                    </Link>
                </div>

                <small className={`text-white text-xs block ${row ? 'mt-3' : 'mt-4'}`}>
                    { entry.description }
                </small>
            </div>
        </div>
    );
}

BlogCard.propTypes = {
    className: PropTypes.string,
    entry: PropTypes.object.isRequired,
    row: PropTypes.bool
}
 
export default BlogCard;