const NgFlagIcon = ({ ...rest }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_708_5634)">
                <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#F0F0F0"/>
                <path d="M0 10.0003C0 14.3 2.71375 17.9654 6.52176 19.3783V0.622314C2.71375 2.03521 0 5.70067 0 10.0003Z" fill="#6DA544"/>
                <path d="M20.0022 10.0003C20.0022 5.70067 17.2885 2.03521 13.4805 0.622314V19.3784C17.2885 17.9654 20.0022 14.3 20.0022 10.0003Z" fill="#6DA544"/>
            </g>
            <defs>
                <clipPath id="clip0_708_5634">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}
 
export default NgFlagIcon;