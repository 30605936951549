import { NumberOne, NumberTwo, NumberThree, NumberFour } from '../assets/icons/ecosystemLogos';

export const ecosystemData = [
    { 
        key: 'real-estate',
        TitleIcon: NumberOne,
        name: 'Real Estate',
        image: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/ecosystem1_rsh1t9.png',
        title: 'Enjoy eco-friendly lifestyles at the AAST Solar-Powered Estate.',
        description: 'Enjoy eco-friendly lifestyles at the AAST Solar-Powered Estate, where homes, blockchain security, and community benefits come together to create a unique and innovative real estate experience.',
        detailHeading: 'AAST Solar-Powered Estate: Revolutionizing Real Estate with Sustainability',
        detailSubheading: 'Welcome to the AAST Solar-Powered Estate, a pioneering real estate project that combines sustainability and innovation. Our eco-friendly community will offer a unique living experience, powered by renewable energy and blockchain technology.',
        backgroundImage: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/real-estate-large_be1wzz.png',
        supportingImage: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/ecosystem1_rsh1t9.png',
        featureHeading: 'Key Features',
        features: [
            'Solar-Powered Homes: Energy-efficient homes with solar panels, reducing carbon footprint and energy costs',
            'Sustainable Living: Eco-friendly infrastructure, green spaces, and community programs promoting environmental responsibility',
            'Blockchain Security: Secure property ownership and transactions using blockchain technology',
            'Community Benefits: Access to exclusive rewards, services, and events within the AAST ecosystem'
        ],
        benefits: [
            'Reduced Energy Costs: Solar power generates clean energy, lowering utility bills',
            'Increased Property Value: Sustainable features and blockchain security enhance property value',
            'Community Engagement: Participate in eco-friendly initiatives and events, fostering a sense of community',
            'Peace of Mind: Secure property ownership and transactions, ensured by blockchain technology'
        ]
    },
    {
        key: 'academy',
        TitleIcon: NumberTwo,
        name: 'Education',
        image: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/ecosystem2_v7cb7x.png',
        title: 'Empower your future in crypto and blockchain technology with AAST Academy.',
        description: 'Empower your future in crypto and blockchain technology with AAST Academy. Earn a graduate certificate through comprehensive education and hands-on training, preparing you for a successful career in this rapidly evolving field.',
        detailHeading: "Empowering the Future of Crypto: AAST Academy",
        detailSubheading: "Welcome to the AAST Academy, where we're shaping the next generation of crypto and blockchain leaders. Our comprehensive education program is designed to equip students with the knowledge and skills necessary to thrive in the rapidly evolving world of cryptocurrencies and blockchain technology.",
        backgroundImage: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/academy-large_hchtcx.png',
        supportingImage: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/academy-supporting_bzb7hm.png',
        featureHeading: "What to Expect",
        featureSubheading: "Our curriculum will cover a wide range of topics, including:",
        features: [
            "Cryptocurrency fundamentals",
            "Blockchain technology and its applications",
            "Investment strategies and risk management",
            "Smart contract development",
            "Decentralized finance (DeFi)",
            "Regulatory frameworks and compliance"
        ],
        benefitHeading: "Learning Outcomes:",
        benefitSubheading: "Upon completing our program, students will receive a graduate certificate in Crypto and Blockchain Technology. They will be well-versed in:",
        benefits: [
            "Understanding the underlying technology and concepts of cryptocurrencies and blockchain",
            "Analyzing market trends and making informed investment decisions",
            "Developing and implementing blockchain-based solutions",
            "Staying up-to-date with industry developments and regulatory changes"
        ],
        extras: {
            extraHeading: "Learning Resources:",
            extraContent: [
                "Video Lectures: Engaging video content featuring industry experts and experienced instructors",
                "Interactive Tutorials: Hands-on exercises and simulations to reinforce learning",
                "Case Studies: Real-world examples and group discussions to apply theoretical knowledge",
                "Mentorship: One-on-one guidance from experienced professionals in the field"
            ]
        }
    },
    {
        key: 'commerce',
        TitleIcon: NumberThree,
        name: 'E-Commerce',
        image: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/ecosystem3_b0wb6q.png',
        title: 'Experience seamless ecommerce with AAST, enabling global transactions with low fees and fast delivery.',
        description: "Experience seamless ecommerce with AAST, enabling global transactions with low fees and fast delivery. Partnering with merchants like iAfriq, we're bridging the gap between digital payments and international trade.",
        detailHeading: 'Shop Global, Pay with AAST: Unlocking Ecommerce Possibilities',
        detailSubheading: "Experience seamless ecommerce transactions with AAST, the cryptocurrency that's bridging the gap between digital payments and global commerce. Our ecosystem enables merchants and customers to connect effortlessly, making international trade more accessible than ever.",
        backgroundImage: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/e-commerce-large_ys4muy.png',
        supportingImage: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/e-commerce-supporting_ttbenh.png',
        featureHeading: 'Featured Merchant: iAfriq (iafriq.com)',
        features: "We're proud to partner with iAfriq, an authorized reseller of Apple products in Nigeria. With AAST, customers worldwide can purchase Apple products and have them delivered right to their doorstep. iAfriq's commitment to quality and customer satisfaction aligns perfectly with our vision for a seamless ecommerce experience.",
        benefits: [
            "Global Accessibility: Shop from merchants worldwide, regardless of geographical boundaries",
            "Fast and Secure: AAST transactions are lightning-fast and secure, ensuring a hassle-free experience",
            "Low Fees: Reduced transaction fees compared to traditional payment methods",
            "Increased Customer Base: Merchants can tap into a global customer base, expanding their reach and sales"
        ]
    },
    {
        key: 'payment',
        TitleIcon: NumberFour,
        name: 'AAST Payment System',
        image: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/ecosystem4_tjnkjf.png',
        title: 'Experience seamless transactions with the AAST Payment System.',
        description: 'Experience seamless transactions with the AAST Payment System, enabling fast, secure, and low-cost payments between individuals and businesses, powered by blockchain technology.',
        detailHeading: "AAST Payment System: Transforming Transactions within the Ecosystem",
        detailSubheading: "The AAST Payment System is the backbone of our ecosystem's transactional infrastructure. Our user-friendly payment solution enables swift, secure, and low-cost payments between individuals and businesses, leveraging the power of blockchain technology.",
        backgroundImage: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/payment-large_btungy.png',
        supportingImage: 'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/payment-supporting_okzne1.png',
        featureHeading: "Afriq jm lounge: Pioneer in Adoption",
        features: "Afriq JM Lounge 1 and  2, two forward-thinking businesses, have embraced the AAST Payment System to enhance their customers' experience. By integrating our payment system, they offer their customers a seamless and efficient way to make transactions. Whether it's for a refreshing drink or a delicious meal, customers can now pay with ease using AAST.",
        benefitHeading: "Benefits for Businesses",
        benefitSubheading: "By joining the AAST Payment System, businesses like Afriq JM Lounge 1 and 2 enjoy:",
        benefits: [
            "Faster Transactions: Quick and efficient payments, reducing wait times and increasing customer satisfaction.",
            "Enhanced Security: Our blockchain-based system ensures secure and tamper-proof transactions.",
            "Increased Customer Reach: Accept payments from a global customer base, without borders or restrictions.",
            "Reduced Fees: Competitive transaction fees, reducing costs and increasing profit margins."
        ]
    },
]