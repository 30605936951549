import PropTypes from "prop-types";

const EcosystemCard = ({ item }) => {
    return (
        <div className="mx-6 rounded-xl relative">
            <img src={item.image} className="rounded-xl block w-full object-cover" alt={`ecosystem set ${item.name}`}/>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-70"></div>
            <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                <h1 className="text-lg my-2 uppercase font-bold">{ item.name }</h1>
                <p className="text-sm">{ item.title }</p>
            </div>
        </div>
    );
}

EcosystemCard.propTypes = {
    item: PropTypes.object.isRequired
}
 
export default EcosystemCard;