import CopyIcon from "../../assets/icons/copyIcon";
import { PlayIcon } from '@heroicons/react/24/outline';
import { useRef } from "react";

const ContractAddressSection = () => {
    const textRef = useRef(null);

    const copyToClipboard = event => {
        event.stopPropagation()
        const text = textRef.current.innerText;
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }

    return (
        <div className="p-4 sm:p-8" data-aos="fade-up" data-aos-duration="1500">
            <div className="bg-grey-primary rounded-xl px-4 py-12 lg:w-4/5 lg:mx-auto relative">
                <img className="absolute z-0 bottom-0 left-0 w-48" src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/litecoin-left_ny2jve.png'} alt="litecoin infographic 1"/>
                <img className="absolute z-0 top-0 right-0 w-36" src={'https://cloud.iafriq.com/storage/Files/AfriqX/TokenWebsite/images/litecoin-right_owe3le.png'} alt="litecoin infographic 2"/>
                <h3 className="text-white z-10 relative uppercase font-semibold sm:font-bold text-2xl text-center">AAST Contract Address</h3>

                <div className="relative w-full xl:w-3/5 md:w-4/5 sm:mx-auto flex justify-between items-center border-gray border rounded-lg p-4 pr-6 my-4 bg-transparent text-white h-fit whitespace-normal">
                    <a href="https://bscscan.com/token/0xb1E998b346DDDacD06f01db50645bE52DafB93db" target="_blank" rel="noreferrer" className="break-all w-5/6 sm:w-full" ref={textRef}>
                        0xb1E998b346DDDacD06f01db50645bE52DafB93db
                    </a>
                    <CopyIcon className="cursor-pointer" onClick={copyToClipboard}/>
                </div>
                    
                <div className="flex relative z-10 flex-col items-center text-center lg:flex-row lg:justify-between md:w-4/5 xl:w-3/5 md:mx-auto md:px-2">
                    <small className="text-white font-bold text-xs my-2">click the above address to check on BscScan</small>

                    <a href="https://www.youtube.com/watch?v=R4IKsl2CQ4Q" target="_blank" rel="noreferrer" className="bg-transparent text-light-blue my-2 font-bold border-none text-sm flex items-center justify-center">
                        <PlayIcon aria-hidden="true" className="h-3 w-3 mr-2" style={{ fill: 'var(--light-blue)' }}/> Guide to add wallet address
                    </a>
                </div>
            </div>
        </div>
    );
}
 
export default ContractAddressSection;