import styled from "styled-components";
import { aboutData } from "../data/aboutData";
import FaqSection from "../components/home/FaqSection";
import ContactSection from "../components/home/ContactSection";
import NewsletterSection from "../components/home/NewsletterSection";

const GradientText = styled.span`
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`

const About = () => {

    return (
        <div className="min-h-screen py-6 flex flex-col w-full text-white">
            <div className="flex flex-col w-full border-b border-grey-primary pb-10 px-6 lg:px-8">
                <h6 className="font-bold text-center text-light-blue text-xs lg:text-lg">Who Are We?</h6>

                <div className="my-4 mx-2">
                    <h2 className="text-center font-bold text-2xl lg:text-4xl xl:w-3/5 xl:mx-auto">
                        AGC Ecosystem <br/> Leading the Way in High Edge <br/> <GradientText className="bg-gradient-to-r from-white via-blue-primary to-blue-primary">Digital Currency</GradientText> Trading with Cutting Edge Frequency Strategies
                    </h2>
                </div>

                <div data-aos="fade-up" data-aos-duration="1500" className="grid grid-cols-1 gap-x-6 gap-y-10 text-left lg:grid-cols-3 transition-all lg:my-8 xl:px-10">
                    {
                        aboutData?.map(({ Logo, description }, index) => (
                            <div key={index} className="flex flex-col p-8 rounded-3xl bg-grey-primary sm:w-3/4 sm:mx-auto lg:w-full xl:w-4/5">
                                <Logo className="w-12 sm:w-16"/>

                                <p className="mt-4 text-sm leading-6">{ description }</p>
                            </div>
                        ))
                    }
                </div>

                <div data-aos="fade-up" data-aos-duration="1500" className="mt-6 p-2 xl:py-8 xl:px-16">
                    <p className="text-sm leading-6">
                        AAST is the native Token of Afriq Exchange (AfriqX) and the utility token of Afriq Group of Companies. Built on Binance Smart Chain (BEP20), AAST is a digital store of value and a medium of exchange designed to power the trading and payment operations of the AGC ecosystem. Leveraging AI and decentralized finance (DeFi), AAST takes the global adoption of cryptocurrencies to a new height as it provides holders the opportunity to pay for goods and services within our ecosystem and beyond, facilitating digital access and inclusion among our vast community. With AAST, our primary goals are to establish a convenient, secure, fast, and reliable cross-border digital payment solution that employs cutting-edge technologies and to channel resources towards various charity initiatives.
                    </p>

                    <p className="text-sm leading-6 mt-4">
                        Afriq Group of Companies (AGC) is a corporate group controlling a number of subsidiary companies with a common vision. These member companies include:
                    </p>

                    <ul className="">
                        <li className="text-sm leading-6 mt-4">
                            <h6 className="font-bold">Afriq Exchange Limited (AfriqX)</h6>
                            <p>AfriqX is a world-class digital exchange yet to be launched. With its cutting-edge security and user-friendly features, AfriqX is designed to enable interested individuals locally and internationally to buy and sell a variety of digital assets using AfriqX mobile and web applications. The exchange will offer a wide range of digital asset services such as token listing, buying, selling, storing, sending, receiving, staking, and a host of others poised to revolutionize users' trading experience. Afriq Exchange already has a native token to power transactions, AAST, which is already listed and trading on six centralized exchanges and one decentralized exchange.</p>
                        </li>

                        <li className="text-sm leading-6 mt-4">
                            <h6 className="font-bold">iAfriq Global Services Limited</h6>
                            <p>IAfriq is the biggest Apple Store in Africa following Apple Inc. endorsement of iAfriq.com as its authorized reseller of Apple products. This partnership guarantees token holder's seamless e-commerce experience with AAST, enabling global transactions at minimal fees and ensuring superfast delivery services.</p>
                        </li>

                        <li className="text-sm leading-6 mt-4">
                            <h6 className="font-bold">Afriq Arbitrage System Ltd</h6>
                            <p>AAS is a pioneering digital currency trading platform that utilizes high-frequency trading and advanced algorithms to automatically acquire digital currencies at opportune moments from exchanges and sell them strategically for optimal returns. At Afriq Group of Companies (AGC), we believe in harnessing the power of digital currencies to create a positive impact through innovative technology solutions. Our mission extends beyond profit-making; we are dedicated to empowering communities especially the less privileged and enabling widespread access to essential technology. Through our innovative approach to trading, we not only generate wealth but also contribute significantly to social causes.</p>
                        </li>
                    </ul>

                    <div className="mt-6">
                        <button type="button" className="px-4 py-3 rounded-lg text-xs font-bold text-light-blue hover:bg-light-blue hover:text-white border border-light-blue bg-transparent">
                            Buy Now
                        </button>
                    </div>
                </div>
            </div>

            <FaqSection/>

            <ContactSection/>

            <NewsletterSection/>
        </div>
    );
}
 
export default About;