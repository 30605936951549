import TopBar from "./TopBar";
import DollarIcon from "../../assets/icons/dollarIcon";
import CustomSelect from "../shared/CustomSelect";
// import ExportIcon from "../../assets/icons/exportIcon";
import { useReducer } from "react";
import BuyTokenModal from "./BuyTokenModal";
import { formReducer } from "../../helpers/reducers";
import { paymentOptions } from "../../data/dashboardData";
import TransactionsTable from "./TransactionsTable";

const BuyToken = () => {
    const [formData, setFormData] = useReducer(formReducer, {})
    
    const handleChange = (event) => {
        setFormData({
			name: event.target.name,
			value: event.target.value,
		});
    }

    return (
        <div className="text-white flex flex-col w-full" style={{ minHeight: '100vh' }}>
            <TopBar title={'Buy Token'}/>

            <div className="flex flex-col p-3 lg:p-5 flex-grow">
                <div className="flex flex-col lg:flex-row">

                    <div className="lg:w-2/5 lg:mr-2">
                        <label htmlFor="amount" className="block text-sm font-medium leading-6">
                            Amount to Buy (USD)
                        </label>
                        <div className="mt-2 relative shadow-sm">
                            <input
                                id="amount"
                                name="amount"
                                type="number"
                                placeholder="Enter amount to buy"
                                required
                                onChange={handleChange}
                                value={formData.amount || ''}
                                className="block w-full h-12 rounded-md border-0 py-2 pr-28 placeholder:text-gray placeholder:text-xs shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary"
                            />
                            <div className="absolute inset-y-0 right-5 flex items-center">
                                <label htmlFor="currency" className="sr-only">
                                    Currency
                                </label>
                                <CustomSelect 
                                    options={[{ value: 'USD', icon: <DollarIcon/> }]}
                                    reduce={option => option.value} label="value"
                                    selectWrapperClass={'w-20'} 
                                    value={'USD'}
                                    selectedOptionClass={'rounded-full px-2 border-purple-primary bg-purple-primary'}
                                    />
                            </div>
                        </div>
                        <small className="text-xs text-grey-secondary ml-1">You get 0 AAST</small>
                    </div>

                    <div className="mt-3 lg:mt-0 lg:w-2/5 lg:mx-2">
                        <label htmlFor="payment" className="block text-sm font-medium leading-6">
                            Payment Method
                        </label>
                        <div className="mt-2 relative shadow-sm">
                            <CustomSelect id="payment" name="payment" options={paymentOptions}
                                placeholder={'Select Payment method'}
                                reduce={option => option.value} label={'name'}
                                onInput={event => setFormData({
                                    name: "payment_method",
			                        value: event,
                                })}
                                value={formData.payment_method || ''}
                                selectedOptionClass={'h-12 w-full rounded-md p-2 px-3 shadow-sm ring-1 ring-inset ring-purple-primary focus:ring-2 focus:ring-inset focus:ring-gray sm:text-sm sm:leading-6 bg-grey-primary border-0'}
                            />
                            
                        </div>
                    </div>

                    <div className="lg:w-1/5 lg:ml-2">
                        <BuyTokenModal buttonClass="w-full py-3 text-sm font-bold bg-blue-primary border border-blue-primary hover:bg-purple-primary hover:text-blue-primary rounded-md mt-8 flex items-center justify-center" data={formData}/>
                    </div>
                </div>


                <div className="w-full flex justify-between items-center mt-6 pb-4 px-2 border-b border-grey-primary">
                    <h6 className="text-sm font-bold">
                        Transaction History
                    </h6>

                    {/* <span className="flex items-center" role="button">
                        <span className="text-xs font-bold">Export</span>
                        <ExportIcon className="ml-1"/>
                    </span> */}
                </div>

                <div className="px-5 w-full flex flex-grow">
                    <TransactionsTable />
                </div>
            </div>
        </div>
    );
}
 
export default BuyToken;