import Logo from '../../assets/icons/logo';
import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Link } from 'react-router-dom';
import MenuCloseIcon from '../../assets/icons/menuCloseIcon';
import MenuOpenIcon from '../../assets/icons/menuOpenIcon';

const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className='px-2 py-5 sm:px-10'>
            <header className='bg-grey-primary rounded-xl animate__backInDown animate__animated animate__delay-1s'>
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-4" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">AAST</span>
                            <Logo className="h-8 w-auto"/>
                        </Link>
                    </div>
                    <div className="flex lg:hidden">
                        <button type="button" onClick={() => setMobileMenuOpen(true)} className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white">
                            <span className="sr-only">Open main menu</span>
                            <MenuOpenIcon aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        <Link to="/about" className="text-sm font-semibold leading-6 text-white">About</Link>
                        <Link to="/ecosystem" className="text-sm font-semibold leading-6 text-white">Ecosystem</Link>
                        <Link to="/blog" className="text-sm font-semibold leading-6 text-white">Updates</Link>
                        <Link to="/documents" className="text-sm font-semibold leading-6 text-white">Documents</Link>
                        <Link to="/learn-to-earn" className="text-sm font-semibold leading-6 text-white">Learn2Earn</Link>
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:items-center lg:gap-x-12">
                        <Link to="/swap" className="text-sm font-semibold leading-6 text-white">Swap</Link>
                        <Link to="/dashboard" className="hover:bg-blue-primary hover:text-white flex items-center gap-x-1 text-sm font-semibold leading-6 text-blue-primary border-blue-primary p-2 border rounded-lg" aria-expanded="false">
                            Claim
                        </Link>
                    </div>
                </nav>

                <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10"></div>
                    <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-purple-primary px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-grey-primary">
                        <div className="flex items-center justify-between">
                            <Link to="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">AAST</span>
                                <Logo className="h-8 w-auto"/>
                            </Link>
                            <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
                                <span className="sr-only">Close menu</span>
                                <MenuCloseIcon aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-white">
                                <div className="space-y-2 py-6">
                                    <Link to="/about" className="-mx-3 hover:bg-grey-primary block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white">About</Link>
                                    <Link to="/ecosystem" className="-mx-3 hover:bg-grey-primary block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white">Ecosystem</Link>
                                    <Link to="/blog" className="-mx-3 hover:bg-grey-primary block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white">Updates</Link>
                                    <Link to="/documents" className="-mx-3 hover:bg-grey-primary block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white">Documents</Link>
                                    <Link to="/learn-to-earn" className="-mx-3 hover:bg-grey-primary block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white">Learn2Earn</Link>
                                </div>
                                <div className="py-6">
                                    <Link to="/swap" className="-mx-3 hover:bg-grey-primary block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white">Swap</Link>
                                    <Link to="/dashboard" className="-mx-3 hover:bg-grey-primary flex items-center text-base gap-x-1 font-semibold leading-7 text-blue-primary px-3 py-2 rounded-lg" aria-expanded="false">
                                        Claim
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>
        </div>
    );
}
 
export default Header;